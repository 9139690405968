import dayjs from 'dayjs'
import React from 'react'
import { CardActionArea, Grid, Typography } from '@material-ui/core'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import VerticalCard from '../../VerticalCard'
import ElementoAccessorio from '../../../assets/bridgeElements/accessori.svg'
import ApparecchioAppoggio from '../../../assets/bridgeElements/appoggio.svg'
import Arco from '../../../assets/bridgeElements/arco.svg'
import Giunto from '../../../assets/bridgeElements/giunto.svg'
import Piedritto from '../../../assets/bridgeElements/piedritto.svg'
import Pila from '../../../assets/bridgeElements/pila.svg'
import Soletta from '../../../assets/bridgeElements/soletta.svg'
import Spalla from '../../../assets/bridgeElements/spalla.svg'
import Trave from '../../../assets/bridgeElements/trave.svg'

import {
    AssignmentTurnedInOutlined as AssignmentTurnedInIcon,
    AssignmentLateOutlined as AssignmentLateIcon,
} from '@material-ui/icons'
import AppTooltip from '../../AppTooltip'

const elementiStrutturali = {
    spalle: Spalla,
    pile: Pila,
    'apparecchi-appoggio': ApparecchioAppoggio,
    giunti: Giunto,
    piedritti: Piedritto,
    archi: Arco,
    travi: Trave,
    soletta: Soletta,
    'elementi-accessori': ElementoAccessorio,
}

export default function LGPLA1DifettositaCards({
    onCardClick,
    inspectionDates = [],
    elementsTotalCountsMapping, // un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero totale di elementi di quella tipologia presenti sul ponte
    schedeConDifettiCount, // un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero di elementi ispezionati che hanno almeno un difetto di quella tipologia
    schedeIspezione, // tutte le schede d'ispezione: un oggetto con le chiavi spalle, pile, ecc e i valori sono il numero totale di elementi ispezionati di quella tipologia
    isInBMSComparison = false,
}) {
    const { t } = useTranslation()

    return Object.entries(elementiStrutturali).map(([elementoStrutturale, image]) => {
        let cardInfo
        if (isInBMSComparison) {
            cardInfo = (
                <Grid container>
                    <Grid item container direction="row" justify={'flex-end'}>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <AppTooltip title={t('inspected_elements_BMS')} placement={'top'}>
                                <AssignmentTurnedInIcon fontSize="small" />
                            </AppTooltip>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <AppTooltip title={t('schede_con_difetti_BMS')} placement={'top'}>
                                <AssignmentLateIcon fontSize="small" />
                            </AppTooltip>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={6}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>
                                {dayjs(inspectionDates[0]).format('DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontSize: 13 }}>
                                {_.size(
                                    schedeIspezione[0]?.[elementoStrutturale.replace('-', '_')]
                                )}
                                {_.has(elementsTotalCountsMapping[0], elementoStrutturale) &&
                                    `/${elementsTotalCountsMapping[0][elementoStrutturale]}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontSize: 13 }}>
                                {schedeConDifettiCount[0][elementoStrutturale]}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={6}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>
                                {dayjs(inspectionDates[1]).format('DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontSize: 13 }}>
                                {_.size(
                                    schedeIspezione[1]?.[elementoStrutturale.replace('-', '_')]
                                )}
                                {_.has(elementsTotalCountsMapping[1], elementoStrutturale) &&
                                    `/${elementsTotalCountsMapping[1][elementoStrutturale]}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontSize: 13 }}>
                                {schedeConDifettiCount[1][elementoStrutturale]}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )
        } else {
            cardInfo = (
                <Grid
                    container
                    item
                    direction="column"
                    wrap="nowrap"
                    alignItems="center"
                    style={{ marginBottom: 8 }}
                >
                    <Typography variant="h7" style={{ marginBottom: '5px' }}>
                        {t('inspected_elements', {
                            n: _.size(schedeIspezione?.[elementoStrutturale.replace('-', '_')]),
                        })}
                        {_.has(elementsTotalCountsMapping, elementoStrutturale) &&
                            `/${elementsTotalCountsMapping[elementoStrutturale]}`}
                    </Typography>
                    <Typography variant="h7">
                        {t('schede_con_difetti', {
                            n: schedeConDifettiCount[elementoStrutturale],
                        })}
                    </Typography>
                </Grid>
            )
        }
        return (
            <Grid item key={elementoStrutturale}>
                <CardActionArea>
                    <VerticalCard
                        titleFontSize={'1.7rem'}
                        title={t(elementoStrutturale)}
                        handleClick={() => onCardClick(elementoStrutturale)}
                        backgroundImage={image}
                    >
                        {elementoStrutturale !== 'elementi-accessori' && cardInfo}
                    </VerticalCard>
                </CardActionArea>
            </Grid>
        )
    })
}
