import React from 'react'
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    TrafficLayer,
    MarkerClusterer,
    Rectangle,
    Autocomplete,
} from '@react-google-maps/api'
import { DrawingManager } from '@react-google-maps/api'
import { google_maps_api_key } from '../../constants'
import { GOOGLE_MAPS_API_KEY_DEV } from '../../constants'
import MarkerIcon from '../../assets/customIcons/MarkerIcon.png'
import { Button, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import { arrayToCoords } from '../../utils'
import LoadingSpinner from '../../components/LoadingSpinner'
import {
    useProceduresFiltersStore,
    useProcedureSelectionOnMapStore,
    useProceduresStore,
} from '../../stateManagement'
import ClusterIcon from '../../assets/customIcons/ClusterIcon_dark.png'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    googleLikeButton: {
        minWidth: 40,
        height: 40,
        padding: 0,
        borderRadius: 2,
        position: 'absolute',
        bottom: 100,
        right: 0,
        margin: 10,
        // these colors are intentionally hardcoded
        backgroundColor: 'white',
        color: 'rgb(102,102,102)',
        '&:hover': {
            backgroundColor: 'white',
            color: 'rgb(51,51,51)',
        },
    },
    input: {
        width: 270,
        height: 40,
        padding: '0 12px',
        borderRadius: 2,
        border: 'none',
        fontSize: 16,
        outline: `none`,
        textOverflow: `ellipsis`,
        position: 'absolute',
        margin: 10,
        top: 0,
        right: 0,
    },
}))

const mapLibraries = ['places']

export default function CoordinatesPicker({
    coordinates,
    onChange,
    initialZoom,
    onConfirm,
    drawCoordinatesArea,
    rectangleCoords,
}) {
    const theme = useTheme()
    const { t } = useTranslation()
    const classes = useStyles()
    const [place, setPlace] = React.useState(null)
    const [zoom, setZoom] = React.useState(initialZoom)
    const [autocomplete, setAutocomplete] = React.useState(null)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: google_maps_api_key,
        libraries: ['drawing', 'places'],
        // libraries: mapLibraries,
    })
    const filteredProcedures = useProceduresFiltersStore((state) => state.filteredProcedures)
    const proceduresListRef = useProcedureSelectionOnMapStore((state) => state.proceduresListRef)
    const storedProcedures = useProceduresStore((state) => state.procedures)
    const [clusterKey, setClusterKey] = React.useState(6) // default zoom
    const [mapRectangle, setMapRectangle] = React.useState(null)

    React.useEffect(() => {
        if (coordinates?.length < 1) {
            centerOnUserPosition()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coordinates])

    const styles = React.useMemo(
        () => ({
            mapContainerStyle: { flex: 1 },
            clusterer: [
                {
                    url: ClusterIcon,
                    width: 45,
                    height: 45,
                    textSize: 17,
                    textColor: theme.palette.secondary.main,
                },
            ],
        }),
        [theme]
    )

    const centerOnUserPosition = () => {
        if (navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                    onChange(pos)
                },
                (err) => {
                    console.warn(err)
                }
            )
        } else {
            console.warn('no navigator!')
        }
    }

    function handleClick(e) {
        onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() })
    }

    const coords = arrayToCoords(coordinates)

    const onRectangleCompleted = (rectangle) => {
        if (mapRectangle) {
            mapRectangle.setMap(null)
        }

        setMapRectangle(rectangle)
        const bounds = rectangle.getBounds()
        const NE = bounds.getNorthEast()
        const SW = bounds.getSouthWest()
        const NW = new window.google.maps.LatLng(NE.lat(), SW.lng())
        const SE = new window.google.maps.LatLng(SW.lat(), NE.lng())

        onChange({
            NE: { lat: NE.lat(), lng: NE.lng() },
            NW: { lat: NW.lat(), lng: NW.lng() },
            SE: { lat: SE.lat(), lng: SE.lng() },
            SW: { lat: SW.lat(), lng: SW.lng() },
        })
    }
    const mapRef = React.useRef(null)
    const setSelectedProcedureId = useProcedureSelectionOnMapStore(
        (state) => state.setSelectedProcedureId
    )

    function handleMarkerClick(procedureId) {
        setSelectedProcedureId(procedureId)
        const index = filteredProcedures.findIndex((p) => p.id === procedureId)
        if (index >= 0) {
            proceduresListRef.current.scrollToItem(index, 'center')
            mapRef.current.setMapTypeId('satellite')
            mapRef.current.setZoom(22)
        }
    }

    function onAutocompleteLoad(autocomplete) {
        setAutocomplete(autocomplete)
    }

    function onAutocompletePlaceChanged() {
        if (autocomplete) {
            const place = autocomplete.getPlace()
            const lat = place?.geometry?.location?.lat()
            const lng = place?.geometry?.location?.lng()
            if (lat && lng) {
                onChange({ lat, lng })
                setZoom(19)
            }
            setPlace(place)
        } else {
            console.log('google autocomplete has not loaded yet')
        }
    }

    return !isLoaded ? (
        <LoadingSpinner />
    ) : (
        <>
            {!drawCoordinatesArea ? (
                <GoogleMap
                    clickableIcons={false}
                    mapContainerStyle={{ minHeight: 400, flex: 1 }}
                    center={coords}
                    zoom={zoom}
                    options={{
                        streetViewControl: false,
                        gestureHandling: 'cooperative',
                        maxZoom: 21,
                        rotateControl: false,
                        tilt: 0,
                        mapTypeId: window.google.maps.MapTypeId.HYBRID,
                        mapTypeControlOptions: {
                            mapTypeIds: [
                                window.google.maps.MapTypeId.ROADMAP,
                                window.google.maps.MapTypeId.HYBRID,
                            ],
                        },
                    }}
                    onClick={handleClick}
                >
                    <Autocomplete
                        onLoad={onAutocompleteLoad}
                        onPlaceChanged={onAutocompletePlaceChanged}
                    >
                        <input
                            type="text"
                            className={classes.input}
                            placeholder={t('map_autocomplete_placeholder')}
                        />
                    </Autocomplete>
                    {!coords ? null : (
                        <Marker position={coords} animation={'2'} icon={MarkerIcon} />
                    )}
                    <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={centerOnUserPosition}
                            size={'small'}
                            className={classes.googleLikeButton}
                            style={{
                                minWidth: 32,
                                maxWidth: 32,
                                minHeight: 32,
                                maxHeight: 32,
                                marginRight: 15,
                            }}
                        >
                            <GpsFixedIcon fontSize={'small'} />
                        </Button>
                        <Button variant={'contained'} color={'secondary'} onClick={onConfirm}>
                            <Typography variant={'caption'}>CONFERMA</Typography>
                        </Button>
                    </div>
                </GoogleMap>
            ) : (
                <>
                    <GoogleMap
                        clickableIcons={false}
                        mapContainerStyle={{ minHeight: '50vh', flex: 1 }}
                        center={coords}
                        zoom={initialZoom || 12}
                        options={{
                            streetViewControl: false,
                            gestureHandling: 'cooperative',
                            maxZoom: 19,
                            rotateControl: false,
                            tilt: 0,
                            mapTypeId: window.google.maps.MapTypeId.HYBRID,
                            mapTypeControlOptions: {
                                mapTypeIds: [
                                    window.google.maps.MapTypeId.ROADMAP,
                                    window.google.maps.MapTypeId.HYBRID,
                                ],
                            },
                        }}
                    >
                        <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={centerOnUserPosition}
                                size={'small'}
                                style={{
                                    minWidth: 32,
                                    maxWidth: 32,
                                    minHeight: 32,
                                    maxHeight: 32,
                                    marginRight: 15,
                                }}
                            >
                                <GpsFixedIcon fontSize={'small'} />
                            </Button>
                        </div>
                        <DrawingManager
                            drawingMode={'rectangle'}
                            onRectangleComplete={onRectangleCompleted}
                            options={{
                                drawingControlOptions: {
                                    drawingModes: [
                                        window.google.maps.drawing.OverlayType.RECTANGLE,
                                    ],
                                },
                            }}
                        />
                        <MarkerClusterer
                            maxZoom={18}
                            styles={styles.clusterer}
                            key={storedProcedures + clusterKey}
                        >
                            {(clusterer) =>
                                storedProcedures?.map((procedure) => {
                                    const validCoord = arrayToCoords(
                                        procedure?.gps_position?.coordinates
                                    )
                                    return !validCoord ? null : (
                                        <Marker
                                            title={procedure.name}
                                            onClick={() => handleMarkerClick(procedure.id)}
                                            key={procedure.id}
                                            position={validCoord}
                                            clusterer={clusterer}
                                            // animation={
                                            //     procedure.id === selectedProcedureId ? '1' : null
                                            // }
                                            icon={MarkerIcon}
                                        />
                                    )
                                })
                            }
                        </MarkerClusterer>
                        {rectangleCoords && rectangleCoords[3] && !mapRectangle ? (
                            <Rectangle
                                bounds={{
                                    north: rectangleCoords[2].coordinates[0],
                                    south: rectangleCoords[0].coordinates[0],
                                    west: rectangleCoords[1].coordinates[1],
                                    east: rectangleCoords[3].coordinates[1],
                                }}
                            />
                        ) : null}
                        <TrafficLayer autoUpdater />
                    </GoogleMap>

                    <Grid xs={12} spacing={3}>
                        <Grid item>
                            <Button variant={'contained'} color={'secondary'} onClick={onConfirm}>
                                <Typography variant={'caption'}>CONFERMA</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}
