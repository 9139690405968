import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import AppForm from './AppForm'
import DynamicFormFields from './FormItems/DynamicFormFields'
import { Button, DialogActions, Grid, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { useProceduresFiltersStore } from '../stateManagement'
import { useSnackBarStore } from '../stateManagement'
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: { flex: 1, overflowY: 'auto' },
    form: { flex: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' },
    node: { minHeight: 40 },
}))

function RecursiveTreeNode({ model, pathSoFar, onLeafClick }) {
    const { t } = useTranslation()
    const keysBlacklist = ['__meta', 'level3', 'la2_required']
    const typesBlacklist = ['attachment', 'fieldsArray']

    return (
        <>
            {_.map(model, (value, key) => {
                const updatedPath = pathSoFar ? pathSoFar + '.' + key : key
                const { _type, translatedLabel, ...valueWithoutType } = value
                const shouldRenderChildren = [undefined, 'year', 'group'].includes(_type)

                return typesBlacklist.includes(_type) || keysBlacklist.includes(key) ? null : (
                    <TreeItem
                        key={updatedPath}
                        style={{ marginTop: 10 }}
                        label={
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: translatedLabel || t(value.label || key),
                                }}
                            />
                        }
                        nodeId={updatedPath}
                        onClick={
                            shouldRenderChildren ? null : () => onLeafClick(updatedPath, _type)
                        }
                    >
                        {shouldRenderChildren && valueWithoutType && (
                            <RecursiveTreeNode
                                model={valueWithoutType}
                                pathSoFar={updatedPath}
                                onLeafClick={onLeafClick}
                            />
                        )}
                    </TreeItem>
                )
            })}
        </>
    )
}

export default function AdvancedSearchTool({
    onFiltersConfirm,
    startingModel,
    onClose,
    customFiltersValues,
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const filters = useProceduresFiltersStore((state) => state.filters)
    const filtersModel = useProceduresFiltersStore((state) => state.filtersModel)
    const setFilters = useProceduresFiltersStore((state) => state.setFilters)
    const setFiltersModel = useProceduresFiltersStore((state) => state.setFiltersModel)
    const refreshFiltersModel = useProceduresFiltersStore((state) => state.refreshFiltersModel)
    const addModelRule = useProceduresFiltersStore((state) => state.addModelRule)

    const showSnackBar = useSnackBarStore((state) => state.show)

    React.useEffect(() => {
        refreshFiltersModel({
            translationFunction: t,
            procedureModel: startingModel,
            autoCompleteValues: customFiltersValues
        })
        return () => setFiltersModel({})
    }, [t, startingModel, customFiltersValues, refreshFiltersModel, setFiltersModel])

    // using { rules } instead of a plain array because react-hook-form doesnt support arrays in top level
    const rules = filtersModel?.rules || {}
    const methods = useForm({
        shouldUnregister: true,
        defaultValues: filters,
    })
    const checkUndefinedValueAndOperator = (obj) => {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (!checkUndefinedValueAndOperator(obj[key])) {
                    return false
                }
            } else if (
                (key === 'valore' || key === 'operatore') &&
                (obj[key] === undefined || obj[key] === '')
            ) {
                return false
            }
        }
        return true
    }

    function onSubmit(data) {
        if (checkUndefinedValueAndOperator(data)) {
            setFilters(data)
            onFiltersConfirm()
            onClose()
        } else {
            showSnackBar({ message: 'Alcuni filtri non sono completi', severity: 'error' })
        }
    }

    function handleElementClick(clickedKey, clickedType) {

        addModelRule(clickedKey, {
            translationFunction: t,
            procedureModel: startingModel,
            autoCompleteValues: customFiltersValues
        })
        methods.reset(methods.getValues())
    }

    function removeFieldByKey(key) {
        const filteredRules = _.omitBy(rules, (model, loopKey) => key === loopKey)
        setFiltersModel({ rules: filteredRules })
    }

    return (
        <>
            <Typography variant="h6">{t('dati')}:</Typography>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultEndIcon={<AddIcon style={{ margin: '0 12px' }} color={'secondary'} />}
            >
                <RecursiveTreeNode
                    model={startingModel}
                    pathSoFar={''}
                    onLeafClick={handleElementClick}
                />
            </TreeView>
            <AppForm methods={methods} onSubmit={onSubmit} className={classes.form}>
                <Typography gutterBottom variant="h6">
                    {t('filtri')}:
                </Typography>
                <div style={{ overflowY: 'auto', paddingTop: 5 }}>
                    {_.isEmpty(rules) ? (
                        <Typography variant="caption">{t('no_filters')}</Typography>
                    ) : (

                            _.map(rules, (model, outerIndex) => {
                                return (
                                    <Grid
                                        style={{ alignItems: 'center' }}
                                        key={outerIndex + Object.keys(rules)}
                                        container
                                    >
                                        <Grid item xs={11}>
                                            <DynamicFormFields
                                                fieldsKey={`rules[${outerIndex}]`}
                                                formFields={model}
                                                drawCoordinatesArea={true}
                                                customFieldsValues={rules[outerIndex].valore.values}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                clickable
                                                color={'secondary'}
                                                onClick={() => removeFieldByKey(outerIndex)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })

                    )}
                </div>
                <DialogActions style={{ marginTop: 'auto' }}>
                    {/* <Button onClick={onClose}>{t('annulla')}</Button> */}
                    <Button type="submit" variant="contained" color="secondary">
                        {t('close')}
                    </Button>
                </DialogActions>
            </AppForm>
        </>
    )
}
