import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import lgp_logo from '../../../assets/lgp/lgp_logo.png'
import resisto_logo from '../../../assets/resisto/resisto_logo.png'
import statico_logo from '../../../assets/statico_logo.png'
import sismaBonus_logo from '../../../assets/sismaBonus_logo.png'
import { colorsMapping } from '../../../customTheme'

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: 100,
        width: '100%',
        background: theme.palette.background.contrast,
    },
    img: {
        width: 180,
        height: 80,
        backgroundColor: 'white',
        borderRadius: 8,
    },
    text: {
        color: theme.palette.secondary.contrastText,
        marginRight: theme.spacing(),
    },
    classValue: {
        background: theme.palette.background.paperLight,
        color: theme.palette.secondary.main,
    },
    sticker: {
        minHeight: 30,
        borderRadius: theme.shape.borderRadius,
        padding: '5px 10px',
        color: theme.palette.common.white,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
    },
}))

export default function MethodologyCard({ methodology, identifier }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const urlLocation = useLocation().pathname

    const classeToNumber = {
        null: 0,
        BASSA: 1,
        MEDIOBASSA: 2,
        MEDIA: 3,
        MEDIOALTA: 4,
        ALTA: 5,
    }

    const methodologiesMapping = {
        mit_guidelines: {
            logo: lgp_logo,
            url: `${urlLocation}/lgp`,
            backgroundSize: 'auto 90%',
        },
        resisto: {
            logo: resisto_logo,
            url: `${urlLocation}/resisto/panoramica`,
            backgroundSize: 'auto 90%',
        },
        statico: {
            logo: statico_logo,
            url: `${urlLocation}/statico`,
            backgroundSize: 'auto 90%',
        },
        sisma_bonus: {
            logo: sismaBonus_logo,
            url: `${urlLocation}/sisma_bonus`,
            backgroundSize: '90%',
        },
    }

    return (
        <CardActionArea>
            <Card
                className={classes.card}
                onClick={() => {
                    if (methodologiesMapping?.[identifier]?.url) {
                        history.push(methodologiesMapping[identifier].url)
                    }
                }}
            >
                <CardContent style={{ paddingBottom: 16 }}>
                    <Grid container wrap="nowrap" spacing={2} justify="space-around">
                        <Grid item>
                            <CardMedia
                                className={classes.img}
                                style={{
                                    backgroundSize:
                                        methodologiesMapping[identifier]?.backgroundSize ||
                                        'contain',
                                }}
                                image={methodologiesMapping[identifier]?.logo}
                            />
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography className={classes.text}>
                                {t('classe_risultante')}
                            </Typography>
                            {methodology?.is_unlocked ? (
                                <div
                                    className={classes.sticker}
                                    style={{
                                        background:
                                            colorsMapping[
                                                classeToNumber[methodology?.latest_result]
                                            ],
                                    }}
                                >
                                    <Typography>
                                        {methodology?.latest_result || t('N/D')}
                                    </Typography>
                                </div>
                            ) : (
                                <Avatar variant="rounded" className={classes.classValue}>
                                    <LockIcon fontSize="large" color="disabled" />
                                </Avatar>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </CardActionArea>
    )
}
