import { Divider, Grid, Paper, Typography } from '@material-ui/core'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import React from 'react'
import { colorsMapping } from '../../../customTheme'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { conversions } from '../../../utils'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
    },
}))

const CustomDot = (props) => {
    const { cx, cy, value } = props

    return (
        <circle cx={cx} cy={cy} r={7} stroke="white" strokeWidth={2} fill={colorsMapping[value]} />
    )
}

export default function BmsGraphic({ data }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <Paper elevation={0} className={classes.paper}>
            <Grid
                item
                container
                direction="column"
                justify="space-around"
                alignItems="center"
                spacing={3}
            >
                {/* TITOLO */}
                <Grid item>
                    <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                        {t('cda_complessiva')}
                    </Typography>
                    <LineChart
                        width={800}
                        height={400}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 70,
                            bottom: 70,
                        }}
                        syncId="id"
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="date" angle={45} tickMargin={25} />
                        <YAxis
                            ticks={[1, 2, 3, 4, 5]}
                            tickFormatter={(value) =>
                                t(
                                    Object.keys(conversions).find(
                                        (key) => conversions[key] === value
                                    )
                                )
                            }
                            interval="preserveStartEnd"
                            domain={[0, 5]}
                        />
                        <Tooltip
                            formatter={(value, name, props) => [
                                Object.keys(conversions).find((key) => conversions[key] === value),
                                t(name),
                            ]}
                            separator=": "
                            contentStyle={{ backgroundColor: theme.palette.background.page }}
                        />
                        <Line
                            type="monotone"
                            dataKey="cda"
                            stroke={theme.palette.secondary.main}
                            strokeWidth={3}
                            dot={<CustomDot />}
                            activeDot={{ r: 8 }}
                        />
                        {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                    </LineChart>
                </Grid>
                <Typography variant="h4" component="h1" align="center" gutterBottom paragraph>
                    {t('cda_intermedie')}
                </Typography>
                {Object.keys(data[0])
                    .filter((val) => val !== 'date' && val !== 'cda')
                    .map((inter_cda, index) => (
                        <Grid item key={index}>
                            {index !== 0 && <Divider className={classes.divider} />}
                            <LineChart
                                width={800}
                                height={400}
                                data={data}
                                margin={{
                                    top: 60,
                                    right: 30,
                                    left: 70,
                                    bottom: 60,
                                }}
                                syncId="id"
                            >
                                <Legend
                                    verticalAlign="top"
                                    height={36}
                                    formatter={(value, entry, index) => t(value)}
                                />
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey="date" angle={45} tickMargin={25} />
                                <YAxis
                                    ticks={[1, 2, 3, 4, 5]}
                                    tickFormatter={(value) =>
                                        t(
                                            Object.keys(conversions).find(
                                                (key) => conversions[key] === value
                                            )
                                        )
                                    }
                                    interval="preserveStartEnd"
                                    domain={[0, 5]}
                                />
                                <Tooltip
                                    formatter={(value, name, props) => [
                                        Object.keys(conversions).find(
                                            (key) => conversions[key] === value
                                        ),
                                        t(name),
                                    ]}
                                    separator=": "
                                    contentStyle={{
                                        backgroundColor: theme.palette.background.page,
                                    }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey={inter_cda}
                                    stroke={theme.palette.secondary.main}
                                    strokeWidth={3}
                                    dot={<CustomDot />}
                                    activeDot={{ r: 8 }}
                                />
                            </LineChart>
                        </Grid>
                    ))}
            </Grid>
        </Paper>
    )
}
