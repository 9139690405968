import React from 'react'
import dayjs from 'dayjs'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

export default function DateField({
    label,
    onChange,
    value,
    watchConfig,
    disableAll = false,
    ...props
}) {
    const { t } = useTranslation()
    const [selectedDate, setSelectedDate] = React.useState()

    const [additionalProps, setAdditionalProps] = React.useState({})
    const methods = useFormContext()
    let watchedValue
    if (methods && methods.watch) {
        watchedValue = watchConfig?.name
            ? // if it's a boolean, it needs to be converted to str otherwise the strict comparison below would fail
              String(methods.watch(watchConfig?.name))
            : null
    }
    const casesToWatch = watchConfig?.cases || undefined
    React.useEffect(() => {
        if (!casesToWatch) {
            if (!_.isEmpty(additionalProps)) {
                setAdditionalProps({})
            }
        } else {
            for (const [triggerValue, caseSettings] of Object.entries(casesToWatch)) {
                if (watchedValue === triggerValue) {
                    setAdditionalProps(caseSettings)
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casesToWatch, watchedValue])

    React.useEffect(() => {
        if (value) {
            setSelectedDate(dayjs(value))
        } else {
            setSelectedDate(null)
        }
    }, [value])

    function handleUpdate(dateObject, parsedDate) {
        setSelectedDate(dateObject)
    }

    function handleChangeAndUpdate(dateObject) {
        setSelectedDate(dateObject)
        return onChange(dateObject.toISOString())
    }

    function submit() {
        if (selectedDate) {
            try {
                selectedDate.toISOString()
                return onChange(selectedDate.toISOString())
            } catch (e) {
                return
            }
        } else return onChange()
    }

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils} locale={'it'}>
            <KeyboardDatePicker
                {...props}
                {...additionalProps}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={t(label || 'Data')}
                value={selectedDate}
                format="DD/MM/YYYY"
                maskChar={'_'}
                invalidDateMessage={t('datepicker_invalid_data_error')}
                onChange={handleUpdate}
                onAccept={handleChangeAndUpdate}
                onBlur={submit}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                disabled={disableAll}
            />
        </MuiPickersUtilsProvider>
    )
}
