import axios from 'axios'
import { unstable_batchedUpdates } from 'react-dom'
import { useSnackBarStore } from './stateManagement'
import i18n from './i18n'

export const backend_url =
    (window.backend_uri !== '{{backend_uri}}' ? window.backend_uri : 'http://localhost:80') +
    '/api/v1'
export const report_generator_url =
    window.report_generator_uri !== '{{report_generator_uri}}'
        ? window.report_generator_uri
        : 'http://localhost:3001'
export const environment = window.environment !== '{{environment}}' ? window.environment : 'dev'
export const google_maps_api_key =
    window.google_maps_api_key !== '{{google_maps_api_key}}'
        ? window.google_maps_api_key
        : 'AIzaSyDb8mSgB8_-Zaxbvj4b-lmsPnwWIFnF6WQ'

axios.defaults.baseURL = backend_url
axios.defaults.withCredentials = true
axios.interceptors.response.use(
    (response) => {
        if (!window.location.pathname.includes(['/access'])) {
            if (response.config.method === 'put' && response.status === 200) {
                useSnackBarStore.getState().show({
                    message: i18n.t('http_put_successful'),
                    severity: 'success',
                })
            }
        }
        return response
    },
    (error) => {
        if (error.response?.status === 401) {
            if (
                !['/login', '/privacy-policy', '/cookie-policy', '/landing'].includes(
                    window.location.pathname
                )
            ) {
                window.location = '/login?redirect=' + window.location.pathname
            }
        } else if (error.response?.status === 403) {
            unstable_batchedUpdates(() => {
                // to force react state changes outside of React components
                useSnackBarStore.getState().show({
                    message: `${i18n.t('forbidden')}: ${error.toJSON().config.url}`,
                    severity: 'error',
                })
            })
            window.history.back()
        }
        return Promise.reject(error)
    }
)

export const uninterceptedAxiosInstance = axios.create()

export const italyCenter = { lat: 41.70980865018976, lng: 11.452007188143401 }
