import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, List, ListItem } from '@material-ui/core'
import Section from '../../Section'
import MethodologyCard from './MethodologyCard'

export default function MethodologiesSection({
    methodologies = {},
    procedureType,
    buildingMaterial,
}) {
    const { t } = useTranslation()
    const [methodologiesToRender, setMethodologiesToRender] = React.useState({})

    React.useEffect(() => {
        let methodologiesToRender = {}
        if (procedureType === 'bridge') {
            // only mit_guidelines
            methodologiesToRender = methodologies
        } else {
            methodologiesToRender = {
                resisto: methodologies.resisto,
                statico: methodologies.statico,
            }
            if (buildingMaterial === 'MASONRY') {
                methodologiesToRender.sisma_bonus = methodologies.sisma_bonus
            }
        }
        setMethodologiesToRender(methodologiesToRender)
    }, [buildingMaterial])

    return (
        <Section xs={12} innerSpacing={3} direction="column">
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" align="center" gutterBottom>
                    {t('metodologie')}
                </Typography>
                {procedureType === 'building' && (
                    <Typography variant="body2" align="center">
                        {t('available_methodologies_subtitle')}{' '}
                        {/* FIXME c.a. should remain uppercase */}
                        <b>{t(buildingMaterial).toLowerCase()}</b>
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                        <List>
                            {/* al momento statico e sisma bonus sono nascoste perchè non implementate */}
                            {Object.keys(methodologiesToRender).map((methodologyName) => {
                                if (
                                    methodologyName === 'sisma_bonus' ||
                                    methodologyName === 'statico'
                                ) {
                                    return
                                } else {
                                    return (
                                        <ListItem key={methodologyName}>
                                            <MethodologyCard
                                                methodology={methodologies[methodologyName]}
                                                identifier={methodologyName}
                                            />
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Section>
    )
}
