import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Fragment } from 'react'

const useStyles = makeStyles((theme) => ({
    name: {
        color: theme.palette.text.secondary,
        fontSize: '0.5rem',
    },
    completed: {
        color: theme.palette.success.main,
    },
    current: {
        fontSize: '0.6rem',
        color: theme.palette.warning.main,
    },
    arrow: {
        color: theme.palette.text.secondary,
        fontSize: '0.7rem',
        fontWeight: 100,
        margin: '0 4px',
    },
}))

export default function ProcedureStepper({ steps }) {
    const classes = useStyles()

    function decideColor(step, index) {
        if (step.is_completed) {
            return `${classes.name} ${classes.completed}`
            // if the previous step is completed or if it's the first step
        } else if (steps[index - 1]?.is_completed || !steps[index - 1]) {
            return `${classes.name} ${classes.current}`
        }
        return `${classes.name}`
    }

    return (
        <Grid item container alignItems="center">
            {steps.map((step, index) => (
                <Fragment key={index}>
                    <Typography variant="caption" className={decideColor(step, index)}>
                        {step.name.toUpperCase()}
                    </Typography>
                    {index < steps.length - 1 ? (
                        <ArrowForwardIcon className={classes.arrow} />
                    ) : null}
                </Fragment>
            ))}
        </Grid>
    )
}
