import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OverviewSection from '../components/Procedures/OverviewSection'
import MethodologiesSection from '../components/Procedures/Methodologies/MethodologiesSection'
import { useMutation, useQuery } from 'react-query'
import { proceduresQueries, queryClient } from '../networking'
import { useCurrentProcedureStore, usePlaceStore, useUserProfileStore } from '../stateManagement'
import LoadingSpinner from '../components/LoadingSpinner'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}))

export default function ProcedurePage() {
    const classes = useStyles()
    const paramsProcedureId = useParams().procedureId
    const setCurrentProcedure = useCurrentProcedureStore(state => state.setCurrentProcedure)
    const setProcedureName = useCurrentProcedureStore((state) => state.setProcedureName)
    const setPlace = usePlaceStore((state) => state.setPlace)

    const { data: procedure, isLoading } = useQuery(
        [proceduresQueries.getProcedure.name, paramsProcedureId],
        () => proceduresQueries.getProcedure.fn(paramsProcedureId),
        {
            onSuccess: (data) => {
                setProcedureName(data.name)
                setCurrentProcedure(data)
            },
        }
    )

    const updateProcedureMutation = useMutation(proceduresQueries.updateProcedure.fn, {
        onSuccess: () => {
            queryClient.invalidateQueries([proceduresQueries.getProcedure.name, paramsProcedureId])
            setPlace(null)
        },
    })

    function updateProcedure(data) {
        updateProcedureMutation.mutate({ id: paramsProcedureId, data })
    }

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <OverviewSection
                            procedure={procedure}
                            isLoading={updateProcedureMutation.isLoading}
                            updateProcedure={updateProcedure}
                        />
                        <MethodologiesSection
                            methodologies={procedure?.methodologies}
                            procedureType={procedure?.type}
                            buildingMaterial={procedure?.building_material}
                        />
                    </>
                )}
            </Grid>
        </Container>
    )
}
