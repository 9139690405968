import React, { useEffect, useState } from 'react'
import { Paper, List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import {useCurrentProcedureStore} from "../../../stateManagement";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 150,
    },
    list: {
        background: theme.palette.primary.main,
    },
    levelBtn: {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
    },
    // material-ui override
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

export default function LGPToolbar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const paramsProcedureId = useParams().procedureId
    const currentUrl = useLocation().pathname
    const currentProcedure = useCurrentProcedureStore(state => state.currentProcedure)

    const [openAccordion, setOpenAccordion] = useState(null)

    useEffect(() => {
        if (currentUrl.includes('/lgp/livello-0')) {
            setOpenAccordion('LA0')
        } else if (currentUrl.includes('/lgp/livello-1')) {
            setOpenAccordion('LA1')
        } else {
            setOpenAccordion(null)
        }
    }, [currentUrl])

    const handleSubMenu = (accordionId) => {
        setOpenAccordion(accordionId === openAccordion ? null : accordionId)
    }

    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>
                {/* LA0 */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    onClick={() => handleSubMenu('LA0')}
                >
                    <ListItemText
                        primary="LA0"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                    {openAccordion === 'LA0' ? (
                        <ExpandLess style={{ position: 'absolute' }} />
                    ) : (
                        <ExpandMore style={{ position: 'absolute' }} />
                    )}
                </ListItem>
                <Divider light variant="middle" />
                <Collapse in={openAccordion === 'LA0'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            selected={_.includes(currentUrl, 'censimento')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/lgp/livello-0/censimento`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('censimento'))}
                            />
                        </ListItem>
                        <ListItem
                            button
                            selected={_.includes(currentUrl, 'documentazione')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/lgp/livello-0/documentazione`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('documentazione'))}
                            />
                        </ListItem>
                    </List>
                </Collapse>

                {/* LA1 */}
                <ListItem
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    button
                    onClick={() => handleSubMenu('LA1')}
                >
                    <ListItemText
                        primary="LA1"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                    {openAccordion === 'LA1' ? (
                        <ExpandLess style={{ position: 'absolute' }} />
                    ) : (
                        <ExpandMore style={{ position: 'absolute' }} />
                    )}
                </ListItem>
                <Divider light variant="middle" />
                <Collapse in={openAccordion === 'LA1'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            style={{ textAlign: 'center' }}
                            button
                            selected={_.includes(currentUrl, 'scheda-descrittiva')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/lgp/livello-1/scheda-descrittiva`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('scheda_descrittiva'))}
                            />
                        </ListItem>
                        <ListItem
                            style={{ textAlign: 'center' }}
                            button
                            selected={_.includes(currentUrl, 'frane-e-fenomeni-idraulici')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/lgp/livello-1/frane-e-fenomeni-idraulici`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('frane_e_fenomeni_idraulici'))}
                            />
                        </ListItem>
                        <ListItem
                            style={{ textAlign: 'center' }}
                            button
                            selected={_.includes(currentUrl, 'difettosita')}
                            onClick={() => {
                                history.push(
                                    `/procedures/${paramsProcedureId}/lgp/livello-1/difettosita`
                                )
                            }}
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                        >
                            <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={_.capitalize(t('difettosità'))}
                            />
                        </ListItem>
                    </List>
                </Collapse>

                {/* LA2 */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, 'livello-2')}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/lgp/livello-2`)
                    }}
                >
                    <ListItemText
                        primary="LA2"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* LA3 */}
                <ListItem
                    button
                    disabled={!(currentProcedure?.owner?.enabled_modules || []).includes("L3")}
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, 'livello-3')}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/lgp/livello-3`)
                    }}
                >
                    <ListItemText
                        primary="LA3"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* LA4 */}
                <ListItem
                    button
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, 'livello-4')}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/lgp/livello-4`)
                    }}
                >
                    <ListItemText
                        primary="LA4"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                </ListItem>
                <Divider light variant="middle" />

                {/* BMS */}
                <ListItem
                    button
                    disabled={!(currentProcedure?.owner?.enabled_modules || []).includes("BMS")}
                    classes={{
                        root: classes.root,
                        selected: classes.selected,
                    }}
                    selected={_.includes(currentUrl, 'BMS')}
                    onClick={() => {
                        history.push(`/procedures/${paramsProcedureId}/lgp/bms`)
                    }}
                >
                    <ListItemText
                        primary="BMS"
                        primaryTypographyProps={{
                            className: classes.levelBtn,
                        }}
                    />
                </ListItem>
            </List>
        </Paper>
    )
}
