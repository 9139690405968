import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import _ from 'lodash'
import { Dialog, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
    useCompanyStore,
    useProcedureCountStore,
    useProceduresFiltersStore,
    useProceduresStore,
} from '../stateManagement'
import {companiesQueries, proceduresQueries} from '../networking'
import {convertBackendSchemaToFrontendSchema, setModelAutocompleteValues} from '../utils'
import AdvancedSearchTool from '../components/AdvancedSearchTool'
import LoadingSpinner from '../components/LoadingSpinner'
import Map from '../components/Map/Map'
import ProceduresPanel from '../components/Procedures/ProceduresPanel'
import procedureModel from '../FormConfigs/procedureModel'
import {structureTypes} from "./models";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    homepage: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.background.default,
    },
    mapContainer: {
        minHeight: 300,
    },
    advancedFiltersContainer: {
        padding: theme.spacing(2),
        height: '90%',
    },
}))

export default function MapScreen() {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const params = useParams()
    const companies = useCompanyStore(state => state.companies)
    const selectedCompany = companies.find(company => company.id === params.companyId)
    const companySelect = selectedCompany?.id || 'tutti_gli_studi'
    const setCompanySelect = newId => history.push(`/home/${companies.find(company => company.id === newId)?.id || 'tutti_gli_studi'}`)
    const filters = useProceduresFiltersStore((state) => state.filters)
    const resetAllFilters = useProceduresFiltersStore((state) => state.resetAllFilters)
    const activeStructureTypes = useProceduresFiltersStore(state => state.activeStructureTypes)
    const setActiveStructureTypes = useProceduresFiltersStore(state => state.setActiveStructureTypes)
    const setProcedures = useProceduresStore((state) => state.setProcedures)
    const { setProceduresTotCount } = useProcedureCountStore((state) => ({
        setProceduresTotCount: state.setTotCount,
    }))

    React.useEffect(() => {
        resetAllFilters()
        const previouslyActiveStructureTypes = {...activeStructureTypes}
        setActiveStructureTypes(Object.assign({}, ...structureTypes.map(t => ({[t]: true}))))
        return () => setActiveStructureTypes(previouslyActiveStructureTypes)
    }, [])

    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = React.useState(false)
    function handleAdvancedSearchFiltersChange() {
        setIsAdvancedSearchOpen(false)
    }
    function closeFiltersDialog() {
        setIsAdvancedSearchOpen(false)
    }


    const {
        data: procedures = [],
        isLoading: isProceduresLoading,
        refetch: refetchProcedures,
    } = useQuery(
        // react-query automatically refetches when filters change
        [proceduresQueries.getProcedures.name, JSON.stringify(filters?.rules), selectedCompany?.id],
        () => {
            let rules = {
                ...(filters?.rules || {}),
                // type: structureTypes
                //     .filter(type => isStructureTypeActive(type))
                //     .map(type => ({
                //         operatore: 'eq',
                //         valore: type
                //     }))
            }
            return proceduresQueries.getProcedures.fn(rules, selectedCompany?.id);
        },
        {
            onSuccess: (data) => {
                if (_.isEmpty(filters.rules)) {
                    setProceduresTotCount(data.length)
                    setProcedures(data)
                }
                return data
            },
        }
    )

    const {data: extraFieldValues} = useQuery(
        [companiesQueries.getExtraFieldValues.name, selectedCompany?.id],
        () => companiesQueries.getExtraFieldValues.fn(selectedCompany?.id),
        {
            enabled: !!selectedCompany?.id
        }
    )

    function handleSelectCompany(id) {
        // resetAllFilters()
        setCompanySelect(id)
    }

    const searchableModel = React.useMemo(function() {
        const searchableModel = _.cloneDeep(procedureModel)
        if (selectedCompany?.settings?.procedure_extra_fields_schema && extraFieldValues) {
            const extraFieldsModel = convertBackendSchemaToFrontendSchema(selectedCompany.settings?.procedure_extra_fields_schema)
            setModelAutocompleteValues({extra_fields: extraFieldsModel}, extraFieldValues)
            searchableModel.extra_fields = extraFieldsModel
        }
        return searchableModel
    }, [selectedCompany, extraFieldValues])

    const companySelectOptions = [{ value: 'tutti_gli_studi', label: t('tutti_gli_studi') }]
    companies
        .map(company => ({value: company.id, label: company.name}))
        .sort((a, b) => 1 - 2 * (a.value.toUpperCase() < b.value.toUpperCase()))
        .forEach(option => companySelectOptions.push(option))

    return isProceduresLoading ? (
        <LoadingSpinner />
    ) : (
        <>
            <Grid container wrap="wrap-reverse" className={classes.homepage}>
                <Grid item container direction="column" xs={12} md={6} lg={4} id="proceduresPanel">
                    <ProceduresPanel
                        companies={companies}
                        companySelectOptions={companySelectOptions}
                        selectedCompany={companySelect}
                        setSelectedCompany={handleSelectCompany}
                        procedures={procedures}
                        loading={isProceduresLoading}
                        openAdvancedSearchDialog={() => setIsAdvancedSearchOpen(true)}
                        getProcedures={refetchProcedures}
                        BIinterface={false}
                    />
                </Grid>
                <Grid container item xs={12} md={6} lg={8} className={classes.mapContainer}>
                    <Map />
                </Grid>
            </Grid>
            <Dialog
                open={isAdvancedSearchOpen}
                onClose={closeFiltersDialog}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    className: classes.advancedFiltersContainer,
                    elevation: 0,
                    variant: 'outlined',
                }}
            >
                <AdvancedSearchTool
                    onFiltersConfirm={handleAdvancedSearchFiltersChange}
                    onClose={closeFiltersDialog}
                    startingModel={searchableModel}
                />
            </Dialog>
        </>
    )
}
