import { makeStyles } from '@material-ui/core/styles'
import { StarOutline as StarOutlineIcon } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    ExpandMore as ExpandMoreIcon,
    ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Typography,
    Button,
    ButtonGroup,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
    Divider,
    Tooltip,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'

import MitRischioIdraulicoTable from '../../../components/MitRischioIdraulicoTable'
import MitOverviewTable from '../../../components/MitOverviewTable'
import { colorsMapping } from '../../../customTheme'
import { backend_url } from '../../../constants'
import { useSnackBarStore } from '../../../stateManagement'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    CdAValue: {
        fontSize: '1.1rem',
        padding: '2px 5px',
        borderRadius: theme.shape.borderRadius,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
        color: theme.palette.common.white,
    },
    summaryRoot: {
        minHeight: 62,
    },
    extraordinaryIcon: {
        position: 'relative',
        top: 1,
        height: '15px',
        color: theme.palette.secondary.main,
    },
}))

export const InspectionAccordion = ({
    inspection,
    onDownload,
    isComparing,
    inspectionsChecked,
    isChecked,
    isExtraordinary,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const options = ['Scarica relazione', 'Scarica relazione firmata']
    const [isExpanded, setIsExpanded] = React.useState(false)

    const classeToNumber = {
        null: 0,
        'N/A': 0,
        BASSA: 1,
        MEDIOBASSA: 2,
        MEDIA: 3,
        MEDIOALTA: 4,
        ALTA: 5,
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index)
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function downloadFile(fileType = 'report' || 'signed_report') {
        const fileToSave = fileType === 'report' ? inspection.document : inspection.signed_document

        const fullUri = String(new URL(fileToSave.depot_url, backend_url))
        try {
            FileSaver.saveAs(fullUri, fileToSave.name)
        } catch (e) {
            showSnackBar({ message: t('download_failed'), severity: 'error' })
            console.error(e)
        }
    }

    const handleClick = () => {
        if (selectedIndex === 0) {
            downloadFile('report')
        } else if (selectedIndex === 1) {
            downloadFile('signed_report')
        }
    }

    React.useEffect(() => {
        isComparing === true && setIsExpanded(false)
    }, [isComparing])
    return (
        <Accordion
            className={classes['Mui-disabled']} // TODO: verificare
            onChange={(e, expanded) => {
                setIsExpanded(expanded)
            }}
            expanded={isExpanded}
        >
            <AccordionSummary
                className={classes.summaryRoot}
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header" // TODO: verificare
            >
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        {isComparing ? (
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <Checkbox
                                        size="small"
                                        onChange={inspectionsChecked}
                                        value={dayjs(inspection.inspection_date).format(
                                            'DD/MM/YYYY'
                                        )}
                                        checked={isChecked}
                                    />
                                }
                                label={
                                    <Typography>
                                        {dayjs(inspection.inspection_date).format('DD/MM/YYYY')}
                                        {isExtraordinary && (
                                            <StarOutlineIcon
                                                className={classes.extraordinaryIcon}
                                            />
                                        )}
                                    </Typography>
                                }
                            />
                        ) : (
                            <Typography>
                                {dayjs(inspection.inspection_date).format('DD/MM/YYYY')}
                                {isExtraordinary && (
                                    <Tooltip title="Ispezione straordinaria" placement="right">
                                        <StarOutlineIcon className={classes.extraordinaryIcon} />
                                    </Tooltip>
                                )}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography
                            component="h4"
                            className={classes.CdAValue}
                            style={{
                                background: colorsMapping[classeToNumber[inspection.data.result]],
                            }}
                        >
                            {!inspection.data.result || inspection.data.result === 'N/A'
                                ? t('N/D')
                                : inspection.data.result}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {/* TABELLE */}
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <MitOverviewTable
                            statusTrace={inspection.data.result_extra_info}
                            isPrevalutazione={false}
                        />
                    </Grid>
                    <Grid item>
                        <MitRischioIdraulicoTable
                            rischioIdraulico={inspection.data.result_extra_info.rischio_idraulico}
                            isPrevalutazione={false}
                        />
                        <Grid
                            container
                            spacing={3}
                            justify="space-between"
                            style={{ marginTop: '10px' }}
                        >
                            <Grid item xs={12} md={2}>
                                <Typography style={{ fontWeight: 'bolder' }}>
                                    {t('eventuali_note')}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} md={9}>
                                <Typography style={{ wordBreak: 'break-word' }}>
                                    {inspection?.data.eventuali_note || t('N/D')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography style={{ fontWeight: 'bolder' }}>
                                    {t('valutazione')}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} md={9}>
                                {inspection ? (
                                    <Typography>
                                        {dayjs(inspection.inspection_date).format('DD/MM/YYYY')}
                                        <br />
                                        {`${inspection.author_snapshot?.first_name} ${inspection.author_snapshot?.last_name}`}
                                    </Typography>
                                ) : (
                                    <Typography>{t('N/D')}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography style={{ fontWeight: 'bolder' }}>
                                    {t('inspection')}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} md={9}>
                                {inspection?.author_inspection ? (
                                    <Typography>
                                        {`${inspection.author_inspection?.first_name} ${inspection.author_inspection?.last_name}`}
                                    </Typography>
                                ) : (
                                    <Typography>{t('N/D')}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography style={{ fontWeight: 'bolder' }}>
                                    {t('inspection_notes')}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} md={9}>
                                <Typography style={{ wordBreak: 'break-word' }}>
                                    {inspection?.inspection_notes || t('N/D')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* DOWNLOAD BUTTON */}
                    <Grid container item justify="flex-end">
                        <ButtonGroup
                            variant="contained"
                            color="secondary"
                            ref={anchorRef}
                            aria-label="split button"
                            disabled={
                                (options[selectedIndex] === 'Scarica relazione firmata' &&
                                    !inspection.signed_document) ||
                                (options[selectedIndex] === 'Scarica relazione' &&
                                    !inspection.document)
                            }
                        >
                            <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                            <Button
                                color="secondary"
                                size="small"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="menu"
                                onClick={handleToggle}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="top-end"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList>
                                                {options.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index === selectedIndex}
                                                        onClick={(event) =>
                                                            handleMenuItemClick(event, index)
                                                        }
                                                        disabled={
                                                            (option ===
                                                                'Scarica relazione firmata' &&
                                                                !inspection.signed_document) ||
                                                            (option === 'Scarica relazione' &&
                                                                !inspection.document)
                                                        }
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
