import React from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useThemeStore } from '../stateManagement'

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        height: '100%',
    },
    card: {
        height: (props) => props.height || 280,
        width: (props) => props.width || 200,
        backgroundColor: (props) => props.backgroundColor || theme.palette.background.paper,
    },
    title: {
        marginBottom: theme.spacing(2),
        fontSize: (props) => props.titleFontSize,
    },
    image: {
        width: '45%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}))

export default function VerticalCard({
    title,
    children,
    backgroundImage,
    handleClick = null,
    ...props
}) {
    const classes = useStyles(props)
    const themeMode = useThemeStore((state) => state.themeMode)

    return (
        <Card raised onClick={handleClick} className={classes.card}>
            <CardContent className={classes.fullHeight} style={{ paddingBottom: 16, padding: 12 }}>
                <Grid
                    justify="space-between"
                    container
                    direction="column"
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.fullHeight}
                >
                    <Typography variant="h5" align="center" className={classes.title}>
                        {title}
                    </Typography>
                    {backgroundImage && (
                        <img
                            src={backgroundImage}
                            alt=""
                            className={classes.image}
                            style={{
                                filter: themeMode === 'light' ? 'brightness(0.4)' : 'none',
                            }}
                        />
                    )}
                    {children}
                </Grid>
            </CardContent>
        </Card>
    )
}
