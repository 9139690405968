import React from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TrafficLight from './TrafficLight'

const useStyles = makeStyles((theme) => ({
    table: {
        tableLayout: 'fixed',
        background: theme.palette.background.paperLight,
    },
    tableValue: {
        padding: '6px 8px 6px 8px',
    },
    tableHeadings: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '0.7rem',
        padding: '6px 8px 6px 8px',
    },
}))

export default function MitRischioIdraulicoTable({ rischioIdraulico = {} }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const rischio_erosione_generalizzata =
        rischioIdraulico.causes?.fenomeni_erosivi?.causes.rischio_erosione_generalizzata
    const rischio_erosione_localizzata =
        rischioIdraulico.causes?.fenomeni_erosivi?.causes.rischio_erosione_localizzata

    return (
        <>
            <Typography align="center" gutterBottom>
                {t('table_title_rischio_idraulico')}
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th"></TableCell>
                            <TableCell
                                component="th"
                                align="center"
                                className={classes.tableHeadings}
                            >
                                {t('tot')}
                            </TableCell>
                            <TableCell
                                component="th"
                                align="center"
                                className={classes.tableHeadings}
                            >
                                {t('table_heading_pericolosita')}
                            </TableCell>
                            <TableCell
                                component="th"
                                align="center"
                                className={classes.tableHeadings}
                            >
                                {t('table_heading_vulnerabilita')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" className={classes.tableHeadings}>
                                {t('fenomeni_erosivi').toUpperCase()}
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={rischioIdraulico.causes?.fenomeni_erosivi?.value}
                                    tooltipTitle={rischioIdraulico.causes?.fenomeni_erosivi?.value}
                                />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <Grid container justify="center" wrap="nowrap">
                                    <TrafficLight
                                        label="EG"
                                        value={rischio_erosione_generalizzata?.pericolosita}
                                        tooltipTitle={
                                            t('rischio_erosione_generalizzata') +
                                            ' - ' +
                                            (t(rischio_erosione_generalizzata?.pericolosita) ||
                                                t('N/D'))
                                        }
                                    />
                                    <Divider
                                        flexItem
                                        orientation="vertical"
                                        style={{
                                            margin: '0 5px',
                                        }}
                                    />
                                    <TrafficLight
                                        label="EL"
                                        value={rischio_erosione_localizzata?.pericolosita}
                                        tooltipTitle={
                                            t('rischio_erosione_localizzata') +
                                            ' - ' +
                                            (t(rischio_erosione_localizzata?.pericolosita) ||
                                                t('N/D'))
                                        }
                                    />
                                </Grid>
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <Grid container justify="center" wrap="nowrap">
                                    <TrafficLight
                                        label="EG"
                                        value={rischio_erosione_generalizzata?.vulnerabilita}
                                        tooltipTitle={
                                            t('rischio_erosione_generalizzata') +
                                            ' - ' +
                                            (t(rischio_erosione_generalizzata?.vulnerabilita) ||
                                                t('N/D'))
                                        }
                                    />
                                    <Divider
                                        flexItem
                                        orientation="vertical"
                                        style={{
                                            margin: '0 5px',
                                        }}
                                    />
                                    <TrafficLight
                                        label="EL"
                                        value={rischio_erosione_localizzata?.vulnerabilita}
                                        tooltipTitle={
                                            t('rischio_erosione_localizzata') +
                                            ' - ' +
                                            (t(rischio_erosione_localizzata?.vulnerabilita) ||
                                                t('N/D'))
                                        }
                                    />
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableHeadings}>
                                {t('fenomeni_sormonto').toUpperCase()}
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={rischioIdraulico.causes?.rischio_sormonto?.value}
                                    tooltipTitle={rischioIdraulico.causes?.rischio_sormonto?.value}
                                />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={
                                        rischioIdraulico.causes?.rischio_sormonto?.causes
                                            ?.pericolosita
                                    }
                                    tooltipTitle={
                                        rischioIdraulico.causes?.rischio_sormonto?.causes
                                            ?.pericolosita
                                    }
                                />
                            </TableCell>
                            <TableCell align="center" className={classes.tableValue}>
                                <TrafficLight
                                    value={
                                        rischioIdraulico.causes?.rischio_sormonto?.causes
                                            ?.vulnerabilita
                                    }
                                    tooltipTitle={
                                        rischioIdraulico.causes?.rischio_sormonto?.causes
                                            ?.vulnerabilita
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
