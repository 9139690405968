import React from 'react'
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api'
import { italyCenter, google_maps_api_key } from '../../constants'
import { colorsMapping } from '../../customTheme'
import ClusterIcon from '../../assets/customIcons/ClusterIcon_dark.png'
import LoadingSpinner from '../LoadingSpinner'
import {
    useProcedureSelectionOnMapStore,
    useMapStore,
    useProceduresFiltersStore,
} from '../../stateManagement'
import { useTheme } from '@material-ui/core/styles'
import { faBridge, faBuilding } from '@fortawesome/free-solid-svg-icons'

export default function Map() {
    const theme = useTheme()
    const [mapInstance, setMapInstance] = React.useState(null)
    const [clusterKey, setClusterKey] = React.useState(6) // default zoom
    const filteredProcedures = useProceduresFiltersStore((state) => state.filteredProcedures)
    const setMapRefStore = useMapStore((state) => state.setMapRef)
    const setSelectedProcedureId = useProcedureSelectionOnMapStore(
        (state) => state.setSelectedProcedureId
    )
    const selectedProcedureId = useProcedureSelectionOnMapStore(
        (state) => state.selectedProcedureId
    )
    const proceduresListRef = useProcedureSelectionOnMapStore((state) => state.proceduresListRef)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: google_maps_api_key,
        //do not remove, necessary for coordinates dialogs
        libraries: ['drawing', 'places'],
        // libraries: mapLibraries,
    })

    const mapRef = React.useRef(null)

    function arrayToCoords(someObj) {
        if (Array.isArray(someObj) && someObj.length === 2) {
            return { lat: someObj[0], lng: someObj[1] }
        } else if (someObj) {
            throw new Error('TypeError: expected {lat, lng} object but got ' + typeof someObj)
        }
    }

    function handleMarkerClick(procedureId) {
        setSelectedProcedureId(procedureId)

        const index = filteredProcedures.findIndex((p) => p.id === procedureId)
        if (index >= 0) {
            proceduresListRef.current.scrollToItem(index, 'center')
            mapRef.current.setMapTypeId('satellite')
            mapRef.current.setZoom(22)
        }
    }

    const handleMarkerColor = (procedure) => {
        const path = procedure.methodologies.mit_guidelines
            ? procedure.methodologies.mit_guidelines.latest_result
            : procedure.methodologies.resisto.latest_result
        switch (path) {
            case 'ALTA':
            case '5':
                return colorsMapping[5]
            case 'MEDIOALTA':
            case '4':
                return colorsMapping[4]
            case 'MEDIA':
            case '3':
                return colorsMapping[3]
            case 'MEDIOBASSA':
            case '2':
                return colorsMapping[2]
            case 'BASSA':
            case '1':
                return colorsMapping[1]
            default:
                return colorsMapping[6]
        }
    }

    function handleBoundsChanged() {
        // a ogni render il clusterer ricalcola più volte per ogni marker il cluster di appartenenza
        // con un db di 2k pratiche ca. lo sforzo computazionale del calcolo è enorme e blocca tutto
        // dopo infinite ricerche, l'unica soluzione trovata per adesso è:
        // posticipare il render dei cluster a quando la mappa ha presumibilmente finito di caricare
        setTimeout(() => {
            setClusterKey(Math.random())
        }, 500)
    }

    function changeMapTypeBasedOnZoom() {
        if (mapRef?.current?.getZoom() <= 15) {
            mapRef?.current?.setMapTypeId('roadmap')
        }
    }

    const onMapLoaded = React.useCallback(
        function onLoad(map) {
            const bounds = new window.google.maps.LatLngBounds()

            if (filteredProcedures.length < 1) {
                bounds.extend(italyCenter)
            } else {
                filteredProcedures.forEach((procedure) => {
                    const coordObj = arrayToCoords(procedure?.gps_position?.coordinates)
                    if (coordObj) {
                        bounds.extend(coordObj)
                    }
                })
            }
            map.fitBounds(bounds)

            mapRef.current = map
            setMapRefStore(map)
        },
        [filteredProcedures, setMapRefStore]
    )

    React.useEffect(() => {
        const procedure = filteredProcedures.find((x) => x.id === selectedProcedureId)
        const validCoord = arrayToCoords(procedure?.gps_position?.coordinates)
        if (validCoord) {
            mapRef?.current?.panTo(validCoord, { animated: true })
        }
    }, [filteredProcedures, selectedProcedureId])

    React.useEffect(() => {
        if (mapInstance) {
            onMapLoaded(mapInstance)
        }
    }, [filteredProcedures, mapInstance, onMapLoaded])

    // memoized for performance optimization as suggested by the docs
    const styles = React.useMemo(
        () => ({
            mapContainerStyle: { flex: 1, borderRadius: '20px' },
            clusterer: [
                {
                    url: ClusterIcon,
                    width: 45,
                    height: 45,
                    textSize: 17,
                    textColor: theme.palette.secondary.main,
                },
            ],
        }),
        [theme]
    )

    const mapOptions = {
        styles: [
            {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
            },
            // {
            //     featureType: 'traffic',
            //     elementType: 'labels',
            //     stylers: [{ visibility: 'off' }],
            // },
            {
                featureType: 'road',
                stylers: [{ visibility: 'on' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
        ],
        streetViewControl: true,
        gestureHandling: 'cooperative',
        minZoom: 4,
    }
    return !isLoaded ? (
        <LoadingSpinner />
    ) : (
        <GoogleMap
            onLoad={setMapInstance}
            mapContainerStyle={styles.mapContainerStyle}
            mapTypeId={'roadmap'}
            center={italyCenter}
            onZoomChanged={changeMapTypeBasedOnZoom}
            onBoundsChanged={handleBoundsChanged}
            zoom={6}
            tilt={45}
            options={mapOptions}
        >
            <MarkerClusterer
                maxZoom={18}
                styles={styles.clusterer}
                key={filteredProcedures + clusterKey}
            >
                {(clusterer) =>
                    filteredProcedures.map((procedure) => {
                        const validCoord = arrayToCoords(procedure?.gps_position?.coordinates)
                        return !validCoord ? null : (
                            <Marker
                                title={procedure.name}
                                icon={{
                                    path: procedure.methodologies.resisto
                                        ? faBuilding.icon[4]
                                        : faBridge.icon[4],
                                    fillColor: handleMarkerColor(procedure),
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: '#ffffff',
                                    scale: 0.05,
                                }}
                                onClick={() => {
                                    handleMarkerClick(procedure.id)
                                }}
                                key={procedure.id}
                                position={validCoord}
                                clusterer={clusterer}
                                animation={procedure.id === selectedProcedureId ? '1' : null}
                            />
                        )
                    })
                }
            </MarkerClusterer>
        </GoogleMap>
    )
}
