import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { useProceduresFiltersStore } from '../stateManagement'
import { useEffect } from 'react'
import {QuickFilterChip} from "./QuickFilterChip";

const useStyles = makeStyles((theme) => {
    return {
        chip: {
            marginLeft: 5,
            marginRight: 5,
        },
        activeChip: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:focus': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        inactiveChip: {
            backgroundColor: theme.palette.background.disabledModulePill,
        },
    }
})

export const CustomQuickFiltersChip = ({ possibleValues, filter, searchableModel }) => {
    const classes = useStyles()
    const isFilterBoolean = _.some(possibleValues, (value) => typeof value === 'boolean')
    const filters = useProceduresFiltersStore((state) => state.filters)
    const setFilters = useProceduresFiltersStore((state) => state.setFilters)
    const [booleanFilterValue, setBooleanFilterValue] = React.useState()
    const [value, setValue] = React.useState({ label: '', value: null })

    const transformArray = (arr) => {
        const filteredArray = arr?.filter((value) => value !== null)
        return filteredArray?.map((value) => ({
            label: _.isString(value) || _.isNumber(value) ? value.toString() : null,
        }))
    }

    useEffect(() => {
        // type text
        if (value && value.value !== null && typeof value.label === 'string') {
            let nuovoValore = value.label
            let operatore = 'equals'
            const fieldType = _.get(searchableModel, filter)
            if (fieldType && fieldType._type === 'number') {
                nuovoValore = parseFloat(value.label)
                operatore = 'eq'
            }
            const currentFilter = { chiave: undefined, operatore: operatore, valore: nuovoValore }
            const temporaryFilters = _.map(
                _.get(filters.rules?.extra_fields, filter),
                (item) => item
            )

            const filterCount = temporaryFilters.push(currentFilter)

            const clonedFilters = _.cloneDeep(filters)
            _.set(clonedFilters, 'rules.' + filter, temporaryFilters)
            setFilters(clonedFilters)
        }
    }, [value])

    useEffect(() => {
        if (typeof booleanFilterValue === 'boolean') {
            const currentFilter = {
                chiave: undefined,
                operatore: 'eq',
                valore: booleanFilterValue,
            }

            const finalFilters = {
                rules: {
                    ...filters.rules,
                    extra_fields: {
                        ...filters.extra_fields,
                        [filter]: [currentFilter],
                    },
                },
            }

            setFilters(finalFilters)
        }
    }, [booleanFilterValue])

    const handleClearSelection = (event, newValue) => {
        if (newValue === null || newValue === undefined) {
            setValue({ label: '', value: null })
            setFilters({
                rules: _.omit(filters.rules, filter),
            })
        } else {
            setValue(
                transformArray(possibleValues).find((option) => option.label === newValue.label)
            )
        }
    }

    const handleDeleteBooleanFilter = () => {
        setFilters({
            rules: _.omit(filters.rules, filter),
        })
        setBooleanFilterValue(undefined)
    }

    return (
        <>
            {isFilterBoolean ? (
                <Grid container spacing={1}>
                    {/* boolean filter */}
                    <Grid item>
                        <QuickFilterChip
                            label={"Sì"}
                            path={filter}
                            operatore={"eq"}
                            valore={true}
                        />
                    </Grid>
                    <Grid item>
                        <QuickFilterChip
                            label={"No"}
                            path={filter}
                            operatore={"eq"}
                            valore={false}
                        />
                    </Grid>
                    <Grid item>
                        <QuickFilterChip
                            label={"N/A"}
                            path={filter}
                            operatore={"eq"}
                            valore={null}
                        />
                    </Grid>
                </Grid>
            ) : (
                // text filter
                <>
                    {possibleValues && (
                        <Grid>
                            <Autocomplete
                                options={transformArray(possibleValues)}
                                getOptionLabel={(option) => option.label}
                                style={{ width: 300 }}
                                onChange={handleClearSelection}
                                value={value}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                )}
                            />
                        </Grid>
                    )}
                </>
            )}
        </>
    )
}
