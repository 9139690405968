import { useEffect } from 'react'
import { io } from 'socket.io-client'
import _ from 'lodash'
// import { generate_sio_jwt } from '../utils'
import { backend_url, report_generator_url, google_maps_api_key } from '../constants'
import difettiMap from '../assets/lgp/difetti_map.json'
import difettiInfo from '../assets/difetti_info.json'

export default function SocketIOClient({
    updateStatus,
    procedureId,
    methodology,
    type,
    withIntro,
}) {
    console.log('intro', withIntro)
    const generateDifetti = () => {
        /* Aggiungo informazioni mancanti a difettiMap */
        const mapDifetto = (elemento) =>
            _.map(elemento, (difetto) => {
                return {
                    ...difetto,
                    weight: difettiInfo[difetto.id].weight,
                    name: difettiInfo[difetto.id].name,
                }
            })
        return _.mapValues(difettiMap, (elemento) => {
            if (Array.isArray(elemento)) {
                return mapDifetto(elemento)
            } else {
                return _.mapValues(elemento, (materiale) => {
                    return mapDifetto(materiale)
                })
            }
        })
    }

    useEffect(() => {
        const socket = io(report_generator_url, {
            // TODO: restore some authentication
            // auth: {
            //     jwt: generate_sio_jwt(),
            // },
            reconnectionAttempts: 3,
        })
        // socket.io.on('reconnect_attempt', () => {
        //     socket.auth.jwt = generate_sio_jwt()
        // })
        const backend_base_url = String(new URL('/', backend_url)).slice(0, -1)
        socket.on('connect', () => {
            console.log('socket.io connected')
            socket.emit('generate', {
                procedure_id: procedureId,
                methodology: methodology,
                backend_url: backend_url,
                difetti: generateDifetti(),
                snapshot_get_path: '/snapshots',
                // remove last backslash (cant omit in URL constructor)
                report_type: type,
                backend_base_url: backend_base_url,
                googleMapKey: google_maps_api_key,
                withIntro: withIntro,
            })
        })

        console.log('using backend_base_url', backend_base_url)
        socket.on('disconnect', () => {
            console.log('socket.io disconnected')
        })
        socket.on('connect_error', (err) => {
            console.log('socket.io error', err)
            updateStatus({
                error_message: `connect error: ${err}`,
            })
        })
        socket.io.on('reconnect_failed', (err) => {
            console.log('socket.io reconnect_failed', err)
            updateStatus({
                error_message: `reconnect failed: ${err}`,
            })
        })
        socket.on('status', (msg) => {
            updateStatus(msg)
        })

        return () => socket.disconnect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}
