import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ReactComponent as ColoredStripe } from '../../../assets/landingPage/ColoredStripe.svg'
import { darkPalette } from '../../../customTheme'

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: darkPalette.background.default,
        color: darkPalette.text.primary,
        height: '64px'
    },
    link: {
        filter: 'opacity(0.5)',
        transition: '0.3s',
        '&:hover': {
            filter: 'none',
            transition: '0.3s',
        },
        marginLeft: '5px',
    },
    footerText: {
        filter: 'opacity(0.5)',
        marginLeft: '5px',
    }
}))
const LandingPageFooter = () => {
    const classes = useStyles()
    return (
        <Grid container direction='column'>
            <ColoredStripe />
            <Grid item container justify='center' alignItems='center' className={classes.footer}>
                <Grid item >
                    <Link target='_blank' to={{ pathname: 'https://builti.it/note-legali/' }} rel="noopener">
                        <Typography
                            variant="caption"
                            className={classes.link}
                        >
                            Note legali
                        </Typography>
                    </Link>
                </Grid>
                <Grid item>
                    <Typography
                        className={classes.footerText}
                    >
                        |
                    </Typography>
                </Grid>
                <Grid item>
                    <Link target='_blank' to={{ pathname: 'https://builti.it/credits/' }} rel="noopener">
                        <Typography
                            variant="caption"
                            className={classes.link}
                        >
                            Credits
                        </Typography>
                    </Link>
                </Grid>
                <Typography
                    className={classes.footerText}
                >
                    |
                </Typography>
                <Grid item>
                    <Link target='_blank' to={{ pathname: 'https://www.iubenda.com/privacy-policy/51965999' }} rel="noopener">
                        <Typography
                            variant="caption"
                            className={classes.link}
                        >
                            Privacy policy
                        </Typography>
                    </Link>
                </Grid>
                <Typography
                    className={classes.footerText}
                >
                    |
                </Typography>
                <Grid item>
                    <Link target='_blank' to={{ pathname: 'https://www.iubenda.com/privacy-policy/51965999/cookie-policy' }} rel="noopener">
                        <Typography
                            variant="caption"
                            className={classes.link}
                        >
                            Cookie policy
                        </Typography>
                    </Link>
                </Grid>
                <Typography
                    className={classes.footerText}
                >
                    |
                </Typography>
                <Grid item>
                    <Typography
                        variant="caption"
                        className={classes.footerText}

                    >
                        P. IVA IT 03735391207
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LandingPageFooter