import React from 'react'
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { capitalizeFirstLetter } from '../utils'

const useStyles = makeStyles((theme) => ({
    versionLink: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
        },
        '&hover': {},
    },
    subtitle: {
        marginBottom: '20px',
    },
    smallButton: {
        maxWidth: 180,
        height: 40,
        lineHeight: 1.2,
    },
    createdAtText: {
        color: theme.palette.text.secondary,
        marginRight: '5px',
    },
}))

export default function LA3Results({
    isNotAllowedToWrite,
    lastSnapshot, // serve per sapere l'autore e la data dell'ultimo snapshot
    showLastSnapshotAuthorDate = false,
    currentDataPath,
    isInBMSComparison = false,
    inspection,
}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [column, setColumns] = React.useState([])
    const [data, setData] = React.useState([])
    const [dataOldNorm, setDataOldNorm] = React.useState([])
    const [mCds, setMCds] = React.useState({})
    const [vCds, setVCds] = React.useState({})
    React.useEffect(() => {
        if (!currentDataPath.results) return
        if (currentDataPath.results.tipo_calcolo === 'APPOGGIO_APPOGGIO') {
            setColumns([
                { value: 'M<sub>max,storica</sub>', style: { width: '38.5%' } },
                { value: 'M<sub>max,2018</sub>', style: { width: '38.5%' } },
                { value: 'M<sub>max,storica</sub>/M<sub>max,2018</sub>', style: { width: '23%' } },
            ])
            setData([
                {
                    label: currentDataPath.results['m_max_normativa_storica'],
                    style: { width: '38.5%' },
                },
                {
                    label: currentDataPath.results['m_max_ultima_normativa'],
                    style: { width: '38.5%' },
                },
                {
                    label: currentDataPath.results['rapporto_m_max'],
                    style:
                        currentDataPath.results['rapporto_m_max'] > 1
                            ? { width: '23%', backgroundColor: '#77DD77', color: 'black' }
                            : { width: '23%', backgroundColor: '#FFFF66', color: 'black' },
                },
            ])
            setDataOldNorm([
                {
                    label: currentDataPath.results['m_max_normativa_storica'],
                    style: { width: '38.5%' },
                },
            ])
            if (currentDataPath.results['risultati_cds'] !== undefined) {
                currentDataPath.results['risultati_cds'].forEach((val) => {
                    setMCds(
                        Object.assign(mCds, {
                            [val['carico']]: [
                                { label: val['m_max_cds'], style: { width: '38.5%' } },
                                {
                                    label: val['rapporto_m_max'],
                                    style:
                                        val['rapporto_m_max'] > 1
                                            ? {
                                                  width: '23%',
                                                  backgroundColor: '#77DD77',
                                                  color: 'black',
                                              }
                                            : {
                                                  width: '23%',
                                                  backgroundColor: '#FFFF66',
                                                  color: 'black',
                                              },
                                },
                            ],
                        })
                    )
                    setVCds(
                        Object.assign(vCds, {
                            [val['carico']]: [
                                { label: val['v_max_cds'], style: { width: '36%' } },
                                {
                                    label: val['rapporto_v_max'],
                                    style:
                                        val['rapporto_v_max'] > 1
                                            ? {
                                                  width: '28%',
                                                  backgroundColor: '#77DD77',
                                                  color: 'black',
                                              }
                                            : {
                                                  width: '28%',
                                                  backgroundColor: '#FFFF66',
                                                  color: 'black',
                                              },
                                },
                            ],
                        })
                    )
                })
            }
        } else {
            setColumns([
                { value: 'M<sub>max<sup>+</sup>,storica</sub>', style: { width: '10%' } },
                { value: 'M<sub>max<sup>-</sup>,storica</sub>', style: { width: '10%' } },
                { value: 'M<sub>max<sup>+</sup>,2018</sub>', style: { width: '15%' } },
                { value: 'M<sub>max<sup>-</sup>,2018</sub>', style: { width: '15%' } },
                {
                    value: 'M<sub>max<sup>+</sup>,storica</sub>/M<sub>max<sup>+</sup>,2018</sub>',
                    style: { width: '25%' },
                },
                {
                    value: 'M<sub>max<sup>-</sup>,storica</sub>/M<sub>max<sup>-</sup>,2018</sub>',
                    style: { width: '25%' },
                },
            ])
            setData([
                {
                    label: currentDataPath.results['m_max_pos_normativa_storica'],
                    style: { width: '10%' },
                },
                {
                    label: currentDataPath.results['m_max_neg_normativa_storica'],
                    style: { width: '10%' },
                },
                {
                    label: currentDataPath.results['m_max_pos_ultima_normativa'],
                    style: { width: '15%' },
                },
                {
                    label: currentDataPath.results['m_max_neg_ultima_normativa'],
                    style: { width: '15%' },
                },
                {
                    label: currentDataPath.results['rapporto_m_max_pos'],
                    style:
                        currentDataPath.results['rapporto_m_max_pos'] > 1
                            ? { width: '25%', backgroundColor: '#77DD77', color: 'black' }
                            : { width: '25%', backgroundColor: '#FFFF66', color: 'black' },
                },
                {
                    label: currentDataPath.results['rapporto_m_max_neg'],
                    style:
                        currentDataPath.results['rapporto_m_max_neg'] > 1
                            ? { width: '25%', backgroundColor: '#77DD77', color: 'black' }
                            : { width: '25%', backgroundColor: '#FFFF66', color: 'black' },
                },
            ])
            setDataOldNorm([
                {
                    label: currentDataPath.results['m_max_pos_normativa_storica'],
                    style: { width: '10%' },
                },
                {
                    label: currentDataPath.results['m_max_neg_normativa_storica'],
                    style: { width: '10%' },
                },
            ])
            if (currentDataPath.results['risultati_cds'] !== undefined) {
                currentDataPath.results['risultati_cds'].forEach((val) => {
                    setMCds(
                        Object.assign(mCds, {
                            [val['carico']]: [
                                { label: val['m_max_pos_cds'], style: { width: '15%' } },
                                { label: val['m_max_neg_cds'], style: { width: '15%' } },
                                {
                                    label: val['rapporto_m_max_pos'],
                                    style:
                                        val['rapporto_m_max_pos'] > 1
                                            ? {
                                                  width: '25%',
                                                  backgroundColor: '#77DD77',
                                                  color: 'black',
                                              }
                                            : {
                                                  width: '25%',
                                                  backgroundColor: '#FFFF66',
                                                  color: 'black',
                                              },
                                },
                                {
                                    label: val['rapporto_m_max_neg'],
                                    style:
                                        val['rapporto_m_max_neg'] > 1
                                            ? {
                                                  width: '25%',
                                                  backgroundColor: '#77DD77',
                                                  color: 'black',
                                              }
                                            : {
                                                  width: '25%',
                                                  backgroundColor: '#FFFF66',
                                                  color: 'black',
                                              },
                                },
                            ],
                        })
                    )
                    setVCds(
                        Object.assign(vCds, {
                            [val['carico']]: [
                                { label: val['v_max_cds'], style: { width: '20%' } },
                                {
                                    label: val['rapporto_v_max'],
                                    style:
                                        val['rapporto_v_max'] > 1
                                            ? {
                                                  width: '80%',
                                                  backgroundColor: '#77DD77',
                                                  color: 'black',
                                              }
                                            : {
                                                  width: '80%',
                                                  backgroundColor: '#FFFF66',
                                                  color: 'black',
                                              },
                                },
                            ],
                        })
                    )
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDataPath])

    return (
        <>
            {currentDataPath.errors && !isNotAllowedToWrite ? (
                <h2>
                    Errore nel calcolo:{' '}
                    <pre style={{ overflowX: 'scroll' }}>{currentDataPath.errors}</pre>
                </h2>
            ) : (
                <>
                    {!currentDataPath.results ? (
                        <Typography variant="h5" align="center" style={{ margin: 0 }}>
                            {t('No data to calculate')}
                        </Typography>
                    ) : (
                        <>
                            {currentDataPath.results['risultati_cds']?.length === 0 ? (
                                <Typography
                                    className={classes.subtitle}
                                    variant="h6"
                                    align="center"
                                >
                                    {t('level_3_results_without_cds')}
                                </Typography>
                            ) : (
                                <Typography
                                    className={classes.subtitle}
                                    variant="h6"
                                    align="center"
                                >
                                    {t('level_3_results_with_cds')}
                                    {currentDataPath.results['risultati_cds']
                                        ?.at(-1)
                                        ['carico'].replaceAll('_', ' ')}
                                </Typography>
                            )}
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {column.map((c, i) => (
                                                <TableCell
                                                    key={i}
                                                    style={c.style}
                                                    dangerouslySetInnerHTML={{
                                                        __html: c.value,
                                                    }}
                                                />
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {data.map((d, i) => (
                                                <TableCell key={i} style={d?.style}>
                                                    {d?.label?.toFixed(3)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} style={{ marginTop: 10 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '38.5%' }}>
                                                V<sub>max,storica </sub>
                                            </TableCell>
                                            <TableCell style={{ width: '38.5%' }}>
                                                V<sub>max,2018</sub>
                                            </TableCell>
                                            <TableCell style={{ width: '23%' }}>
                                                V<sub>max,storica</sub>/V<sub>max,2018</sub>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {currentDataPath.results[
                                                    'v_max_normativa_storica'
                                                ]?.toFixed(3)}
                                            </TableCell>
                                            <TableCell>
                                                {currentDataPath.results[
                                                    'v_max_ultima_normativa'
                                                ]?.toFixed(3)}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    currentDataPath.results['rapporto_v_max'] > 1
                                                        ? {
                                                              backgroundColor: '#77DD77',
                                                              color: 'black',
                                                              width: '28%',
                                                          }
                                                        : {
                                                              color: 'black',
                                                              backgroundColor: '#FFFF66',
                                                              width: '28%',
                                                          }
                                                }
                                            >
                                                {currentDataPath.results['rapporto_v_max']?.toFixed(
                                                    3
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {_.get(currentDataPath, 'results.risultati_cds', []).map(
                                (risultato, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Typography
                                                variant="h5"
                                                component="h2"
                                                align="left"
                                                style={{ margin: '20px 0' }}
                                            >
                                                {capitalizeFirstLetter(
                                                    risultato['carico'].replaceAll('_', ' ')
                                                )}
                                            </Typography>
                                            <TableContainer
                                                component={Paper}
                                                style={{ marginTop: 10 }}
                                            >
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {column.map((c, i) => (
                                                                <TableCell
                                                                    key={i}
                                                                    style={c.style}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: c.value.replace(
                                                                            '2018',
                                                                            `cds ${risultato[
                                                                                'carico'
                                                                            ]
                                                                                .split('_')
                                                                                .at(-1)}`
                                                                        ),
                                                                    }}
                                                                />
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            {dataOldNorm.map((d, i) => (
                                                                <TableCell key={i} style={d.style}>
                                                                    {d.label?.toFixed(3)}
                                                                </TableCell>
                                                            ))}
                                                            {mCds[risultato.carico]?.map((d, i) => (
                                                                <TableCell key={i} style={d.style}>
                                                                    {d.label?.toFixed(3)}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TableContainer
                                                component={Paper}
                                                style={{ marginTop: 10 }}
                                            >
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ width: '38.5%' }}>
                                                                V<sub>max,storica</sub>
                                                            </TableCell>
                                                            <TableCell style={{ width: '38.5%' }}>
                                                                V
                                                                <sub>
                                                                    max,cds{' '}
                                                                    {risultato['carico']
                                                                        .split('_')
                                                                        .at(-1)}
                                                                </sub>
                                                            </TableCell>
                                                            <TableCell style={{ width: '23%' }}>
                                                                V<sub>max,storica</sub>/V
                                                                <sub>
                                                                    max,cds{' '}
                                                                    {risultato['carico']
                                                                        .split('_')
                                                                        .at(-1)}
                                                                </sub>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{ width: '36%' }}>
                                                                {currentDataPath.results[
                                                                    'v_max_normativa_storica'
                                                                ].toFixed(3)}
                                                            </TableCell>
                                                            <TableCell
                                                                style={
                                                                    vCds[risultato.carico]?.at(0)
                                                                        .style
                                                                }
                                                            >
                                                                {vCds[risultato.carico]
                                                                    ?.at(0)
                                                                    .label.toFixed(3)}
                                                            </TableCell>
                                                            <TableCell
                                                                style={
                                                                    vCds[risultato.carico]?.at(1)
                                                                        .style
                                                                }
                                                            >
                                                                {vCds[risultato.carico]
                                                                    ?.at(1)
                                                                    .label.toFixed(3)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </React.Fragment>
                                    )
                                }
                            )}
                        </>
                    )}
                </>
            )}
            {isInBMSComparison ? (
                <Grid container justify="flex-end">
                    <Grid item style={{ marginTop: '8px' }}>
                        <Typography
                            variant="caption"
                            classes={{
                                root: classes.createdAtText,
                            }}
                        >
                            {inspection.type === 'L3'
                                ? `${inspection.author_snapshot.first_name} ${
                                      inspection.author_snapshot.last_name
                                  } • ${dayjs(inspection.createdAt).format('DD/MM/YYYY HH:mm')} •`
                                : null}
                        </Typography>
                        {currentDataPath.results?.version && (
                            <Link to="/changelog">
                                <Typography
                                    variant="caption"
                                    classes={{
                                        root: classes.versionLink,
                                    }}
                                >
                                    v{currentDataPath.results?.version}
                                </Typography>
                            </Link>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Grid container justify="flex-end">
                    <Grid item style={{ marginTop: '8px' }}>
                        {showLastSnapshotAuthorDate && lastSnapshot && (
                            <Typography
                                variant="caption"
                                classes={{
                                    root: classes.createdAtText,
                                }}
                            >
                                {`${lastSnapshot.author_snapshot.first_name} ${
                                    lastSnapshot.author_snapshot.last_name
                                } • ${dayjs(lastSnapshot?.createdAt).format('DD/MM/YYYY HH:mm')} •`}
                            </Typography>
                        )}
                        {currentDataPath.results?.version && (
                            <Link to="/changelog">
                                <Typography
                                    variant="caption"
                                    classes={{
                                        root: classes.versionLink,
                                    }}
                                >
                                    v{currentDataPath.results?.version}
                                </Typography>
                            </Link>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    )
}
