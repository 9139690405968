import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { proceduresQueries } from '../../networking'
import { useQuery } from 'react-query'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/it'
import './inspectionCalendar.css'
import AlertDialog from '../../components/Dialogs/AlertDialog'
import { useHistory } from 'react-router-dom'

require('react-big-calendar/lib/css/react-big-calendar.css')

const useStyles = makeStyles((theme) => ({
    calendar: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        padding: 30,
    },
    pageContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    'rbc-toolbar': {
        '& button': { color: `${theme.palette.text.primary}` },
        '& button:focus': { color: `${theme.palette.text.primary}` },
        '& button:hover': { color: '#404040' },
        '& .rbc-active': {
            color: '#404040',
        },
    },
}))

const lang = {
    it: {
        week: 'Settimana',
        work_week: 'Settimana lavorativa',
        day: 'Giorno',
        month: 'Mese',
        previous: 'Indietro',
        next: 'Avanti',
        today: 'Oggi',
        agenda: 'Agenda',
    },
}

export default function InspectionCalendar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    moment.tz.setDefault('Europe/Rome')
    const localizer = momentLocalizer(moment)
    const [proceduresList, setProceduresList] = React.useState(null)
    const [eventsList, setEventsList] = React.useState(null)
    const [isEventDialogOpen, setIsEventDialogOpen] = React.useState(false)
    const [selectedProcedure, setSelectedProcedure] = React.useState(null)
    const today = new Date()

    const {
        data: procedures = [],
        isFetching: isProceduresLoading,
        refetch: getProcedures,
    } = useQuery(
        [proceduresQueries.getProcedures.name],
        () => proceduresQueries.getProcedures.fn({'next_inspection_date': [{operatore: 'ne', valore: null}]}),
        {
            onSuccess: (data) => {
                setProceduresList(data)
            },
            onError: (e) => console.log(e)
        }
    )

    const { messages } = {
        messages: lang['it'],
    }

    const handleEventClick = (e) => {
        setSelectedProcedure({ id: e.procedureId, title: e.procedureName })
        setIsEventDialogOpen(true)
    }

    const handleEventDialogConfirm = () => {
        history.push(`/procedures/${selectedProcedure.id}/lgp`)
        setSelectedProcedure(null)
    }

    React.useEffect(() => {
        const events = []
        proceduresList?.map((procedure, index) => {
            if (procedure.next_inspection_date != undefined) {
                events.push({
                    allDay: true,
                    end: new Date(procedure.next_inspection_date),
                    id: index,
                    start: new Date(procedure.next_inspection_date),
                    title: procedure.name,
                    procedureId: procedure.id,
                    procedureName: procedure.name,
                })
            }
        })
        setEventsList(events)
        console.log({events}, 'madonna puttanaaaaa')
    }, [proceduresList])

    return isProceduresLoading ? (
        <LoadingSpinner />
    ) : (
        <Grid
            item
            container
            direction="column"
            justify="space-around"
            alignItems="center"
            spacing={3}
            xs={12}
            className={classes.pageContainer}
        >
            <Grid container justify="center" xs={12}>
                <Grid item xs={10} className={classes.calendar}>
                    <Calendar
                        culture="it"
                        localizer={localizer}
                        events={eventsList}
                        startAccessor="start"
                        endAccessor="end"
                        messages={messages}
                        style={{ minHeight: '80vh' }}
                        onSelectEvent={handleEventClick}
                        className={classes['rbc-toolbar']}
                        defaultDate={new Date(today.getFullYear(), today.getMonth(), 1)}
                    />
                </Grid>
                <AlertDialog
                    open={isEventDialogOpen}
                    setIsOpen={setIsEventDialogOpen}
                    title={t(`calendar_goto_procedure_title`, { name: selectedProcedure?.title })}
                    primaryButton="conferma"
                    onSubmitCb={handleEventDialogConfirm}
                />
            </Grid>
        </Grid>
    )
}
