import React from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import {useTranslation} from "react-i18next";

export default function SearchableTextField({
    onChange,
    value,
    type,
    customFieldsValues = [],
    label,
    info,
    onClickInfo,
    ...props
}) {
    const {t} = useTranslation()
    const options = React.useMemo(() => {
        return customFieldsValues.filter(value => value !== null)
            .map(value => (_.isString(value) || _.isNumber(value) ? value.toString() : null))
    }, [customFieldsValues])

    const handleChange = React.useMemo(() => {
        return (type === 'number') ? ((event, newValue) => onChange(parseFloat(newValue))) : ((event, newValue) => onChange(newValue));
    }, [type, onChange])

    return (
        <>
            <Autocomplete
                options={options}
                freeSolo
                autoSelect
                fullWidth
                getOptionLabel={(option) => option}
                onChange={handleChange}
                value={options.find(
                    (option) => option === (value || "").toString()
                ) || _.isString(value) || _.isNumber(value) ? value.toString() : null}
                renderInput={(params) => <TextField {...params} {...props} label={t(label)} variant="outlined" />}
            />
        </>
    )
}
