import React from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, IconButton, Avatar, Badge } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsMenu from './NotificationsMenu'
import { companiesQueries, notificationQueries, profileQueries, queryClient } from '../networking'
import { getInitials } from '../utils'
import FibaLogoTesto from '../assets/Fiba_logo_testo.png'
import {useCompanyStore, useUserProfileStore} from '../stateManagement'
import { backend_url } from '../constants'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'space-between',
    },
    leftButton: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.common.white,
        width: 40,
        height: 40,
    },
    consentLink: {
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
}))

export default function TopBar({ onHamburgerClick }) {
    const classes = useStyles()
    const history = useHistory()
    const urlLocation = useLocation().pathname
    const [anchorEl, setAnchorEl] = React.useState(null)
    const { isSuperAdmin, setProfile, profile } = useUserProfileStore(state => state)
    const { setCompanies, setDashboardCompanies, setWriteCompanies } = useCompanyStore(state => state)

    const profileQuery = useQuery(profileQueries.getUser.name, profileQueries.getUser.fn, {
        onSuccess: (data) => setProfile(data),
    })


    useQuery(
        companiesQueries.getCompanies.name,
        () => companiesQueries.getCompanies.fn(),
        {
            enabled: profileQuery.isSuccess,
            onSuccess: data => {
                if (isSuperAdmin) {
                    setCompanies(data)
                    setDashboardCompanies(data)
                    setWriteCompanies(data)
                    return
                }

                const companies = data
                const dashboardCompanies = []
                const writeCompanies = []

                companies.forEach(company => {
                    if (company.admins.map(user => user?.id).includes(profile.id)) {
                        dashboardCompanies.push(company)
                        writeCompanies.push(company)
                    }
                    if (company.members.map(user => user?.id).includes(profile.id)) {
                        dashboardCompanies.push(company)
                    }
                })
                setCompanies(data)
                setDashboardCompanies(dashboardCompanies)
                setWriteCompanies(writeCompanies)
            }
        }
    )

    const { data: notifications = [] } = useQuery(
        notificationQueries.getNotifications.name,
        notificationQueries.getNotifications.fn,
        {
            refetchInterval: 60 * 1000, // REMEMBER: refetch query every minute!
            onSuccess: (data) => {
                data?.reverse()
                return data
            },
        }
    )

    const markAllNotificationsRead = useMutation(notificationQueries.markAllNotificationsRead.fn, {
        onSuccess: () => queryClient.invalidateQueries(notificationQueries.getNotifications.name),
    })

    const unreadNotificationsCount = notifications.filter((n) => n.read === false).length

    function handleNotificationsOpen(item) {
        setAnchorEl(item.currentTarget)
        if (unreadNotificationsCount > 0) {
            markAllNotificationsRead.mutate()
        }
    }

    function closeNotificationsMenu() {
        setAnchorEl(null)
    }

    function navigateToUserProfile() {
        if (urlLocation !== '/user/profile') history.push('/user/profile')
    }

    function goToRelevantUrl() {
        // const selectedCompany = companies.find((x) => x.name === companySelect)
        // if (selectedCompany) {
        //     return `/dashboard/${selectedCompany.id}`
        // }
        return '/home'
    }

    const hamburgerButton = (
        <IconButton
            onClick={onHamburgerClick}
            edge="start"
            className={classes.leftButton}
            aria-label="navigation menu"
            color="inherit"
        >
            <MenuIcon />
        </IconButton>
    )

    const rightButtons = (
        <div>
            <IconButton onClick={handleNotificationsOpen} color="inherit">
                <Badge
                    invisible={unreadNotificationsCount === 0}
                    badgeContent={unreadNotificationsCount}
                    overlap="circle"
                    color="secondary"
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <IconButton onClick={navigateToUserProfile}>
                {profile && (
                    <Avatar
                        src={
                            profile?.image?.depot_url &&
                            new URL(profile.image.depot_url, backend_url)
                        }
                        alt={`${profile.first_name} ${profile.last_name}`}
                        className={classes.avatar}
                    >
                        {getInitials(profile.first_name, profile.last_name)}
                    </Avatar>
                )}
            </IconButton>
        </div>
    )

    return (
        !['/login', '/privacy-policy', '/cookie-policy'].includes(urlLocation) && (
            <AppBar position="sticky">
                <Toolbar className={classes.toolbar}>
                    {hamburgerButton}
                    <Link to={goToRelevantUrl()} style={{ display: 'flex' }}>
                        <img src={FibaLogoTesto} alt="" width={130} />
                    </Link>
                    {rightButtons}
                </Toolbar>
                <NotificationsMenu
                    open={Boolean(anchorEl)}
                    closeMenu={closeNotificationsMenu}
                    anchorElement={anchorEl}
                    notifications={notifications}
                />
            </AppBar>
        )
    )
}
