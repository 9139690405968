import React, { useState } from 'react'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import MethodologyPage from '../../MethodologyPage'

import LGPLA1DifettositaCards from '../../../components/LGP/LA1/difettosita'
import { levelsQueries } from '../../../networking'

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        height: '100%',
        justifyContent: 'center',
    },
}))

export default function LGP_LA1_difettosita() {
    const classes = useStyles()
    const history = useHistory()
    const { procedureId } = useParams()

    const { data: livello0 = {} } = useQuery([levelsQueries.getMitLevel0.name, procedureId], () =>
        levelsQueries.getMitLevel0.fn(procedureId)
    )
    const { data: livello1 = {} } = useQuery([levelsQueries.getMitLevel1.name, procedureId], () =>
        levelsQueries.getMitLevel1.fn(procedureId)
    )

    const [elementsTotalCountsMapping, setElementsTotalCountMapping] = useState({})
    React.useEffect(() => {
        function calcTotalCount(element, source) {
            // n_elementi_totali is not used and will be removed from be
            const partialCounts = _.omit(source[element], 'n_elementi_totali')
            let sum = 0
            Object.entries(partialCounts).forEach(([material, number]) => {
                sum += number
            })
            return sum
        }

        if (Object.keys(livello0).length > 0 && Object.keys(livello1).length > 0) {
            const counts = {
                spalle: calcTotalCount('spalle', livello1.tipologia_elementi_strutturali),
                pile: calcTotalCount('pile', livello1.tipologia_elementi_strutturali),
                'apparecchi-appoggio': livello1.apparecchi_appoggio.n_apparecchi_tot || 0,
                giunti: livello0.giunti.numero_totale_giunti || 0,
                archi: calcTotalCount('arco', livello1.tipologia_elementi_strutturali_impalcato),
                travi:
                    calcTotalCount('travi', livello1.tipologia_elementi_strutturali_impalcato) +
                    calcTotalCount('traversi', livello1.tipologia_elementi_strutturali_impalcato),
                soletta: calcTotalCount(
                    'soletta',
                    livello1.tipologia_elementi_strutturali_impalcato
                ),
            }
            setElementsTotalCountMapping(counts)
        }
    }, [livello0, livello1])

    const [schedeConDifettiCount, setSchedeConDifettiCount] = useState({})
    React.useEffect(() => {
        function countSchedeConDifetti(schede) {
            let count = 0
            schede.forEach((scheda) => {
                if (calcDefectsNumber(scheda.difetti) >= 1) {
                    count++
                }
            })
            return count
        }

        function calcDefectsNumber(collection = {}) {
            let sum = 0
            Object.entries(collection).forEach(([key, value]) => {
                if ((value.estensione || value.intensita) && !value.motivo_assenza) {
                    sum += 1
                }
            })
            return sum
        }
        if (Object.keys(livello1).length > 0) {
            const counts = {
                spalle: countSchedeConDifetti(livello1.schede_ispezione.spalle),
                pile: countSchedeConDifetti(livello1.schede_ispezione.pile),
                'apparecchi-appoggio': countSchedeConDifetti(
                    livello1.schede_ispezione.apparecchi_appoggio
                ),
                giunti: countSchedeConDifetti(livello1.schede_ispezione.giunti),
                piedritti: countSchedeConDifetti(livello1.schede_ispezione.piedritti),
                archi: countSchedeConDifetti(livello1.schede_ispezione.archi),
                travi: countSchedeConDifetti(livello1.schede_ispezione.travi),
                soletta: countSchedeConDifetti(livello1.schede_ispezione.soletta),
            }
            setSchedeConDifettiCount(counts)
        }
    }, [livello1])

    return (
        <MethodologyPage methodology="lgp" maxWidth="lg">
            <Grid
                container
                justify="space-evenly"
                alignItems="center"
                spacing={5}
                className={classes.fullHeight}
            >
                <LGPLA1DifettositaCards
                    schedeIspezione={livello1?.schede_ispezione}
                    elementsTotalCountsMapping={elementsTotalCountsMapping}
                    schedeConDifettiCount={schedeConDifettiCount}
                    onCardClick={(elementoStrutturaleName) =>
                        history.push(`./difettosita/${elementoStrutturaleName}`)
                    }
                />
            </Grid>
        </MethodologyPage>
    )
}
