import * as React from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core'
import {
    faUserTie,
    faUser,
    faBookOpenReader,
    faFloppyDisk,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const iconStyle = {
    marginRight: '12px',
    fontSize: "18px"
}
export default function CompanyAnalitycsTable({
    admins,
    members,
    readOnlyUser,
    diskUsage
}) {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>
                            <Grid
                                container
                                alignItems='center'
                                justify='center'
                                wrap='nowrap'
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        style={iconStyle}
                                        icon={faUserTie}
                                        color="#FFBF45"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>{t('admin_section_title')}</Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align='center'>
                            <Grid
                                container
                                alignItems='center'
                                justify='center'
                                wrap='nowrap'
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        style={iconStyle}
                                        icon={faUser}
                                        color="#FFBF45"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>{t('members_section_title')}</Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align='center'>
                            <Grid
                                container
                                alignItems='center'
                                justify='center'
                                wrap='nowrap'
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        style={iconStyle}
                                        icon={faBookOpenReader}
                                        color="#FFBF45"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>{t('readers_section_title')}</Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align='center'>
                            <Grid
                                container
                                alignItems='center'
                                justify='center'
                                wrap='nowrap'
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        style={iconStyle}
                                        icon={faFloppyDisk}
                                        color="#FFBF45"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>{t('disk_usage')}</Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow >
                        <TableCell align="center">{admins}</TableCell>
                        <TableCell align="center">{members}</TableCell>
                        <TableCell align="center">{readOnlyUser}</TableCell>
                        <TableCell align="center">
                            <Typography>
                                {diskUsage}
                                <Tooltip title={t('GB_equivalenti_helptext')}>
                                    <FontAwesomeIcon
                                        style={{ fontSize: "12px", marginBottom: "8px", marginLeft: "4px" }}
                                        icon={faCircleInfo}
                                        color="#FFBF45"
                                    />
                                </Tooltip>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}