import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { Grid, Typography, Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import WorkIcon from '@material-ui/icons/Work'
import AppSelect from '../FormItems/AppSelect'
import SearchBar from '../FormItems/SearchBar'
import SearchDialog from '../Dialogs/SearchDialog'
import ProceduresList from './ProceduresList'
import FilterIcon from '@material-ui/icons/FilterList'
import LoadingSpinner from '../LoadingSpinner'
import AppTooltip from '../AppTooltip'
import {
    useProceduresFiltersStore,
    useProcedureCountStore
} from '../../stateManagement'
import { useUserProfileStore } from '../../stateManagement'
import {AppChip} from "../QuickFilterChip";
import {ClearAll} from "@material-ui/icons";
import {structureTypes} from "../../screens/models";

const useStyles = makeStyles((theme) => ({
    top: {
        margin: theme.spacing(2),
        marginBottom: theme.spacing(),
        width: 'auto',
    },
    tools: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: 20,
        padding: theme.spacing(0.5),
        width: 'auto',
        position: 'relative',
    },
    nonBiTools: {
        background: theme.palette.background.paper,
        marginTop: theme.spacing(),
    },
    procedures: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        flex: 1,
    },
}))

export default function ProceduresPanel({
    procedures = [],
    openAdvancedSearchDialog,
    companies = [],
    selectedCompany,
    setSelectedCompany,
    companySelectOptions,
    loading,
    getProcedures,
    BIinterface = false,
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const {
        filters,
        setFilteredProcedures
    } = useProceduresFiltersStore((state) => state)

    const filteredProcedures = useProceduresFiltersStore((state) => state.filteredProcedures)
    const resetAllFilters = useProceduresFiltersStore(state => state.resetAllFilters)
    const activeStructureTypes = useProceduresFiltersStore((state) => state.activeStructureTypes)
    const setActiveStructureTypes = useProceduresFiltersStore(state => state.setActiveStructureTypes)
    const proceduresTotCount = useProcedureCountStore(state => state.totCount)

    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [searchInput, setSearchInput] = React.useState('')
    const profile = useUserProfileStore((state) => state.profile)


    const toggleStructureType = (type) => {
        activeStructureTypes[type] = !activeStructureTypes[type]
        setActiveStructureTypes(activeStructureTypes)
    }

    function goToCreateProcedure(companyId) {
        history.push('/procedures/init', { companyId })
    }
    const filteredCompanies = companies.filter((company) => {
        return company.admins.some((admin) => admin.id === profile.id)
    })

    const fuseOptions = { keys: ['name'], threshold: 0.5 }
    const [fuse] = React.useState(new Fuse([], fuseOptions))

    const companyIdsStr = JSON.stringify(companies.map(company => company.id).sort())

    React.useEffect(() => {
        const f =  procedures
            .filter(procedure => activeStructureTypes[procedure.type])
            || []
        if (searchInput) {
            fuse.setCollection(f)
            setFilteredProcedures(fuse.search(searchInput).map((p) => p.item))
        } else {
            setFilteredProcedures(f)
        }
    }, [
        procedures,
        selectedCompany,
        searchInput,
        companyIdsStr,
        // JSON.stringify(companies),
        fuse,
        setFilteredProcedures,
        activeStructureTypes
    ])

    function handleChangeSelectedCompany(e) {
        const newValue = e.target.value
        setSelectedCompany(newValue)
        // history.replace(`/dashboard/${newValue}`)
    }

    const isFiltersActive = Object.keys(filters?.rules || {}).length !== 0
    return (
        <>
            {!BIinterface && (
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                    className={classes.top}
                >
                    <Grid item>
                        <Typography variant="h4" component="h1">
                            {t('pratiche')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {filteredCompanies.length ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddIcon />}
                                onClick={() => setIsDialogOpen(true)}
                            >
                                {t('nuova_pratica')}
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                container
                direction="column"
                justify="space-between"
                className={`${classes.tools} ${!BIinterface && classes.nonBiTools}`}
                spacing={1}
                style={{marginBottom: 5}}
            >
                {!BIinterface && (
                    <Grid item container spacing={1} direction={"row"}>
                        {structureTypes.map(type => (
                            <Grid item key={type}>
                                <AppChip
                                    onClick={procedures.find(procedure => procedure.type === type) && (() => toggleStructureType(type))}
                                    isActive={activeStructureTypes[type] && filteredProcedures.find(procedure => procedure.type === type)}
                                    label={t(type)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                )}
                {!BIinterface && (
                    <Grid item justify="space-between" alignItems="center">
                        <AppSelect
                            optionsArray={companySelectOptions}
                            value={selectedCompany}
                            onChange={handleChangeSelectedCompany}
                            translate={false}
                        />
                    </Grid>
                )}
                <SearchBar
                    item
                    onIconClick={() => {
                        setIsDialogOpen(true)
                    }}
                    justify="flex-end"
                    input={searchInput}
                    setInput={setSearchInput}
                    useLabel={BIinterface}
                    BIinterface={BIinterface}
                    endAdornment={() => (
                        <>
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {isFiltersActive
                                            ? `${t('advanced_search')} - ${t(
                                                  'some_filters_are_active'
                                              )}!`
                                            : t('advanced_search')}
                                    </Typography>
                                }
                            >
                                <IconButton onClick={openAdvancedSearchDialog}>
                                    <FilterIcon />
                                </IconButton>
                            </AppTooltip>
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {t("clear_all_filters")}
                                    </Typography>
                                }
                            >
                                <IconButton
                                    onClick={resetAllFilters}
                                    disabled={!isFiltersActive}
                                >
                                    <ClearAll/>
                                </IconButton>
                            </AppTooltip>
                        </>
                    )}
                />
                <Grid item>
                    <Typography
                        // component={'h1'}
                        style={{ fontSize: '2em', marginRight: 5 }}
                        // color={'secondary'}
                    >
                        {/*{`${filteredProcedures.length}/${proceduresTotCount}`}*/}
                        {filteredProcedures.length}/{proceduresTotCount} {t("search_results")}
                    </Typography>
                    {/*<Typography variant={'caption'}>{t('search_results')}</Typography>*/}
                </Grid>
            </Grid>
            <Grid item className={classes.procedures}>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <ProceduresList procedures={filteredProcedures} getProcedures={getProcedures} />
                )}
            </Grid>

            <SearchDialog
                open={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                title="pratica_selezione_company_titolo"
                subtitle="pratica_selezione_company_testo"
                data={filteredCompanies}
                handleResultClick={goToCreateProcedure}
                icon={<WorkIcon />}
            />
        </>
    )
}
