import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BackgroundTexture from '../../assets/BackgroundTexture.svg'
import LandingPageCard from './components/LandingPageCard'
import Building from '../../assets/landingPage/Building.jpeg'
import Infrastructure from '../../assets/landingPage/Infrastructure.png'
import IOT from '../../assets/landingPage/IOT.png'
import FibaLogo from '../../assets/landingPage/FibaLogo.svg'
import LandingPageFooter from './components/LandingPageFooter'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url('${BackgroundTexture}'),linear-gradient(#4D6384,#0B0D0F)`,
        backgroundSize: 'contain',
    },
    logo: {
        marginTop: '50px',
        flex: 1,
        alignSelf: 'center',
    },
    cards: {
        marginTop: '30px',
        marginBottom: '30px',
        flex: 4,
    },
    card: {
        margin: theme.spacing(3),
    },
}))
export default function LandingPage() {
    const classes = useStyles()
    const cards = [
        {
            image: Building,
            title: 'Building',
            text: 'Metodologia speditiva per la valutazione della vulnerabilità sismica e statica degli edifici a costi e tempi contenuti.',
            externalLink: 'https://resisto-prod.herokuapp.com',
        },
        {
            image: Infrastructure,
            title: 'Infrastructure',
            text: 'Metodologia speditiva per l’analisi difettologica e il monitoraggio dinamico di infrastrutture di trasporto.',
            externalLink: '/home',
        },
        {
            image: IOT,
            title: 'IOT',
            text: 'Monitoraggio dinamico tramite sensori, raccolta ed elaborazione dei dati anche ai fini di analisi predittive.',
            externalLink: 'https://sensors.staging.tigroup.dev',
        },
    ]
    return (
        <Grid container>
            <Grid
                container
                className={classes.background}
                justify="space-between"
                direction="column"
            >
                <Grid item className={classes.logo}>
                    <img src={FibaLogo} width={150} alt="" />
                </Grid>
                <Grid item container justify="center" className={classes.cards}>
                    {cards.map((card) => (
                        <Grid item key={card.title} className={classes.card}>
                            <LandingPageCard
                                image={card.image}
                                title={card.title}
                                text={card.text}
                                externalLink={card.externalLink}
                            />
                        </Grid>
                    ))}
                </Grid>
                <LandingPageFooter />
            </Grid>
        </Grid>
    )
}
