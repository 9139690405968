import { Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CdA_absent from '../../../assets/CdA-absent.png'
import CdA_alta from '../../../assets/CdA-alta.png'
import CdA_bassa from '../../../assets/CdA-bassa.png'
import CdA_media from '../../../assets/CdA-media.png'
import CdA_medio_alta from '../../../assets/CdA-medio_alta.png'
import CdA_medio_bassa from '../../../assets/CdA-medio_bassa.png'
import MitOverviewTable from '../../../components/MitOverviewTable'
import MitRischioIdraulicoTable from '../../../components/MitRischioIdraulicoTable'
import { colorsMapping } from '../../../customTheme'

const useStyles = makeStyles((theme) => ({
    CdATitle: {
        fontSize: '1.3rem',
    },
    CdAValue: {
        fontSize: '1.1rem',
        padding: '2px 5px',
        borderRadius: theme.shape.borderRadius,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
        color: theme.palette.common.white,
    },
    imgContainer: {
        width: '100%',
        height: 200,
        display: 'flex',
        background: '#fff',
        borderRadius: theme.shape.borderRadius,
    },
    versionLink: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
        },
        '&hover': {},
        minHeight: 22
    },
}))

const CdAImgMap = {
    ALTA: CdA_alta,
    MEDIOALTA: CdA_medio_alta,
    MEDIA: CdA_media,
    MEDIOBASSA: CdA_medio_bassa,
    BASSA: CdA_bassa,
}
const classeToNumber = {
    null: 0,
    'N/A': 0,
    BASSA: 1,
    MEDIOBASSA: 2,
    MEDIA: 3,
    MEDIOALTA: 4,
    ALTA: 5,
}
export default function LA2Results({ results }) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid container direction="column">
            <Grid container justify="center" style={{ marginBottom: 10 }} spacing={1}>
                <Grid item>
                    <Typography component="h4" className={classes.CdATitle}>
                        {t('classe_attenzione')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        component="h4"
                        className={classes.CdAValue}
                        style={{
                            background: colorsMapping[classeToNumber[results.data.data.result]],
                        }}
                    >
                        {!results.data.data.result || results.data.data.result === 'N/A'
                            ? t('N/D')
                            : results.data.data.result}
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.imgContainer}>
                <div
                    style={{
                        background: `#fff url(${CdAImgMap[results.data.data.result] || CdA_absent
                            }) center/contain no-repeat`,
                        margin: 20,
                        flex: 1,
                    }}
                />
            </div>
            <MitOverviewTable
                statusTrace={results.data.data.result_extra_info}
                isPrevalutazione={false}
            />
            <MitRischioIdraulicoTable
                rischioIdraulico={results.data.data.result_extra_info.rischio_idraulico}
                isPrevalutazione={false}
            />
            <Grid item style={{ alignSelf: 'end' }}>
                <Link to="/changelog">
                    <Typography
                        variant="caption"
                        display='block'
                        classes={{
                            root: classes.versionLink,
                        }}
                    >
                        {results.data.data.result_extra_info.version &&
                            `v${results.data.data.result_extra_info.version}`
                        }
                    </Typography>
                </Link>
            </Grid>
            <Grid container spacing={3} justify="space-around">
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 'bolder' }}>{t('valutazione')}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography
                        style={{ fontWeight: 'bolder' }}
                        color="textSecondary"
                        align="center"
                    >
                        {t('info')}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm={8} md={9}>
                    <Typography>
                        {dayjs(results.data.created_at).format('DD/MM/YYYY HH:mm')}
                        <br />
                        {`${results.data.author_snapshot?.first_name} ${results.data.author_snapshot?.last_name}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography
                        style={{ fontWeight: 'bolder' }}
                        color="textSecondary"
                        align="center"
                    >
                        {t('note')}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} md={9}>
                    <Typography
                        style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {results.data.data.eventuali_note || t('N/D')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{ fontWeight: 'bolder' }}
                    >
                        {t('inspection')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography
                        style={{ fontWeight: 'bolder' }}
                        color="textSecondary"
                        align="center"
                    >
                        {t('info')}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm={8} md={9}>
                    <Typography>
                        {dayjs(results.data.inspection_date).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    {results.data.author_inspection ? (
                        <Typography>
                            {`${results?.data.author_inspection.first_name} ${results?.data.author_inspection.last_name}`}
                        </Typography>
                    ) : (
                        <Typography>{t('N/D')}</Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography
                        style={{ fontWeight: 'bolder' }}
                        color="textSecondary"
                        align="center"
                    >
                        {t('note')}
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} md={9}>
                    <Typography
                        style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {results?.data.inspection_notes ||
                            t('N/D')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
