import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import {
    Folder as FolderIcon,
    InsertInvitation as InsertInvitationIcon,
    ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons'

import DashboardCard from './DashboardCard'
import { useProceduresFiltersStore } from '../../../stateManagement'
import { availableCompaniesQueries } from '../../../networking'
import { handleExcelResponseFile } from '../../../utils'
import dayjs from 'dayjs'
import LGPPieCharts from './LGPPieCharts'

export const LGPCard = ({ company, isLoading }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const filters = useProceduresFiltersStore((state) => state.filters)

    const {
        data: methodologyAnalytics = {},
        isFetching: isMethodologyAnalyticsFetching,
        refetch: refetchMethodologyAnalytics,
    } = useQuery(
        [availableCompaniesQueries.getMethodologyAnalytics.name, company?.id],
        () =>
            availableCompaniesQueries.getMethodologyAnalytics.fn({
                companyId: company?.id,
                methodology: 'mit_guidelines',
                filters: filters?.rules,
            }),
        {
            enabled: Boolean(company?.id),
            retry: false,
            onError: (e) => {
                console.error('errore', e)
            },
        }
    )

    const { mutate: getLGPReport, isLoading: isLgpReportFileFetching } = useMutation(
        availableCompaniesQueries.getProceduresReportFile.fn,
        {
            onSuccess: (data) => {
                handleExcelResponseFile(data, `${company.name}-${dayjs().format('DD/MM/YYYY')}`)
            },
        }
    )

    React.useEffect(refetchMethodologyAnalytics, [filters, refetchMethodologyAnalytics])


    const LgpCardsConfig = [
        {
            icon: <FolderIcon fontSize="large" />,
            count: methodologyAnalytics?.unlocked_procedures,
            type: 'pratiche',
            list: [
                { title: t('valutazione'), value: methodologyAnalytics?.confirmed_valutazione },
                {
                    title: t('prevalutazione'),
                    value: methodologyAnalytics?.confirmed_prevalutazione,
                },
            ],
        },
        {
            icon: <ErrorOutlineIcon fontSize="large" />,
            count: methodologyAnalytics?.count_PS,
            type: 'ponti PS',
            list: [
                { title: t('valutazione'), value: methodologyAnalytics?.count_PS_valutazione },
                {
                    title: t('prevalutazione'),
                    value: methodologyAnalytics?.count_PS_prevalutazione,
                },
            ],
        },
        {
            icon: <InsertInvitationIcon fontSize="large" />,
            count: methodologyAnalytics?.count_next_inspections?.[6],
            type: 'Ispezioni nei prossimi 6 mesi',
            buttons: [
                {
                    title: 'Vai al calendario',
                    onClick: () => {
                        history.push('/calendar')
                    },
                },
            ],
        },
    ]

    const downloadFile = () => {
        getLGPReport({
            companyId: company?.id,
            methodology: 'mit_guidelines',
            filters: filters?.rules,
        })
    }
    return (
        <Grid style={{ marginBottom: 20 }} spacing={3}>

            <Grid container item justify="space-between" xs={12} spacing={1} style={{ marginBottom: '20px' }}>
                {LgpCardsConfig.map((card, i) => (
                    <DashboardCard
                        key={i}
                        icon={card.icon}
                        count={card.count}
                        type={card.type}
                        list={card.list}
                        buttons={card.buttons}
                    />
                ))}
            </Grid>
            {/* CDA */}
            <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
                <Grid container direction={"column"} spacing={5} style={{alignItems: "center", marginTop: 20}}>
                    <LGPPieCharts methodologyAnalytics={methodologyAnalytics} isLoading={isMethodologyAnalyticsFetching} />
                </Grid>
            </Grid>
            <Grid container item justify="center">
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={
                            isLgpReportFileFetching ? (
                                <CircularProgress size={25} variant="indeterminate" />
                            ) : undefined
                        }
                        disabled={isLgpReportFileFetching}
                        onClick={() => downloadFile()}
                    >
                        {t('extract')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
