import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
    Edit as EditIcon,
    Help as HelpIcon,
    HourglassEmpty as HourglassEmptyIcon,
    Save as SaveIcon,
} from '@material-ui/icons'
import _ from 'lodash'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import difettiInfo from '../../../assets/difetti_info.json'
import difettiMap from '../../../assets/difetti_map.json'
import { colorsMapping } from '../../../customTheme'
import { difetto_generico, note, scheda_info } from '../../../FormConfigs/mit_la_1_difetto_config'
import { materialsMapping } from '../../../utils'
import AppAccordion from '../../AppAccordion'
import AppForm from '../../AppForm'
import AppTooltip from '../../AppTooltip'
import AppSelect from '../../FormItems/AppSelect'
import DynamicFormFields from '../../FormItems/DynamicFormFields'
import { RequiredLA2Info } from '../../FormItems/RequiredLA2'
import FulfillAbsenceButtons from '../../FulfillAbsenceButtons'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
    tooltipIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: theme.palette.background.paperLight,
        fontSize: 30,
    },
    doneIcon: {
        color: theme.palette.success.main,
    },
    customChip: {
        color: 'white',
        marginRight: '10px',
        borderRadius: '10px',
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'center',
    },
    customChipMobile: {
        fontSize: '11px',
        height: '16px',
        borderRadius: '8px',
        padding: '0 4px',
    },
    customChipRed: {
        backgroundColor: colorsMapping[5],
    },
    customChipGreen: {
        backgroundColor: colorsMapping[2],
    },
    customChipOrange: {
        backgroundColor: colorsMapping[4],
    },
    customChipGray: {
        backgroundColor: theme.palette.text.secondary,
    },
    hide: {
        display: 'none',
    },
    bmsMargin: {
        marginTop: '15px',
    },
}))
const miniGuide = ['difetti_guide_1', 'difetti_guide_2', 'difetti_guide_3', 'difetti_guide_4']

export default function DettaglioSchedaIspezione({
    currentElementoStrutturale,
    livello1,
    formPath,
    formPathForDynamicFormFields,
    isNotAllowedToWrite,
    isInBMSComparison = false,
    handleEditMaterial,
    sendData = (data) => {},
    methods,
    isLoading = false, //
    isUpdateLoading,
}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    const [isEditMaterialOpen, setIsEditMaterialOpen] = React.useState(false)
    const [newMaterial, setNewMaterial] = React.useState('')
    const [difettiList, setDifettiList] = React.useState([])

    const handleCloseEditMaterialDialog = () => {
        setIsEditMaterialOpen(false)
    }

    const handleNewMaterial = ({ target: { value } }) => {
        setNewMaterial(value)
    }

    function configureEstensioneIntensitaFields(model, data) {
        const {
            estensione: estensioneModel,
            intensita: intensitaModel,
            ...otherModelFields
        } = model
        const { estensione: estensioneData, intensita: intensitaData } = data
        let configuredEstensioneButtons, configuredIntensitaButtons
        if (estensioneData[0] === 'tutti') {
            configuredEstensioneButtons = [{ value: 0.2 }, { value: 0.5 }, { value: 1 }]
        } else {
            configuredEstensioneButtons = [{ value: estensioneData[0] }]
        }
        if (intensitaData[0] === 'tutti') {
            configuredIntensitaButtons = [{ value: 0.2 }, { value: 0.5 }, { value: 1 }]
        } else {
            configuredIntensitaButtons = [{ value: intensitaData[0] }]
        }
        return {
            estensione: {
                ...estensioneModel,
                buttons: configuredEstensioneButtons,
            },
            intensita: {
                ...intensitaModel,
                buttons: configuredIntensitaButtons,
            },
            ...otherModelFields,
        }
    }

    const { isDirty, isValid } = methods.formState

    React.useEffect(() => {
        if (!_.isEmpty(livello1)) {
            const material = _.get(livello1, `${formPath}.materiale`)
            if (material) {
                setDifettiList(difettiMap[currentElementoStrutturale][material])
            } else {
                setDifettiList(difettiMap[currentElementoStrutturale])
            }
        }
    }, [livello1, formPath, setDifettiList, currentElementoStrutturale])

    if (isInBMSComparison && !_.get(livello1, formPath)) {
        return (
            <Grid style={{ marginTop: '15px' }}>
                <Typography variant="h5" align="center">
                    Questa scheda non è presente in questa ispezione
                </Typography>
            </Grid>
        )
    }

    return (
        <>
            <Typography
                variant="h4"
                component="h1"
                align="center"
                gutterBottom
                className={isInBMSComparison && classes.bmsMargin}
            >
                {t(currentElementoStrutturale) +
                    (_.get(livello1, `${formPath}.materiale`)
                        ? ' - ' + t(_.get(livello1, `${formPath}.materiale`))
                        : '')}
                {!isInBMSComparison &&
                    !['giunti', 'apparecchi_appoggio'].includes(currentElementoStrutturale) && (
                        <IconButton
                            clickable
                            color={'secondary'}
                            onClick={() => setIsEditMaterialOpen(true)}
                            disabled={isNotAllowedToWrite}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
            </Typography>

            {/* EDIT MATERIAL DIALOG */}
            <Dialog
                open={isEditMaterialOpen}
                onClose={() => handleCloseEditMaterialDialog(setIsEditMaterialOpen)}
            >
                <DialogTitle>{t('edit_material_dialog_title')}</DialogTitle>

                <DialogContent>
                    <Typography gutterBottom>{t('edit_material_dialog_warning')}</Typography>
                    <AppSelect
                        fullWidth
                        required
                        fieldLabel="materiale"
                        optionsArray={materialsMapping[currentElementoStrutturale]}
                        value={newMaterial}
                        onChange={handleNewMaterial}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditMaterialDialog}>{t('annulla')}</Button>

                    <Button
                        onClick={() => handleEditMaterial(newMaterial, setIsEditMaterialOpen)}
                        color="secondary"
                        variant="contained"
                        disabled={!newMaterial}
                    >
                        {t('edit_material_confirmation')}
                    </Button>
                </DialogActions>
            </Dialog>

            {!isInBMSComparison && <RequiredLA2Info />}

            <AppTooltip
                title={
                    <>
                        <Typography variant="body2">
                            {t('PS') + ' - ' + t('pregiudica_statica')}
                        </Typography>
                        <Typography variant="body2">
                            {t('NA') + ' - ' + t('non_applicabile')}
                        </Typography>
                        <Typography variant="body2">
                            {t('NR') + ' - ' + t('non_rilevabile')}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {t('NP') + ' - ' + t('non_presente')}
                        </Typography>

                        <Box mt={2}>
                            <Typography variant="body1" gutterBottom>
                                {t('difetti_guide_title')}
                            </Typography>
                            {miniGuide.map((item, index) => (
                                <Typography variant="body2" key={index} gutterBottom>
                                    - {t(item)}
                                </Typography>
                            ))}
                        </Box>
                    </>
                }
            >
                <HelpIcon className={classes.tooltipIcon} />
            </AppTooltip>

            <AppForm idx="data-form" onSubmit={sendData} methods={methods}>
                <Grid
                    style={{
                        marginRight: isInBMSComparison ? 8 : 'unset',
                        marginLeft: isInBMSComparison ? 8 : 'unset',
                    }}
                >
                    {isInBMSComparison ? (
                        <Grid
                            container
                            xs={12}
                            justify="center"
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                        >
                            <Grid item xs={10}>
                                <DynamicFormFields
                                    fieldsKey={formPathForDynamicFormFields}
                                    formFields={scheda_info}
                                    disableAll={isNotAllowedToWrite}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <DynamicFormFields
                            fieldsKey={formPathForDynamicFormFields}
                            formFields={scheda_info}
                            disableAll={isNotAllowedToWrite}
                        />
                    )}
                </Grid>
                {!isInBMSComparison && (
                    <FulfillAbsenceButtons
                        difettiPossibili={difettiList}
                        formPath={formPath}
                        methods={methods}
                        disableAll={isNotAllowedToWrite}
                    />
                )}
                {difettiList.map((difettoMap) => {
                    const currentDifettoInfo = difettiInfo[difettoMap.id]
                    const { ps, ...fixedFields } = configureEstensioneIntensitaFields(
                        difetto_generico,
                        difettoMap
                    )
                    let relevantFields
                    if (currentDifettoInfo.weight < 4) {
                        relevantFields = fixedFields
                    } else {
                        const { estensione, intensita, motivo_assenza, foto, note } = fixedFields
                        relevantFields = {
                            estensione,
                            intensita,
                            motivo_assenza,
                            ps,
                            foto,
                            note,
                        }
                    }
                    return (
                        <AppAccordion
                            key={currentDifettoInfo.id}
                            accordionLabel={`${currentDifettoInfo.id} : ${currentDifettoInfo.name}`}
                            avatarContent={`G${currentDifettoInfo.weight}`}
                            avatarStyle={{
                                backgroundColor: colorsMapping[currentDifettoInfo.weight],
                                color: theme.palette.background.paper,
                            }}
                            renderHeaderContent={() => {
                                const currentDifettoPath = _.get(livello1, formPath)?.difetti?.[
                                    difettoMap.id
                                ]

                                if (
                                    currentDifettoPath?.estensione ||
                                    currentDifettoPath?.intensita ||
                                    currentDifettoPath?.motivo_assenza
                                ) {
                                    let esaminato_str, status_icon
                                    if (
                                        (currentDifettoPath?.estensione &&
                                            currentDifettoPath?.intensita) ||
                                        currentDifettoPath?.motivo_assenza
                                    ) {
                                        esaminato_str = 'esaminato'
                                    } else {
                                        esaminato_str = 'parzialmente_esaminato'
                                        status_icon = <HourglassEmptyIcon color="secondary" />
                                    }
                                    let faulty_icon = null
                                    if (
                                        currentDifettoPath?.estensione &&
                                        currentDifettoPath?.intensita
                                    ) {
                                        faulty_icon = (
                                            <Box
                                                className={`${classes.customChip} ${
                                                    isMobile && classes.customChipMobile
                                                } ${classes.customChipRed}`}
                                            >
                                                {t('presente')}
                                            </Box>
                                        )
                                    } else if (currentDifettoPath?.motivo_assenza === 'NA') {
                                        faulty_icon = (
                                            <Box
                                                className={`${classes.customChip} ${
                                                    isMobile && classes.customChipMobile
                                                } ${classes.customChipOrange}`}
                                            >
                                                {t('NA')}
                                            </Box>
                                        )
                                    } else if (currentDifettoPath?.motivo_assenza === 'NP') {
                                        faulty_icon = (
                                            <Box
                                                className={`${classes.customChip} ${
                                                    isMobile && classes.customChipMobile
                                                } ${classes.customChipGreen}`}
                                            >
                                                {t('NP')}
                                            </Box>
                                        )
                                    } else if (currentDifettoPath?.motivo_assenza === 'NR') {
                                        faulty_icon = (
                                            <Box
                                                className={`${classes.customChip} ${
                                                    isMobile && classes.customChipMobile
                                                } ${classes.customChipGray}`}
                                            >
                                                {t('NR')}
                                            </Box>
                                        )
                                    }

                                    return (
                                        <>
                                            {faulty_icon}
                                            {esaminato_str === 'parzialmente_esaminato' && (
                                                <AppTooltip
                                                    title={
                                                        <Typography variant="body2">
                                                            {t(esaminato_str)}
                                                        </Typography>
                                                    }
                                                >
                                                    {status_icon}
                                                </AppTooltip>
                                            )}
                                        </>
                                    )
                                }
                            }}
                        >
                            <DynamicFormFields
                                fieldsKey={`${formPathForDynamicFormFields}.difetti.${difettoMap.id}`}
                                formFields={relevantFields}
                                disableAll={isNotAllowedToWrite}
                            />
                        </AppAccordion>
                    )
                })}
                <br />
                <DynamicFormFields
                    fieldsKey={formPathForDynamicFormFields}
                    formFields={note}
                    disableAll={isNotAllowedToWrite}
                />

                {!isInBMSComparison && (
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={
                            !isDirty || // FIXME somehow doesnt work anymore
                            // !_.isEmpty(touched) || // workaround for isDirty
                            !isValid ||
                            isLoading ||
                            isUpdateLoading
                        }
                        className={`${classes.fab} ${isNotAllowedToWrite && classes.hide}`}
                    >
                        {isUpdateLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                )}
            </AppForm>
        </>
    )
}
