import React from 'react'

import AppTooltip from '../../../components/AppTooltip'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { modulesQueries } from '../../../networking'
import { Chip, makeStyles } from '@material-ui/core'
import { Lock as LockIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => {
    return {
        chip: {
            marginRight: theme.spacing(1),
        },
        disabledModuleChip: {
            backgroundColor: theme.palette.background.disabledModulePill,
        },
        chipLabel: {
            lineHeight: 'normal',
        },
        lock: {
            color: '#d3d4d8',
            fontSize: 13,
            marginRight: theme.spacing(1),
        },
    }
})
const EnabledModules = ({ enabledModules }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { data: allModules = [] } = useQuery(
        modulesQueries.getModules.name,
        modulesQueries.getModules.fn
    )
    return (
        allModules.map((module) => {
            const isModuleEnabled = enabledModules.includes(module)
            return (
                <Chip
                    key={module}
                    classes={{
                        root: [
                            classes.chip,
                            isModuleEnabled ? undefined : classes.disabledModuleChip,
                        ].join(' '),
                        label: classes.chipLabel,
                    }}
                    size="small"
                    label={module}
                    color={isModuleEnabled ? 'secondary' : 'primary'}
                    onDelete={isModuleEnabled ? undefined : () => { }}
                    deleteIcon={
                        isModuleEnabled ? undefined : (
                            <AppTooltip title={t('inactive_module')} placement={'top'}>
                                <LockIcon className={classes.lock} fontSize="small" />
                            </AppTooltip>
                        )
                    }
                />
            )
        })
    )
}

export default EnabledModules
