import React from 'react'
import { TextField, Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
    label: {
        backgroundColor: theme.palette.secondary.main,
        padding: '15px 50px 15px 20px ',
        borderRadius: '0px 0px 50px 50px',
    },
    newProcedureButton: {
        boxShadow: 'none',
        padding: '5px 5px',
        borderRadius: '50%',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            right: '7px',
        },
        [theme.breakpoints.up('lg')]: {
            position: 'relative',
            left: '7px',
        },
    },
}))

export default function SearchBar({
    input,
    setInput,
    fullWidth,
    autoFocus,
    onEnterPress,
    endAdornment,
    BIinterface,
    onIconClick = () => null,
    useLabel = false,
    ...props
}) {
    const { t } = useTranslation()
    const classes = useStyles()

    function handleChange(e) {
        setInput(e.target.value)
    }

    function handleKeyUp(e) {
        if (e.key === 'Enter' && typeof onEnterPress === 'function') {
            onEnterPress(e)
        }
    }

    return (
        <Grid container wrap="nowrap" alignItems="center" {...props}>
            <Grid
                container
                className={useLabel ? classes.label : null}
                alignItems="center"
                justify="center"
            >
                {BIinterface && (
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.newProcedureButton}
                            onClick={onIconClick}
                        >
                            <AddIcon />
                        </Button>
                    </Grid>
                )}
                <Grid item xs={BIinterface ? 10 : 12}>
                    <TextField
                        size={useLabel && 'small'}
                        autoFocus={autoFocus}
                        fullWidth={true}
                        variant="outlined"
                        placeholder={t('cerca')}
                        value={input}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        InputProps={{
                            // startAdornment: <SearchIcon style={{ marginRight: 10 }} />,
                            ...(endAdornment ? { endAdornment: endAdornment() } : {}),
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
