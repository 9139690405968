import React from 'react'

export default function ScrollableCarousel({
    renderItem,
    itemProps = [],
    currentIndex,
    scrollTarget = "visible",
    scrollMargin = {left: 0, right: 0},
    onScroll = undefined,
}) {
    const itemsContainerRef = React.useRef(null)
    React.useEffect(() => adjustScrollPosition(itemsContainerRef, currentIndex, scrollTarget, scrollMargin), [currentIndex, scrollTarget, scrollMargin])

    return (
        <div onScroll={onScroll} style={{
            width: 'fit-content',
            maxWidth: '100%',
            height: 'max-content',
            overflow: "auto",
            scrollbarWidth: "none",
            onScroll: onScroll
        }}>
            <div ref={itemsContainerRef} style={{
                width: 'max-content',
                height: 'max-content',

                paddingLeft: scrollMargin.left,
                paddingRight: scrollMargin.right,

                ...(scrollTarget === 'force-center' && getAdjustedContainerPadding(itemsContainerRef)),

                display: "flex",
                position: "relative",

            }}>
                {itemProps.map(renderItem)}
            </div>
        </div>
    )
}

function adjustScrollPosition(itemsContainerRef, currentIndex, scrollTarget, scrollMargin) {
    if (scrollTarget === 'none') {return}

    const itemStart = itemsContainerRef.current?.childNodes[currentIndex]?.offsetLeft
    const itemWidth = itemsContainerRef.current?.childNodes[currentIndex]?.offsetWidth
    const itemEnd = itemStart + itemWidth

    const totalWidth = itemsContainerRef.current?.offsetWidth

    const currentScroll = itemsContainerRef.current?.parentElement.scrollLeft
    const visibleWidth = itemsContainerRef.current?.parentElement?.offsetWidth

    let newScroll
    if (scrollTarget === 'visible') {
        //     [            ] ..... ->      [       .....]
        //     [          ..]..     ->      [       .....]
        //   ..[..          ]       ->      [.....       ]
        //     [  .....     ]       ->      [  .....     ]
        const targetStart = Math.max(itemEnd - visibleWidth + scrollMargin.right, 0)
        const targetEnd  = Math.min(itemStart - scrollMargin.left, totalWidth)
        if (targetStart <= currentScroll && currentScroll <= targetEnd) {return}
        newScroll = currentScroll < targetStart ? targetStart : targetEnd

    } else if (scrollTarget === 'lazy-center') {
        const targetStart = itemStart - visibleWidth / 2
        const targetEnd = itemEnd - visibleWidth / 2
        if (targetStart <= currentScroll && currentScroll <= targetEnd) {return}
        newScroll = currentScroll < targetStart ? targetStart : targetEnd

    } else {
        newScroll = Math.min(Math.max((itemStart + itemEnd - visibleWidth) / 2, 0), totalWidth)
    }

    itemsContainerRef.current.parentElement.scrollTo({
        behavior: "smooth",
        left: newScroll,
    })
}

function getAdjustedContainerPadding(itemsContainerRef) {

    const firstItemWidth = itemsContainerRef.current?.firstChild?.offsetWidth
    const lastItemWidth = itemsContainerRef.current?.lastChild?.offsetWidth
    const containerWidth = itemsContainerRef.current?.parentElement?.offsetWidth

    return {
        paddingLeft: (containerWidth - firstItemWidth) / 2,
        paddingRight: (containerWidth - lastItemWidth) / 2
    }
}
