import React from 'react'
import { Chip } from '@material-ui/core'
import { useProceduresFiltersStore } from '../stateManagement'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
    return {
        chip: {
            marginLeft: 5,
            marginRight: 5,
        },
        activeChip: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:focus': {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        inactiveChip: {
            backgroundColor: theme.palette.background.disabledModulePill,
        },
    }
})


export const QuickFilterChip = ({ label, path, operatore, valore, ruleEditingOptions }) => {
    const {
        isRuleSet,
        toggleRule,
    } = useProceduresFiltersStore(state => state)

    const isActive = isRuleSet(path, operatore, valore, ruleEditingOptions)
    const toggleFilter = () => toggleRule(path, operatore, valore, ruleEditingOptions)

    return(
        <AppChip
            onClick={toggleFilter}
            isActive={isActive}
            label={label}
        />
    )
}

export const AppChip = ({onClick, isActive, label}) => {
    const classes = useStyles()
    return (
        <Chip
            label={label}
            clickable={!!onClick}
            className={`${classes.Chip} ${isActive ? classes.activeChip : classes.inactiveChip}`}
            color={"primary"}
            onClick={onClick}
        />
    )
}