import React from 'react'
import {
    Grid,
    Container,
    Button,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import _ from 'lodash'
import CompanyCard from '../components/Companies/CompanyCard'
import LoadingSpinner from '../components/LoadingSpinner'
import Section from '../components/Section'
import { companiesQueries, queryClient, profileQueries, usersQueries } from '../networking'
import { useSnackBarStore, useUserProfileStore } from '../stateManagement'
import { capitalizeFirstLetter } from '../utils'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    button: {
        alignSelf: 'flex-end',
    },
    list: {
        height: 197,
        overflow: 'auto',
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    root: {
        width: 500,
    },
}))

export default function CompaniesPage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    const { register, handleSubmit, control, formState } = useForm({
        mode: 'onChange',
    })
    const { isValid } = formState

    const onSubmit = (data) => {
        createCompanyMutation.mutate({
            name: `${capitalizeFirstLetter(data.companyName)}`,
            admins: data.admins.map((newCompanyAdmin) => newCompanyAdmin.id),
        })
        setIsDialogOpen(false)
    }

    const { data: users } = useQuery(usersQueries.getUsers.name, () => usersQueries.getUsers.fn(), {
        enabled: Boolean(isSuperAdmin),
        onError: (e) => {
            console.error(e)
        },
        select: (data) => {
            const userHasReportGeneratorRole = (user) => {
                const isRoleReportGenerator = (role) => role.name === 'report_generator'
                if (user.roles.findIndex(isRoleReportGenerator) !== -1) return true
                else return false
            }
            const filteredUsersList = data.filter((user) => !userHasReportGeneratorRole(user))
            return filteredUsersList
        },
    })
    const { data: companies = [], isLoading } = useQuery(
        companiesQueries.getCompanies.name,
        companiesQueries.getCompanies.fn
    )
    const createCompanyMutation = useMutation(companiesQueries.createCompany.fn, {
        onSuccess: () => {
            showSnackBar({ message: t('new_company_created'), severity: 'success' })
            return queryClient.invalidateQueries(companiesQueries.getCompanies.name)
        },
        onError: () => {
            showSnackBar({
                message: t('new_company_creation_failed'),
                severity: 'error',
            })
        },
    })

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <Container maxWidth="md" className={classes.container}>
            <Section xs={12} direction="column" wrap="nowrap" innerSpacing={3}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {t('companies_page.title')}
                </Typography>
                <Grid item className={classes.button}>
                    {isSuperAdmin && (
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={
                                createCompanyMutation.isLoading ? (
                                    <CircularProgress size={18} />
                                ) : (
                                    <AddIcon />
                                )
                            }
                            onClick={() => setIsDialogOpen(true)}
                            disabled={createCompanyMutation.isLoading}
                        >
                            {t('new_company')}
                        </Button>
                    )}
                </Grid>
                {companies?.map((company) => (
                    <CompanyCard key={company.id} company={company} />
                ))}
            </Section>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Crea nuova organizzazione</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={3} direction="column" justify="space-between">
                            <Grid item>
                                <Controller
                                    control={control}
                                    rules={{ required: true, minLength: 3 }}
                                    name="companyName"
                                    render={({ onChange, value, onBlur }) => (
                                        <TextField
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            label="Nome"
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name="admins"
                                    defaultValue={[]}
                                    rules={{
                                        required: true,
                                        validate: { moreThanOne: (value) => value.length > 0 },
                                    }}
                                    render={(
                                        { onChange, value, name, ref, onBlur },
                                        { invalid, isTouched, isDirty }
                                    ) => (
                                        <Autocomplete
                                            className={classes.root}
                                            onChange={(event, value, reason) => onChange(value)}
                                            value={value}
                                            multiple
                                            id="tags-outlined"
                                            options={users}
                                            getOptionLabel={(user) =>
                                                `${user.first_name} ${user.last_name}`
                                            }
                                            renderOption={(user) => (
                                                <ListItemText
                                                    primary={`${user.first_name} ${user.last_name}`}
                                                    secondary={user.email}
                                                />
                                            )}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Seleziona admin"
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)}>{t('annulla')}</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={!Boolean(isValid)}
                        >
                            Conferma
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Container>
    )
}
