import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import _ from 'lodash'
import {
    Grid,
    Typography,
    Button,
    ButtonGroup,
    CircularProgress,
    Tabs,
    Tab,
    Divider,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ClickAwayListener,
    DialogContent,
    DialogActions,
    Dialog,
    Select,
    TextField,
    FormControl,
    InputLabel,
    InputAdornment,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    Save as SaveIcon,
    Edit as EditIcon,
    Done as DoneIcon,
    ArrowRight as ArrowRightIcon,
    Email as EmailIcon,
    Description as DescriptionIcon,
    ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'

import MethodologyPage from '../MethodologyPage'
import CdA_alta from '../../assets/lgp/CdA/CdA-alta.png'
import CdA_medio_alta from '../../assets/lgp/CdA/CdA-medio_alta.png'
import CdA_media from '../../assets/lgp/CdA/CdA-media.png'
import CdA_medio_bassa from '../../assets/lgp/CdA/CdA-medio_bassa.png'
import CdA_bassa from '../../assets/lgp/CdA/CdA-bassa.png'
import CdA_absent from '../../assets/lgp/CdA/CdA-absent.png'
import CdA_locked from '../../assets/lgp/CdA/CdA-locked.png'
import CalculationErrorsDialog from '../../components/CalculationErrorsDialog'
import InputDialog from '../../components/Dialogs/InputDialog'
import DropzoneButton from '../../components/DropzoneButton'
import LoadingSpinner from '../../components/LoadingSpinner'
import MitOverviewTable from '../../components/MitOverviewTable'
import MitRischioIdraulicoTable from '../../components/MitRischioIdraulicoTable'
import SocketIOClient from '../../components/SocketIOClient'
import { backend_url } from '../../constants'
import useDisableForm from '../../customHooks/useDisableForm'
import { colorsMapping } from '../../customTheme'
import { proceduresQueries, snapshotsQueries } from '../../networking'
import { useSnackBarStore, useUserProfileStore } from '../../stateManagement'

const useStyles = makeStyles((theme) => ({
    LGPPaper: {
        padding: theme.spacing(3),
        height: '100%',
        position: 'relative',
        borderTopLeftRadius: '0px',
    },
    imgContainer: {
        marginRight: 14,
        marginLeft: 14,
        height: 200,
        display: 'flex',
        background: '#fff',
        borderRadius: '20px',
    },
    smallButton: {
        maxWidth: 180,
        height: 40,
        lineHeight: 1.2,
    },
    linkButton: {
        textAlign: 'center',
    },
    overlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        background: 'rgb(27 30 35 / 50%)',
    },
    overlayText: {
        color: theme.palette.text.primary,
        fontSize: '1.8rem',
        textShadow: '2px 2px 5px rgb(0 0 0 / 50%), -2px -2px 5px rgb(0 0 0 / 50%)',
    },
    CdATitle: {
        fontSize: '1.3rem',
    },
    CdAValue: {
        fontSize: '1.1rem',
        padding: '2px 5px',
        borderRadius: theme.shape.borderRadius,
        textShadow: '0px 0px 4px rgb(0 0 0 / 20%)',
        color: theme.palette.common.white,
    },
    errorBtn: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.dark,
        },
        margin: 'auto',
        padding: '6px 12px',
        width: '50%',
        marginTop: 8,
    },
    errorBtnHovered: {},
    versionLink: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
        },
        '&hover': {},
    },
    createdAtText: {
        color: theme.palette.text.secondary,
        marginRight: '5px',
    },
    tabs: { backgroundColor: theme.palette.background.paper },
    icon: { fontSize: 20 },
    permissionInfo: {
        marginBottom: '20px',
    },
    uploadSubButton: {
        backgroundColor: theme.palette.secondary.main,
    },
    alert: {
        color: 'rgb(102, 60, 0)',
        backgroundColor: 'rgb(255, 244, 229)',
        justifyContent: 'center',
    },
    hide: {
        display: 'none',
    },
}))

const CdAImgMap = {
    ALTA: CdA_alta,
    MEDIOALTA: CdA_medio_alta,
    MEDIA: CdA_media,
    MEDIOBASSA: CdA_medio_bassa,
    BASSA: CdA_bassa,
}
const classeToNumber = {
    null: 0,
    'N/A': 0,
    BASSA: 1,
    MEDIOBASSA: 2,
    MEDIA: 3,
    MEDIOALTA: 4,
    ALTA: 5,
}
const sendReportOptions = ['send_report', 'send_signed_report']
const tabs = Object.freeze({
    PREVALUTAZIONE: 'PREVALUTAZIONE',
    VALUTAZIONE: 'VALUTAZIONE',
})
const dataPaths = Object.freeze({
    IN_PROGRESS: 'IN_PROGRESS',
    CONFIRMED: 'CONFIRMED',
})

export default function LGP_LA2() {
    // Al mount: prendo l'ultimo snapshot e se il report esiste già metto l'url nello state --> disponibile per download e email.
    // Click su "ottieni_relazione": controllo se ho già l'url. Se sì, download. Altrimenti genero relazione, refetch snapshot, metto l'url nello state e download.
    // Click su "conferma_dati": creo nuovo snapshot e resetto l'url che era nello state.
    const classes = useStyles()
    const { t } = useTranslation()
    const { procedureId } = useParams()
    const showSnackBar = useSnackBarStore((state) => state.show)

    const [currentTab, setCurrentTab] = React.useState(tabs.PREVALUTAZIONE)
    const [currentDataPath, setCurrentDataPath] = React.useState({
        inUse: dataPaths.CONFIRMED,
        result: 'latest_result',
        extra: 'latest_result_extra_info',
    })
    const [isSendReportSelectionOpen, setIsSendReportSelectionOpen] = React.useState(false)
    const [generationProgress, setGenerationProgress] = React.useState(null)
    const [isErrorsDialogOpen, setIsErrorsDialogOpen] = React.useState(false)
    const [reportUrl, setReportUrl] = React.useState()
    const [signedReport, setSignedReport] = React.useState()
    const [hasUserRequestedDownload, setHasUserRequestedDownload] = React.useState(false)
    const [loadSocketIO, setLoadSocketIO] = React.useState(false)
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false)
    const [dialogNotes, setDialogNotes] = React.useState(null)
    // quando è 0 è "Invia valutazione", 1 è "Invia valutazione firmata"
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [isBmsEnabled, setIsBmsEnabled] = React.useState(false)
    const [mailHref, setMailHref] = React.useState('')
    const [isInspectionDialogOpen, setIsInspectionDialogOpen] = React.useState(false)
    const [selectedTypology, setSelectedTypology] = React.useState(null)
    //default inspection delay
    const [inspectionDelay, setInspectionDelay] = React.useState(null)
    //inspection delay selected by the user
    const [userSelectedInspectionDelay, setUserSelectedInspectionDelay] = React.useState(null)
    const sendReportOptionsBtnGroupRef = React.useRef(null)
    const [invalidInspectionDelay, setInvalidInspectionDelay] = React.useState(false)
    const [isInspectionExtraordinary, setIsInspectionExtraordinary] = React.useState(false)

    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)

    const handleChange = (event) => {
        setIsInspectionExtraordinary(event.target.checked)
    }

    const {
        data: procedure = {},
        isLoading: isProcedureLoading,
        refetch: refetchProcedure,
    } = useQuery(
        [proceduresQueries.getProcedure.name, procedureId],
        () => proceduresQueries.getProcedure.fn(procedureId),
        {
            onSuccess: (data) => {
                const mit_guidelines = data.methodologies.mit_guidelines
                if (_.isEmpty(_.get(mit_guidelines, currentDataPath.result))) {
                    // default is usually "confirmed" data, but if it's empty...
                    switchToWorkInProgressData()
                }
            },
        }
    )

    const saveSignedDocument = useMutation(snapshotsQueries.saveSignedDocument.fn, {
        onError: () =>
            showSnackBar({
                message: t('generic_error'),
                severity: 'error',
            }),
        onSuccess: () => {
            refetchLastSnapshot()
        },
    })

    const deleteSignedDocument = useMutation(snapshotsQueries.deleteSignedDocument.fn, {
        onError: () =>
            showSnackBar({
                message: t('generic_error'),
                severity: 'error',
            }),
        onSuccess: () => {
            refetchLastSnapshot()
        },
    })

    const handleSaveSignedDocument = (snapshotId, file) => {
        saveSignedDocument.mutate({ snapshotId, file })
    }

    const handleDeleteSignedDocument = (snapshotId) => {
        deleteSignedDocument.mutate({ snapshotId })
    }

    const { isNotAllowedToWrite, alert } = useDisableForm(procedure)

    const mit_guidelines = procedure?.methodologies?.mit_guidelines
    const result = _.get(mit_guidelines, currentDataPath.result)
    const result_extra = _.get(mit_guidelines, currentDataPath.extra, {})
    const isMethodologyUnlocked = !!mit_guidelines?.is_unlocked

    const { data: lastSnapshot, refetch: refetchLastSnapshot } = useQuery(
        [snapshotsQueries.getLastSnapshot.name, procedureId, currentTab === tabs.PREVALUTAZIONE],
        () => snapshotsQueries.getLastSnapshot.fn(procedureId, 'mit_guidelines', currentTab),
        {
            onSuccess: (data) => {
                if (data?.document) {
                    if (hasUserRequestedDownload) {
                        downloadReport(data.document.depot_url, data.document.name)
                    }
                }
                setReportUrl(data.document?.depot_url)
                setSignedReport(data.signed_document)
            },
            onSettled: () => setHasUserRequestedDownload(false),
        }
    )

    const createSnapshotMutation = useMutation(
        () => {
            const additionalData =
                currentTab === tabs.PREVALUTAZIONE
                    ? { interventi_manutenzione_consigliati: dialogNotes }
                    : { eventuali_note: dialogNotes }
            return snapshotsQueries.createSnapshot.fn({
                procedureId: procedureId,
                procedure_owner: procedure.owner.id,
                methodology: 'mit_guidelines',
                ...additionalData,
                type: currentTab,
            })
        },
        {
            onError: () =>
                showSnackBar({
                    message: t('generic_error'),
                    severity: 'error',
                }),
            onSuccess: () => {
                setReportUrl(null)
                refetchProcedure()
                refetchLastSnapshot()
            },
        }
    )

    const { data: enabledModules } = useQuery(
        [proceduresQueries.getProcedureOwnerEnabledModules.name, procedureId],
        () => proceduresQueries.getProcedureOwnerEnabledModules.fn({ id: procedureId }),
        {
            onSuccess: (data) => {
                if (data.data.includes('BMS')) {
                    setIsBmsEnabled(true)
                } else if (!data.data.includes('BMS')) {
                    setIsBmsEnabled(false)
                }
            },
        }
    )

    const { data: inspectionDelays } = useQuery(
        snapshotsQueries.getInspectionDelays.name,
        snapshotsQueries.getInspectionDelays.fn
    )

    const markAsInspection = useMutation(snapshotsQueries.markAsInspection.fn, {
        onSuccess: () => {
            refetchLastSnapshot()
        },
    })

    const handleMarkAsInspection = (delay) => {
        markAsInspection.mutate({
            snapshotId: lastSnapshot.id,
            monthsDelay: delay,
            bridgeType: selectedTypology,
            notes: textFieldValue,
            isExtraordinary: isInspectionExtraordinary,
        })
        setSelectedTypology(null)
        setIsInspectionDialogOpen(false)
    }
    const switchToWorkInProgressData = () =>
        setCurrentDataPath({
            inUse: dataPaths.IN_PROGRESS,
            result:
                currentTab === tabs.PREVALUTAZIONE
                    ? 'workspace.prevalutazione_result'
                    : 'workspace.result',
            extra:
                currentTab === tabs.PREVALUTAZIONE
                    ? 'workspace.prevalutazione_result_extra_info'
                    : 'workspace.result_extra_info',
        })

    const switchToConfirmedData = () =>
        setCurrentDataPath({
            inUse: dataPaths.CONFIRMED,
            result:
                currentTab === tabs.PREVALUTAZIONE
                    ? 'latest_prevalutazione_result'
                    : 'latest_result',
            extra:
                currentTab === tabs.PREVALUTAZIONE
                    ? 'latest_prevalutazione_result_extra_info'
                    : 'latest_result_extra_info',
        })

    const last_modified_at = _.get(mit_guidelines, 'last_modified_at', '')
    const last_snapshot_date = _.get(lastSnapshot, 'created_at', null)

    let isChangedSinceLastConfirm = false

    if (!last_snapshot_date || dayjs(last_modified_at).isAfter(dayjs(last_snapshot_date || ''))) {
        isChangedSinceLastConfirm = true
    }

    React.useEffect(() => {
        if (isChangedSinceLastConfirm) {
            switchToWorkInProgressData()
        } else {
            switchToConfirmedData()
        }
    }, [isChangedSinceLastConfirm])

    React.useEffect(() => {
        if (currentDataPath.inUse === dataPaths.IN_PROGRESS) {
            switchToWorkInProgressData()
        } else {
            switchToConfirmedData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab])

    React.useEffect(() => {
        let path
        if (currentTab === tabs.PREVALUTAZIONE) {
            path = reportUrl
        } else {
            if (selectedIndex === 0) {
                path = reportUrl
            } else {
                path = signedReport.depot_url
            }
        }

        const subject = encodeURI('Relazione pratica - ' + procedure?.name)
        let bodyText
        if (currentTab === tabs.PREVALUTAZIONE) {
            bodyText = 'Questo è il link per scaricare la relazione della prevalutazione:'
        } else {
            if (selectedIndex === 0) {
                bodyText = 'Questo è il link per scaricare la relazione:'
            } else {
                bodyText = 'Questo è il link per scaricare la relazione firmata:'
            }
        }
        bodyText = encodeURI(bodyText) + '%0A'
        const reportLink = backend_url.split('/api/v1')[0] + path

        setMailHref(`mailto:?subject=${subject}&body=${bodyText}${reportLink}`)
    }, [selectedIndex, reportUrl, signedReport, procedure?.name])

    function handleConfirmData() {
        createSnapshotMutation.mutate()
        switchToConfirmedData()
    }

    function downloadReport(url, fileName) {
        if (url) {
            FileSaver.saveAs(String(new URL(url, backend_url)), fileName)
        } else {
            setHasUserRequestedDownload(true)
            setLoadSocketIO(true)
        }
    }

    function handleDocumentGenerationStatusChange(status) {
        if (!!status.error_message) {
            setLoadSocketIO(false)
            setGenerationProgress(null)
        } else if (status.current_step === status.total_steps) {
            setLoadSocketIO(false)
            setGenerationProgress(null)
            refetchLastSnapshot()
        } else {
            setGenerationProgress((status?.current_step / status?.total_steps) * 100)
        }
    }

    function handleTabChange(event, value) {
        setCurrentTab(value)
    }

    function handleSendReportSelectionMenuItemClick(event, index) {
        setSelectedIndex(index)
        setIsSendReportSelectionOpen(false)
    }

    function handleToggleSendReportSelection() {
        setIsSendReportSelectionOpen((prevOpen) => !prevOpen)
    }

    function handleSendReportSelectionClose(event) {
        if (
            sendReportOptionsBtnGroupRef.current &&
            sendReportOptionsBtnGroupRef.current.contains(event.target)
        ) {
            return
        }

        setIsSendReportSelectionOpen(false)
    }

    const handleSelectedTypology = (event) => {
        setSelectedTypology(event.target.value)
    }

    React.useEffect(() => {
        setUserSelectedInspectionDelay(inspectionDelay)
    }, [inspectionDelay])

    React.useEffect(() => {
        if (isInspectionDialogOpen === false) {
            setSelectedTypology(null)
        }
    }, [isInspectionDialogOpen])

    React.useEffect(() => {
        if (lastSnapshot && inspectionDelays && selectedTypology) {
            switch (lastSnapshot.data.result) {
                case 'BASSA':
                    setInspectionDelay(inspectionDelays[selectedTypology].Cda_BASSA)
                    break
                case 'MEDIOBASSA':
                    setInspectionDelay(inspectionDelays[selectedTypology].Cda_MEDIOBASSA)
                    break
                case 'MEDIA':
                    setInspectionDelay(inspectionDelays[selectedTypology].Cda_MEDIA)
                    break
                case 'MEDIOALTA':
                    setInspectionDelay(inspectionDelays[selectedTypology].Cda_MEDIOALTA)
                    break
                case 'ALTA':
                    setInspectionDelay(inspectionDelays[selectedTypology].Cda_ALTA)
                    break
                default:
                    break
            }
        }
    }, [selectedTypology, lastSnapshot])

    const handleUserSelectedDelayChange = (e) => {
        setUserSelectedInspectionDelay(e.target.value)
        if (e.target.value.includes('-')) {
            setInvalidInspectionDelay(true)
        } else {
            setInvalidInspectionDelay(false)
        }
    }
    const invalidInspectionMessage = invalidInspectionDelay ? 'non valido' : null
    const [textFieldValue, setTextFieldValue] = React.useState('')
    const handletextFieldValue = (e) => {
        setTextFieldValue(e.target.value)
    }
    return isProcedureLoading ? (
        <LoadingSpinner />
    ) : (
        <MethodologyPage methodology="lgp" alert={alert}>
            {loadSocketIO && (
                <SocketIOClient
                    procedureId={procedureId}
                    methodology="mit_guidelines"
                    updateStatus={handleDocumentGenerationStatusChange}
                    type={currentTab}
                    withIntro={
                        lastSnapshot.company_reference === '60b8ff6acc5d9bed73a2bc3c' ||
                        lastSnapshot.company_snapshot.name === 'Enel Green Power Italia'
                    }
                />
            )}
            <Tabs value={currentTab} onChange={handleTabChange}>
                <Tab
                    value={tabs.PREVALUTAZIONE}
                    label={t('prevalutazione')}
                    className={classes.tabs}
                />
                <Tab value={tabs.VALUTAZIONE} label={t('valutazione')} className={classes.tabs} />
            </Tabs>

            {/* <Section xs={12} direction="column" style={{ position: 'relative', borderTopLeftRadius: '0px' }}> */}
            <Paper className={classes.LGPPaper}>
                <Grid container>
                    {!isMethodologyUnlocked && (
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            className={classes.overlay}
                        >
                            <Typography variant="h6" align="center" className={classes.overlayText}>
                                {t('pratica_sblocca_credito')}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item container direction="column" justify="space-around" spacing={3}>
                        {/* CdA */}
                        <Grid item>
                            <Typography variant="h4" component="h1" align="center">
                                {t('LA2_page.title')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {isChangedSinceLastConfirm &&
                                currentDataPath.inUse === dataPaths.CONFIRMED && (
                                    <Alert className={classes.alert} severity="warning">
                                        {t('unconfirmed_data')}
                                    </Alert>
                                )}
                        </Grid>
                        {/* toggle buttons */}
                        <Grid item container alignItems="center" justify="space-evenly" spacing={1}>
                            <Grid item>
                                <Typography variant="h6" align="center">
                                    {t('methodology_data_using_which_data_label')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ButtonGroup disableElevation variant="contained">
                                    <Button
                                        color={
                                            currentDataPath.inUse === dataPaths.IN_PROGRESS &&
                                            'secondary'
                                        }
                                        className={classes.smallButton}
                                        startIcon={<EditIcon />}
                                        disabled={!isMethodologyUnlocked}
                                        onClick={switchToWorkInProgressData}
                                    >
                                        {t('use_work_in_progress_methodology_data')}
                                    </Button>
                                    <Button
                                        color={
                                            currentDataPath.inUse === dataPaths.CONFIRMED &&
                                            'secondary'
                                        }
                                        className={classes.smallButton}
                                        startIcon={<SaveIcon />}
                                        disabled={!isMethodologyUnlocked}
                                        onClick={switchToConfirmedData}
                                    >
                                        {t('use_latest_confirmed_methodology_data')}
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid
                                container
                                justify="center"
                                style={{ marginBottom: 10 }}
                                spacing={1}
                            >
                                <Grid item>
                                    <Typography component="h4" className={classes.CdATitle}>
                                        {t('classe_attenzione')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component="h4"
                                        className={classes.CdAValue}
                                        style={{
                                            background: colorsMapping[classeToNumber[result]],
                                        }}
                                    >
                                        {!result || result === 'N/A' ? t('N/D') : result}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div className={classes.imgContainer}>
                                <div
                                    style={{
                                        background: `#fff url(${
                                            !isMethodologyUnlocked
                                                ? CdA_locked
                                                : CdAImgMap[result] || CdA_absent
                                        }) center/contain no-repeat`,
                                        margin: 20,
                                        flex: 1,
                                    }}
                                />
                            </div>
                            {(currentTab === tabs.VALUTAZIONE
                                ? mit_guidelines?.workspace?.errors?.length
                                : mit_guidelines?.workspace?.prevalutazione_errors?.length) > 0 &&
                                currentDataPath.inUse === dataPaths.IN_PROGRESS && (
                                    <Button
                                        onClick={() => setIsErrorsDialogOpen(true)}
                                        variant={'contained'}
                                        color={'primary'}
                                        classes={{
                                            root: classes.errorBtn,
                                            hover: classes.errorBtnHovered,
                                        }}
                                    >
                                        <Typography variant={'caption'}>
                                            {t('error_calculating_cda_btn_label')}
                                        </Typography>
                                        <ArrowRightIcon />
                                    </Button>
                                )}
                            <CalculationErrorsDialog
                                open={isErrorsDialogOpen}
                                setIsOpen={setIsErrorsDialogOpen}
                                errors={_.get(
                                    mit_guidelines,
                                    currentTab === tabs.VALUTAZIONE
                                        ? 'workspace.errors'
                                        : 'workspace.prevalutazione_errors'
                                )}
                            />
                        </Grid>
                        {/* tables */}
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <MitOverviewTable
                                    statusTrace={result_extra}
                                    isPrevalutazione={currentTab === tabs.PREVALUTAZIONE}
                                />
                            </Grid>
                            {currentTab === tabs.VALUTAZIONE && (
                                <Grid item>
                                    <MitRischioIdraulicoTable
                                        rischioIdraulico={result_extra?.rischio_idraulico}
                                        isPrevalutazione={currentTab === tabs.PREVALUTAZIONE}
                                    />
                                </Grid>
                            )}
                            <Grid item style={{ alignSelf: 'end' }}>
                                {result_extra?.version && (
                                    <Link to="/changelog">
                                        <Typography
                                            variant="caption"
                                            classes={{
                                                root: classes.versionLink,
                                            }}
                                        >
                                            v{result_extra.version}
                                        </Typography>
                                    </Link>
                                )}
                            </Grid>
                            {/* notes */}
                            <Grid item>
                                {currentDataPath.inUse === dataPaths.CONFIRMED && (
                                    <>
                                        {currentTab === tabs.PREVALUTAZIONE && (
                                            <Grid container spacing={1} justify="space-between">
                                                <Grid item xs={12} md={3}>
                                                    <Typography
                                                        style={{ fontWeight: 'bolder' }}
                                                        color="textSecondary"
                                                    >
                                                        {t('info')}
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={12} md={8}>
                                                    {lastSnapshot ? (
                                                        <Typography>
                                                            {dayjs(last_snapshot_date).format(
                                                                'DD/MM/YYYY HH:mm'
                                                            )}
                                                            <br />
                                                            {`${lastSnapshot.author_snapshot?.first_name} ${lastSnapshot.author_snapshot?.last_name}`}
                                                        </Typography>
                                                    ) : (
                                                        <Typography>{t('N/D')}</Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Typography
                                                        style={{ fontWeight: 'bolder' }}
                                                        color="textSecondary"
                                                    >
                                                        {t('interventi_manutenzione_consigliati')}
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem xs={1} />
                                                <Grid item xs={12} md={8}>
                                                    <Typography
                                                        style={{
                                                            whiteSpace: 'pre-wrap',
                                                            overflowWrap: 'break-word',
                                                        }}
                                                    >
                                                        {lastSnapshot?.data
                                                            .interventi_manutenzione_consigliati ||
                                                            t('N/D')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {currentTab === tabs.VALUTAZIONE && (
                                            <Grid container spacing={3} justify="space-around">
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: 'bolder' }}>
                                                        {t('valutazione')}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} md={2}>
                                                    <Typography
                                                        style={{ fontWeight: 'bolder' }}
                                                        color="textSecondary"
                                                        align="center"
                                                    >
                                                        {t('info')}
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={12} md={9}>
                                                    {lastSnapshot ? (
                                                        <Typography>
                                                            {dayjs(last_snapshot_date).format(
                                                                'DD/MM/YYYY HH:mm'
                                                            )}
                                                            <br />
                                                            {`${lastSnapshot.author_snapshot?.first_name} ${lastSnapshot.author_snapshot?.last_name}`}
                                                        </Typography>
                                                    ) : (
                                                        <Typography>{t('N/D')}</Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <Typography
                                                        style={{ fontWeight: 'bolder' }}
                                                        color="textSecondary"
                                                        align="center"
                                                    >
                                                        {t('note')}
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={12} md={9}>
                                                    <Typography
                                                        style={{
                                                            overflowWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {lastSnapshot?.data.eventuali_note ||
                                                            t('N/D')}
                                                    </Typography>
                                                </Grid>
                                                {lastSnapshot?.inspection_date && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{ fontWeight: 'bolder' }}
                                                            >
                                                                {t('inspection')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            <Typography
                                                                style={{ fontWeight: 'bolder' }}
                                                                color="textSecondary"
                                                                align="center"
                                                            >
                                                                {t('info')}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs={12} md={9}>
                                                            <Typography>
                                                                {dayjs(
                                                                    lastSnapshot.inspection_date
                                                                ).format('DD/MM/YYYY HH:mm')}
                                                            </Typography>
                                                            {lastSnapshot?.author_inspection ? (
                                                                <Typography>
                                                                    {`${lastSnapshot.author_inspection?.first_name} ${lastSnapshot.author_inspection?.last_name}`}
                                                                </Typography>
                                                            ) : (
                                                                <Typography>{t('N/D')}</Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            <Typography
                                                                style={{ fontWeight: 'bolder' }}
                                                                color="textSecondary"
                                                                align="center"
                                                            >
                                                                {t('note')}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Grid item xs={12} md={9}>
                                                            <Typography
                                                                style={{
                                                                    wordBreak: 'break-word',
                                                                    whiteSpace: 'pre-wrap',
                                                                }}
                                                            >
                                                                {lastSnapshot?.inspection_notes ||
                                                                    t('N/D')}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* buttons */}
                    <Grid
                        item
                        container
                        style={{
                            width: '70%',
                            margin: '20px auto 10px',
                        }}
                        justify={'space-evenly'}
                        spacing={3}
                    >
                        {currentDataPath.inUse === dataPaths.IN_PROGRESS && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className={classes.smallButton}
                                    startIcon={
                                        createSnapshotMutation.isLoading ? (
                                            <CircularProgress size={20} />
                                        ) : isChangedSinceLastConfirm ? (
                                            <SaveIcon />
                                        ) : (
                                            <DoneIcon />
                                        )
                                    }
                                    disabled={
                                        ((currentTab === tabs.VALUTAZIONE
                                            ? mit_guidelines?.workspace?.errors?.length
                                            : mit_guidelines?.workspace?.prevalutazione_errors
                                                  ?.length) &&
                                            currentDataPath.inUse === dataPaths.IN_PROGRESS) ||
                                        isNotAllowedToWrite ||
                                        currentDataPath.inUse === dataPaths.CONFIRMED ||
                                        createSnapshotMutation.isLoading ||
                                        !isMethodologyUnlocked ||
                                        !isChangedSinceLastConfirm ||
                                        !_.get(
                                            mit_guidelines,
                                            currentTab === tabs.PREVALUTAZIONE
                                                ? 'workspace.prevalutazione_result'
                                                : 'workspace.result'
                                        )
                                    }
                                    onClick={() => setIsConfirmationDialogOpen(true)}
                                >
                                    {t('conferma_dati')}
                                </Button>
                            </Grid>
                        )}

                        {currentDataPath.inUse !== dataPaths.IN_PROGRESS && (
                            <>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.smallButton}
                                        startIcon={
                                            generationProgress ? (
                                                <CircularProgress
                                                    size={25}
                                                    variant="determinate"
                                                    value={generationProgress}
                                                />
                                            ) : (
                                                <DescriptionIcon />
                                            )
                                        }
                                        disabled={
                                            (isNotAllowedToWrite && !lastSnapshot?.document) ||
                                            currentDataPath.inUse === dataPaths.IN_PROGRESS ||
                                            !last_snapshot_date ||
                                            !isMethodologyUnlocked
                                        }
                                        onClick={() =>
                                            downloadReport(reportUrl, lastSnapshot?.document?.name)
                                        }
                                    >
                                        {t(
                                            currentTab === tabs.PREVALUTAZIONE
                                                ? 'ottieni_prevalutazione'
                                                : 'ottieni_relazione'
                                        )}
                                    </Button>
                                </Grid>
                                {currentTab === tabs.VALUTAZIONE && isBmsEnabled && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={`${classes.smallButton} ${classes.linkButton}`}
                                            disabled={
                                                !lastSnapshot || lastSnapshot?.inspection_date
                                            }
                                            onClick={() => {
                                                setIsInspectionDialogOpen(true)
                                            }}
                                        >
                                            {t('end_inspection')}
                                        </Button>
                                    </Grid>
                                )}
                                {currentTab === tabs.VALUTAZIONE && (
                                    <Grid item>
                                        <DropzoneButton
                                            useDeleteIcon={isSuperAdmin}
                                            value={signedReport}
                                            text={t('upload_signed_report')}
                                            label={t('download_signed_report')}
                                            disabled={!reportUrl}
                                            usePill={false}
                                            handleChange={(file) =>
                                                handleSaveSignedDocument(lastSnapshot.id, file)
                                            }
                                            handleDelete={() =>
                                                handleDeleteSignedDocument(lastSnapshot.id)
                                            }
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                        {currentTab === tabs.PREVALUTAZIONE &&
                            currentDataPath.inUse === dataPaths.CONFIRMED && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={`${classes.smallButton} ${classes.linkButton}`}
                                        startIcon={<EmailIcon />}
                                        href={mailHref}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        disabled={
                                            currentDataPath.inUse === dataPaths.IN_PROGRESS ||
                                            !last_snapshot_date ||
                                            !isMethodologyUnlocked ||
                                            !reportUrl
                                        }
                                    >
                                        {t('invia_prevalutazione')}
                                    </Button>
                                </Grid>
                            )}
                        {currentTab === tabs.VALUTAZIONE &&
                            currentDataPath.inUse === dataPaths.CONFIRMED && (
                                <Grid item>
                                    <ButtonGroup
                                        variant="contained"
                                        color="secondary"
                                        ref={sendReportOptionsBtnGroupRef}
                                        aria-label="split button"
                                        disabled={!signedReport && !reportUrl}
                                    >
                                        <Button startIcon={<EmailIcon />} href={mailHref}>
                                            {t(sendReportOptions[selectedIndex])}
                                        </Button>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            aria-controls={
                                                isSendReportSelectionOpen
                                                    ? 'split-button-menu'
                                                    : undefined
                                            }
                                            aria-expanded={
                                                isSendReportSelectionOpen ? 'true' : undefined
                                            }
                                            aria-label="select merge strategy"
                                            aria-haspopup="menu"
                                            onClick={handleToggleSendReportSelection}
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper
                                        open={isSendReportSelectionOpen}
                                        anchorEl={sendReportOptionsBtnGroupRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === 'bottom'
                                                            ? 'center top'
                                                            : 'center bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener
                                                        onClickAway={handleSendReportSelectionClose}
                                                    >
                                                        <MenuList id="split-button-menu">
                                                            {sendReportOptions.map(
                                                                (option, index) => (
                                                                    <MenuItem
                                                                        key={option}
                                                                        disabled={
                                                                            (option ===
                                                                                'send_signed_report' &&
                                                                                !signedReport) ||
                                                                            (option ===
                                                                                'send_report' &&
                                                                                !reportUrl)
                                                                        }
                                                                        selected={
                                                                            index === selectedIndex
                                                                        }
                                                                        onClick={(event) =>
                                                                            handleSendReportSelectionMenuItemClick(
                                                                                event,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {t(option)}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Grid>
                            )}
                        <InputDialog
                            fullwidth
                            open={isConfirmationDialogOpen}
                            setIsOpen={setIsConfirmationDialogOpen}
                            multiline
                            value={dialogNotes}
                            setValue={setDialogNotes}
                            onSubmitCb={handleConfirmData}
                            required={false}
                            inputLabel={
                                currentTab === tabs.PREVALUTAZIONE
                                    ? t('interventi_manutenzione_consigliati')
                                    : t('eventuali_note')
                            }
                            title={t('snapshot_dialog_title')}
                            button={t('conferma')}
                        />
                        <Dialog
                            open={isInspectionDialogOpen}
                            onClose={() => {
                                setIsInspectionDialogOpen(false)
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                        >
                            <Grid container alignItems="center" direction="column">
                                <DialogContent>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        gutterBottom
                                        style={{ marginBottom: '20px' }}
                                    >
                                        {t('select_type_of_work')}
                                    </Typography>
                                    <Grid
                                        container
                                        justify="center"
                                        direction="row"
                                        style={{ marginBottom: '30px' }}
                                    >
                                        <FormControl variant="outlined">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {t('type_of_work')}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={selectedTypology}
                                                onChange={handleSelectedTypology}
                                                label="Tipologia opera"
                                                style={{ minWidth: '200px' }}
                                            >
                                                <MenuItem value={'PRIMO_TIPO'}>1</MenuItem>
                                                <MenuItem value={'SECONDO_TIPO'}>2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {selectedTypology && (
                                        <Grid
                                            container
                                            direction="column"
                                            style={{ marginBottom: '30px' }}
                                        >
                                            <Grid item>
                                                {lastSnapshot?.data.result === 'MEDIOALTA' ||
                                                lastSnapshot?.data.result === 'ALTA' ? (
                                                    <>
                                                        <Grid item style={{ marginBottom: '20px' }}>
                                                            <Typography variant="h6" align="center">
                                                                {t('schedule_next_inspection')}
                                                            </Typography>
                                                            <Alert
                                                                severity="info"
                                                                style={{
                                                                    backgroundColor: 'transparent',
                                                                    paddingTop: 0,
                                                                }}
                                                            >
                                                                {t(
                                                                    'scheduling_next_inspection_recommended',
                                                                    {
                                                                        months_delay:
                                                                            inspectionDelay,
                                                                    }
                                                                )}
                                                            </Alert>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="center"
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        lineHeight: '3.5',
                                                                        marginBottom: '30px',
                                                                    }}
                                                                >
                                                                    {t('next_inspection')}
                                                                </Typography>
                                                                <TextField
                                                                    type="number"
                                                                    variant="outlined"
                                                                    style={{
                                                                        maxWidth: '100px',
                                                                        marginRight: '10px',
                                                                        marginLeft: '10px',
                                                                    }}
                                                                    InputProps={{
                                                                        inputProps: { min: 1 },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {t('months')}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    value={
                                                                        userSelectedInspectionDelay
                                                                    }
                                                                    error={invalidInspectionDelay}
                                                                    helperText={
                                                                        invalidInspectionMessage
                                                                    }
                                                                    onChange={
                                                                        handleUserSelectedDelayChange
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <Alert
                                                        severity="info"
                                                        style={{
                                                            backgroundColor: 'transparent',
                                                            paddingTop: 0,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            style={{ lineHeight: 1 }}
                                                        >
                                                            {t('next_inspection_month', {
                                                                months_delay: inspectionDelay,
                                                            })}
                                                        </Typography>
                                                    </Alert>
                                                )}
                                                <Grid
                                                    container
                                                    justify="center"
                                                    style={{ marginBottom: '20px' }}
                                                >
                                                    <FormControl component="fieldset">
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                value={isInspectionExtraordinary}
                                                                control={
                                                                    <Checkbox
                                                                        onChange={handleChange}
                                                                    />
                                                                }
                                                                label="Ispezione straordinaria"
                                                                labelPlacement="right"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        style={{
                                                            width: '100%',
                                                            marginRight: '10px',
                                                            marginLeft: '10px',
                                                        }}
                                                        value={textFieldValue}
                                                        label="Note"
                                                        type="text"
                                                        multiline
                                                        rows={6}
                                                        rowsMax={6}
                                                        required={
                                                            userSelectedInspectionDelay !=
                                                            inspectionDelay
                                                        }
                                                        variant="outlined"
                                                        onChange={handletextFieldValue}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </DialogContent>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    setIsInspectionDialogOpen(false)
                                                }}
                                                color="secondary"
                                                variant="contained"
                                            >
                                                {t('annulla')}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    handleMarkAsInspection(
                                                        userSelectedInspectionDelay !=
                                                            inspectionDelay
                                                            ? userSelectedInspectionDelay
                                                            : inspectionDelay
                                                    )
                                                }}
                                                color="secondary"
                                                variant="contained"
                                                autoFocus
                                                disabled={
                                                    !inspectionDelay ||
                                                    invalidInspectionDelay ||
                                                    (textFieldValue == '' &&
                                                        userSelectedInspectionDelay !=
                                                            inspectionDelay)
                                                }
                                            >
                                                {t('conferma')}
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Dialog>
                    </Grid>
                </Grid>
            </Paper>
        </MethodologyPage>
    )
}
