import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function InputDialog({
    open,
    setIsOpen,
    title,
    button,
    inputType,
    multiline = false,
    inputLabel,
    value,
    setValue,
    onSubmitCb,
    required = true,
    fullwidth = false,
    isLoading = false,
}) {
    const { t } = useTranslation()

    function handleChange({ target: { value } }) {
        if (inputType === 'number') setValue(parseInt(value))
        else setValue(value)
    }

    function handleSubmit(e) {
        e.preventDefault()
        onSubmitCb()
        if (inputType === 'number') setValue(1)
        else {
            setIsOpen(false)
            setValue('')
        }
    }

    function handleCancel() {
        setValue('')
        setIsOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setIsOpen(false)} fullWidth={fullwidth}>
            <DialogTitle>{t(title)}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        fullWidth
                        required={required}
                        variant="outlined"
                        type={inputType}
                        multiline={multiline}
                        rows={3}
                        label={t(inputLabel)}
                        value={value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} disabled={isLoading}>
                        {t('annulla')}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size={20} /> : null}
                    >
                        {t(button)}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
