import React from 'react'
import Link from '@material-ui/core/Link'
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppTooltip from '../../../components/AppTooltip'
import { Help as HelpIcon } from '@material-ui/icons'

import LoadingSpinner from '../../../components/LoadingSpinner'

const useStyles = makeStyles((theme) => ({
    container: {
        // [theme.breakpoints.down('md')]: {
        //     marginBottom: theme.spacing(3),
        // },
        // [theme.breakpoints.up('lg')]: {
        //     maxWidth: '270px',
        // },
        // minWidth: '300px',
    },
    card: {
        height: '160px',
        position: 'relative',
    },
    overflow: {
        overflow: 'scroll',
    },
    count: {
        fontWeight: 'bold',
    },
    cardActions: {
        position: 'relative',
        top: '20px',
    },
    iconContainer: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        width: '50px',
        height: '50px',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderRadius: '5px',
    },
    tooltipIcon: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: theme.palette.background.paperLight,
        fontSize: 20,
    },
    customLink: {
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
}))

export default function DashboardCard({ icon, count, type, list = [], buttons, helpText }) {
    const classes = useStyles()
    return (
        <Grid item xs={12} md={6} lg={4} className={classes.container}>
            <Card raised className={`${classes.card} ${list.length > 2 && classes.overflow} `}>
                <CardContent style={{ maxHeight: '100px' }}>
                    {count != null ? (
                        <>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    {icon && (
                                        <Grid
                                            container
                                            className={classes.iconContainer}
                                            justify="center"
                                            alignContent="center"
                                        >
                                            {icon}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        align="right"
                                        className={classes.count}
                                        variant="h4"
                                    >
                                        {count}
                                    </Typography>
                                    {helpText && (
                                        <AppTooltip
                                            title={
                                                <Typography variant="body2">{helpText}</Typography>
                                            }
                                        >
                                            <HelpIcon className={classes.tooltipIcon} />
                                        </AppTooltip>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end" style={{ height: '20px' }}>
                                <Typography
                                    align="right"
                                    variant="subtitle2"
                                    style={{ width: '60%' }}
                                >
                                    {type}
                                </Typography>
                            </Grid>
                            {list && (
                                <Grid container style={{ marginTop: '5px' }}>
                                    {list.map((element, i) => (
                                        <Grid
                                            key={i}
                                            item
                                            container
                                            alignItems="flex-start"
                                            direction="row"
                                            justify="space-between"
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <Typography gutterBottom variant="caption">
                                                    {element.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ paddingLeft: 8 }}>
                                                <Typography
                                                    gutterBottom
                                                    color="textSecondary"
                                                    variant="caption"
                                                    align="right"
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {element.value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Grid container justify="center" alignItems="center">
                            <LoadingSpinner />
                        </Grid>
                    )}
                </CardContent>
                {buttons ? (
                    <CardActions className={classes.cardActions} disableSpacing>
                        <Grid container direction="row-reverse" justify="space-between">
                            {buttons.map((button, index) => (
                                <Link
                                    component="button"
                                    key={index}
                                    variant="body2"
                                    className={classes.customLink}
                                    onClick={button.onClick}
                                >
                                    {index % 2 !== 0 ? (
                                        <Typography
                                            gutterBottom
                                            variant="caption"
                                            color="secondary"
                                        >
                                            <FontAwesomeIcon
                                                style={{ marginRight: '5px' }}
                                                icon={faAngleLeft}
                                                color="secondary"
                                            />
                                            {button.title}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            gutterBottom
                                            variant="caption"
                                            color="secondary"
                                        >
                                            {button.title}
                                            <FontAwesomeIcon
                                                style={{ marginLeft: '5px' }}
                                                icon={faAngleRight}
                                                color="secondary"
                                            />
                                        </Typography>
                                    )}
                                </Link>
                            ))}
                        </Grid>
                    </CardActions>
                ) : null}
            </Card>
        </Grid>
    )
}
