import { Avatar, CircularProgress, Fab, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Save as SaveIcon } from '@material-ui/icons'
import _ from 'lodash'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Caso1 from '../../../assets/caso1.png'
import Caso2_3 from '../../../assets/caso2_3.png'
import { colorsMapping } from '../../../customTheme'
import { scheda_info } from '../../../FormConfigs/mit_la_1_difetto_config'
import {
    elemento_accessorio_model,
    sections_to_render,
} from '../../../FormConfigs/mit_la_1_elAccessorio_config'
import AppAccordion from '../../AppAccordion'
import AppForm from '../../AppForm'
import DynamicFormFields from '../../FormItems/DynamicFormFields'
import FieldsGroup from '../../FormItems/FieldsGroup'

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
    },
    casoImg: {
        width: 350,
        display: 'block',
        margin: '0 auto',
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(),
        filter: 'opacity(0.9)',
    },
    fab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
    },
}))

export default function SchedaElementiAccessori({
    methods,
    formPath,
    formPathForDynamicFormFields,
    sendData = (data) => {},
    isMutationLoading = false,
    isLevel1DataLoading = false,
    isNotAllowedToWrite = false,
    isInBMSComparison = false,
}) {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = useStyles()

    const watchBarriereLaterali = useWatch({
        control: methods.control,
        name: `${formPathForDynamicFormFields}.barriere_laterali`,
    })

    function renderWeight(extra) {
        return extra?.weight ? (
            <Avatar
                style={{
                    width: 22,
                    height: 22,
                    fontSize: 12,
                    backgroundColor: colorsMapping[extra.weight],
                    color: theme.palette.background.paper,
                }}
            >
                G{extra.weight}
            </Avatar>
        ) : null
    }

    // leave them like this
    //https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState

    return (
        <>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                {t('elementi_accessori')}
            </Typography>
            <AppForm onSubmit={sendData} methods={methods}>
                <Grid
                    container
                    xs={12}
                    justify="center"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    <Grid item xs={10}>
                        <DynamicFormFields
                            fieldsKey={formPathForDynamicFormFields}
                            formFields={scheda_info}
                            disableAll={isNotAllowedToWrite || isInBMSComparison}
                        />
                    </Grid>
                </Grid>
                <AppAccordion
                    TransitionProps={{ unmountOnExit: true }}
                    accordionLabel="datiGenerali"
                    accordionDetailsStyle={{ flexWrap: 'wrap' }}
                >
                    <Grid item container justify="space-evenly">
                        <DynamicFormFields
                            fieldsKey={formPathForDynamicFormFields}
                            formFields={elemento_accessorio_model.dati_generali}
                            disableAll={isNotAllowedToWrite || isInBMSComparison}
                        />
                    </Grid>
                    <Grid item>
                        <FieldsGroup groupLabel="marciapiede">
                            <DynamicFormFields
                                fieldsKey={formPathForDynamicFormFields}
                                formFields={elemento_accessorio_model.dati_marciapiede}
                                disableAll={isNotAllowedToWrite || isInBMSComparison}
                            />
                        </FieldsGroup>
                    </Grid>
                </AppAccordion>
                {sections_to_render.map((section) => (
                    <AppAccordion
                        TransitionProps={{ unmountOnExit: true }}
                        key={section}
                        accordionLabel={section}
                    >
                        <DynamicFormFields
                            renderExtra={renderWeight}
                            fieldsKey={`${formPathForDynamicFormFields}.difetti.${section}`}
                            formFields={elemento_accessorio_model[section]}
                            disableAll={isNotAllowedToWrite || isInBMSComparison}
                        />
                    </AppAccordion>
                ))}
                {watchBarriereLaterali && (
                    <>
                        <Typography
                            variant="h6"
                            component="h2"
                            align="center"
                            gutterBottom
                            className={classes.title}
                        >
                            {t(watchBarriereLaterali)}
                        </Typography>
                        <img
                            src={watchBarriereLaterali === 'CASO1' ? Caso1 : Caso2_3}
                            alt="schema caso di pertinenza"
                            className={classes.casoImg}
                        />
                        <AppAccordion
                            //TransitionProps={{ unmountOnExit: true }}
                            accordionLabel="guardiavia"
                            avatarContent="1"
                        >
                            <DynamicFormFields
                                renderExtra={renderWeight}
                                fieldsKey={`${formPath}.guardiavia`}
                                formFields={elemento_accessorio_model.guardiavia_parapetto}
                                disableAll={isNotAllowedToWrite || isInBMSComparison}
                            />
                        </AppAccordion>
                        {watchBarriereLaterali !== 'CASO1' && (
                            <AppAccordion
                                TransitionProps={{
                                    unmountOnExit: true,
                                }}
                                accordionLabel="parapetto"
                                avatarContent="2"
                            >
                                <DynamicFormFields
                                    renderExtra={renderWeight}
                                    fieldsKey={`${formPath}.parapetto`}
                                    formFields={_.omit(
                                        elemento_accessorio_model.guardiavia_parapetto,
                                        'presenza.funzione'
                                    )}
                                    disableAll={isNotAllowedToWrite || isInBMSComparison}
                                />
                            </AppAccordion>
                        )}
                    </>
                )}
                {!isInBMSComparison && (
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={!isDirty || !isValid || isLevel1DataLoading || isMutationLoading}
                        className={classes.fab}
                    >
                        {isMutationLoading ? (
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                        ) : (
                            <SaveIcon />
                        )}
                        {t('salva')}
                    </Fab>
                )}
            </AppForm>
        </>
    )
}
