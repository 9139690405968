import React from 'react'
import AppSnackbar from './AppSnackbar'
import { useSnackBarStore } from '../stateManagement'

export default function GlobalSnackBar() {
    const [display, message, severity, onClose] = useSnackBarStore((state) => [
        state.display,
        state.message,
        state.severity,
        state.onClose,
    ])
    return <AppSnackbar open={display} message={message} severity={severity} onClose={onClose} />
}
