import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import FileSaver from 'file-saver'
import _ from 'lodash'
import { Button, Grid, Typography, Container, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SaveIcon from '@material-ui/icons/Save'
import ErrorIcon from '@material-ui/icons/Error'
import DoneIcon from '@material-ui/icons/Done'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import GetAppIcon from '@material-ui/icons/GetApp'
import Section from '../components/Section'
import DynamicFormFields from '../components/FormItems/DynamicFormFields'
import PasswordField from '../components/FormItems/PasswordField'
import AppForm from '../components/AppForm'
import GlobalSnackBar from '../components/GlobalSnackBar'
import AppTooltip from '../components/AppTooltip'
import { useMutation, useQuery } from 'react-query'
import { profileQueries, queryClient, loginQueries } from '../networking'
import { useTranslation } from 'react-i18next'
import { userPersonalDocuments, userBuiltiDocuments } from '../FormConfigs/user_config'
import { createFieldArray, attachmentType } from '../FormConfigs/utils'
import { backend_url } from '../constants'
import { useSnackBarStore, useProceduresFiltersStore } from '../stateManagement'
import { user_config } from '../FormConfigs/user_config'
import { replaceEmptyStringsWithNullWithin } from '../FormConfigs/utils'

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    submit: {
        marginTop: theme.spacing(2),
    },
    completedProfileBadge: {
        color: theme.palette.success.main,
    },
    downloadIcon: {
        color: theme.palette.secondary.main,
    },
}))

export default function UserProfilePage() {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const [containerKey, setContainerKey] = React.useState(0)
    const showSnackBar = useSnackBarStore((state) => state.show)
    const resetAllProceduresFilters = useProceduresFiltersStore((state) => state.resetAllFilters)
    //---- Each form must have its own instance:
    // General info form
    const methodsGeneralInfo = useForm({ defaultValues: {} })
    // Change password form
    const methodsPassword = useForm()
    // Personal documents form
    const methodsPersonalDocs = useForm({ defaultValues: {}, shouldUnregister: false })

    const { data: user = {}, isLoading } = useQuery(
        profileQueries.getUser.name,
        profileQueries.getUser.fn,
        {
            onSuccess: (data) => {
                methodsGeneralInfo.reset(data)
                methodsPersonalDocs.reset(data)
            },
        }
    )

    const updateUser = useMutation(profileQueries.updateUser.fn, {
        onSuccess: () => queryClient.invalidateQueries(profileQueries.getUser.name),
    })

    const changePassword = useMutation(profileQueries.changePassword.fn, {
        onSuccess: () => {
            queryClient.invalidateQueries(profileQueries.changePassword.name)
            showSnackBar({
                message: t('password_changed_success'),
                severity: 'success',
            })
            methodsPassword.reset({})
            setContainerKey(containerKey + 1)
        },
        onError: (err) => {
            showSnackBar({
                message: `${t('password_changed_error')}: ${err.response.data[0]}`,
                severity: 'error',
            })
        },
    })

    const acceptBuiltiDocsMutation = useMutation(profileQueries.acceptBuiltiDocs.fn, {
        onSuccess: () => queryClient.invalidateQueries(profileQueries.getUser.name),
    })

    const logoutMutation = useMutation(loginQueries.logout.fn, {
        onSuccess: () => {
            resetAllProceduresFilters()
            queryClient.invalidateQueries().then(() => history.push('/login'))
        },
    })

    function sendUserData(data) {
        updateUser.mutate(replaceEmptyStringsWithNullWithin(data))
    }

    function checkNewPasswordInput(data) {
        // TODO
        if (data.newPassword === data.passwordConfirmation) {
            // alert('Password successfully changed! (MOCKED FUNCTION)')
            changePassword.mutate(data)
        } else {
            showSnackBar({
                message: t('new_password_repeat_new_password_mismatch'),
                severity: 'error',
            })
        }
    }

    function handleDownloadFile(file) {
        const previewUri = String(new URL(file.depot_url, backend_url))
        try {
            FileSaver.saveAs(previewUri, file.name)
        } catch (e) {
            showSnackBar({ message: t('download_failed'), severity: 'error' })
            console.error(e)
        }
    }

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
                <Grid item container alignItems="center" justify="center">
                    <Typography
                        variant="h4"
                        component="h1"
                        align="center"
                        style={{ marginRight: 16 }}
                    >
                        {t('user_profile_page.title')}
                    </Typography>
                    {!isLoading &&
                        (user?.completed ? (
                            <AppTooltip
                                title={
                                    <Typography variant="body2">{t('profile_complete')}</Typography>
                                }
                            >
                                <CheckCircleIcon
                                    fontSize="large"
                                    className={classes.completedProfileBadge}
                                />
                            </AppTooltip>
                        ) : (
                            <AppTooltip
                                title={
                                    <Typography variant="body2">
                                        {t('profile_incomplete_body')}
                                    </Typography>
                                }
                            >
                                <ErrorIcon fontSize="large" color="error" />
                            </AppTooltip>
                        ))}
                </Grid>

                <Grid item container spacing={2} wrap="wrap">
                    <Section xs={12} md={8} direction="column">
                        <Typography variant="h6" component="h2" gutterBottom paragraph>
                            {t('informazioni_generali')}
                        </Typography>
                        <AppForm onSubmit={sendUserData} methods={methodsGeneralInfo}>
                            <DynamicFormFields formFields={user_config} fieldsKey={''} />
                            <Grid container justify="flex-end">
                                <Button
                                    className={classes.submit}
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SaveIcon />}
                                >
                                    Salva
                                </Button>
                            </Grid>
                        </AppForm>
                    </Section>
                    <Section xs={12} md={4} direction="column">
                        <Typography variant="h6" component="h2" gutterBottom paragraph>
                            {t('reimposta_password')}
                        </Typography>
                        <Grid item xs={12}>
                            <form onSubmit={methodsPassword.handleSubmit(checkNewPasswordInput)}>
                                <Grid
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    key={containerKey}
                                    spacing={1}
                                >
                                    <Grid item>
                                        <PasswordField
                                            label={t('password_attuale')}
                                            name="oldPassword"
                                            inputRef={methodsPassword.register}
                                            notchedAreaWidth={127}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <PasswordField
                                            label={t('password_nuova')}
                                            name="newPassword"
                                            inputRef={methodsPassword.register}
                                            notchedAreaWidth={123}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <PasswordField
                                            label={t('password_conferma')}
                                            name="passwordConfirmation"
                                            inputRef={methodsPassword.register}
                                            notchedAreaWidth={147}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-end">
                                    <Button type="submit" variant="contained" color="secondary">
                                        {t('reimposta_password')}
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Section>
                </Grid>

                <Section xs={12} direction="column">
                    <Typography variant="h6" component="h2" gutterBottom paragraph>
                        {t('documentazione_builti')}
                    </Typography>
                    <Grid container alignItems="center" style={{ marginBottom: 30 }}>
                        <Typography style={{ marginRight: 8 }}>
                            {t('builti_documents_seen_text')}
                        </Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={acceptBuiltiDocsMutation.mutate}
                            disabled={user.builti_documents?.seen}
                            startIcon={user.builti_documents?.seen ? <DoneIcon /> : null}
                        >
                            {user.builti_documents?.seen ? t('confirmed') : t('conferma')}
                        </Button>
                    </Grid>
                    <Grid container direction="column" spacing={4}>
                        {Object.keys(_.omit(userBuiltiDocuments, ['_type', '__meta'])).map(
                            (docName) => {
                                const files = user.builti_documents?.[docName]
                                return (
                                    <Grid item key={docName}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            {t(docName)}
                                        </Typography>
                                        {files?.length > 0 ? (
                                            <Grid container spacing={2}>
                                                {files.map((f) => (
                                                    <Grid item key={f.name}>
                                                        <Chip
                                                            variant="outlined"
                                                            label={f?.name}
                                                            // file cannot be deleted (dont know why they called it deleteIcon when it could be anything)
                                                            deleteIcon={
                                                                <GetAppIcon
                                                                    className={classes.downloadIcon}
                                                                />
                                                            }
                                                            onDelete={() => handleDownloadFile(f)}
                                                            style={{ maxWidth: 280 }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : (
                                            <Typography variant="caption">
                                                {t('no_file_uploaded')}
                                            </Typography>
                                        )}
                                    </Grid>
                                )
                            }
                        )}
                    </Grid>
                </Section>

                <Section xs={12} direction="column">
                    <Typography variant="h6" component="h2" gutterBottom paragraph>
                        {t('documentazione_personale')}
                    </Typography>
                    <AppForm onSubmit={sendUserData} methods={methodsPersonalDocs}>
                        <DynamicFormFields
                            formFields={{
                                ...userPersonalDocuments,
                                conferme_presa_visione: {
                                    ...createFieldArray(attachmentType),
                                    disabled:
                                        !user?.all_educational_videos_seen ||
                                        !user?.builti_documents.seen,
                                },
                            }}
                            fieldsKey={'personal_documents'}
                        />
                        <Grid container justify="flex-end">
                            <Button
                                className={classes.submit}
                                type="submit"
                                variant="contained"
                                color="secondary"
                                startIcon={<SaveIcon />}
                            >
                                Salva
                            </Button>
                        </Grid>
                    </AppForm>
                </Section>

                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ExitToAppIcon />}
                        onClick={logoutMutation.mutate}
                    >
                        Logout
                    </Button>
                </Grid>
            </Grid>
            <GlobalSnackBar />
        </Container>
    )
}
