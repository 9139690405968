import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Lock as LockIcon,
    EventAvailable as EventAvailableIcon,
    ContactSupport as ContactSupportIcon,
    MenuBook as MenuBookIcon,
    GroupAdd as GroupAddIcon,
    ExitToApp as ExitToAppIcon,
    ViewList as ViewListIcon,
} from '@material-ui/icons'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Avatar,
    Grid,
    Button,
    Typography,
    Divider,
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HomeIcon from '@material-ui/icons/Home'

// import FacebookIcon from '@material-ui/icons/Facebook'
// import TwitterIcon from '@material-ui/icons/Twitter'
// import LinkedInIcon from '@material-ui/icons/LinkedIn'

import {
    profileQueries,
    loginQueries,
    changelogQueries,
    queryClient,
} from '../networking'
import { capitalizeFirstLetter, getInitials, isBmsEnabled } from '../utils'
import {useCompanyStore, useProceduresFiltersStore, useUserProfileStore} from '../stateManagement'
import { backend_url } from '../constants'
import LoadingSpinner from './LoadingSpinner'
import AppTooltip from './AppTooltip'
import ThemeSwitch from './ThemeSwitch'
import FibaLogoTesto from '../assets/Fiba_logo_testo.png'
import ResistoIcon from '../assets/resistoIcon.svg'
import { useSnackBarStore } from '../stateManagement'

// All commented code is about: social buttons and dashboard link

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 300,
        background: theme.palette.primary.main,
        overflow: 'hidden',
    },
    nav: {
        display: 'flex',
        flex: 1,
        color: theme.palette.primary.contrastText,
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(2),
    },
    link: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none',
    },
    userContainer: {
        height: 80,
        borderRadius: theme.shape.borderRadius,
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.text.secondary,
        width: 55,
        height: 55,
    },
    socialNetworksContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    bottomDrawer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
    versionLink: {
        color: theme.palette.common.grey,
        '&:hover': {
            color: 'inherit',
        },
        '&hover': {},
    },
    lock: {
        top: 0,
        right: 0,
        color: '#C2C2C2',
        height: 15,
        position: 'absolute',
        marginTop: 5,
    },
    secondaryIcon: {
        height: '10px',
        verticalAlign: 'middle',
        marginLeft: 8,
        color: theme.palette.secondary.main,
    },
}))

export default function AppDrawer({ open, setIsDrawerOpen }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const showSnackBar = useSnackBarStore((state) => state.show)
    const resetAllProceduresFilters = useProceduresFiltersStore((state) => state.resetAllFilters)
    const isSuperAdmin = useUserProfileStore((state) => state.isSuperAdmin)
    const isUsersManager = useUserProfileStore((state) => state.isUsersManager)
    const {
        companies,
        dashboardCompanies,
    } = useCompanyStore(state => state)



    const { data: currentUser = {}, isLoading } = useQuery(
        profileQueries.getUser.name,
        profileQueries.getUser.fn
    )

    const { data: changelog } = useQuery(
        changelogQueries.getChangelog.name,
        changelogQueries.getChangelog.fn
    )

    const logoutMutation = useMutation({
        mutationKey: loginQueries.logout.name,
        mutationFn: loginQueries.logout.fn,
    })

    const handleLogout = async () => {
        try {
            await logoutMutation.mutateAsync()
            setIsDrawerOpen(false)
            resetAllProceduresFilters()
            await queryClient.invalidateQueries()
            history.push('/login')
        } catch (error) {
            console.error('Errore durante il logout:', error)
            showSnackBar({ message: t('failed_logout'), severity: 'error' })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => setIsDrawerOpen(false), [location])

    function dashboardUrl() {
        // const selectedCompany = companies.find((x) => x.name === companySelect)
        // if (selectedCompany) {
        //     return `/dashboard/${selectedCompany.id}`
        // }
        return '/home'
    }

    return (
        <Drawer
            anchor={'left'}
            open={open}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{ className: classes.drawer }}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <List component="nav" className={classes.nav}>
                    <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        spacing={2}
                        style={{ height: '100%' }}
                    >
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Link to={dashboardUrl()}>
                                <img src={FibaLogoTesto} alt="" width={130} />
                            </Link>
                        </Grid>

                        <Grid item>
                            <Paper elevation={0}>
                                <ListItem button className={classes.userContainer}>
                                    <Link to="/user/profile" className={classes.link}>
                                        <ListItemIcon>
                                            <Avatar
                                                src={
                                                    currentUser?.image?.depot_url &&
                                                    new URL(
                                                        currentUser.image.depot_url,
                                                        backend_url
                                                    )
                                                }
                                                alt={`${currentUser.first_name} ${currentUser.last_name}`}
                                                className={classes.avatar}
                                            >
                                                {getInitials(
                                                    currentUser.first_name,
                                                    currentUser.last_name
                                                )}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                align: 'center',
                                            }}
                                            primary={`${capitalizeFirstLetter(
                                                currentUser.first_name
                                            )} ${capitalizeFirstLetter(currentUser.last_name)}`}
                                        />
                                    </Link>
                                </ListItem>
                            </Paper>
                        </Grid>

                        <Grid item>
                            <ListItem button>
                                <Link to={"/home"} className={classes.link}>
                                    <ListItemIcon>
                                        <HomeIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={capitalizeFirstLetter(t('home'))} />
                                </Link>
                            </ListItem>
                        </Grid>

                        {!!dashboardCompanies.length && <Grid item>
                            <ListItem button>
                                <Link to={"/dashboard/lgp"} className={classes.link}>
                                    <ListItemIcon>
                                        <DashboardIcon color="secondary"/>
                                    </ListItemIcon>
                                    <ListItemText primary={`${capitalizeFirstLetter(t('dashboard'))} LGP`}/>
                                </Link>
                            </ListItem>
                        </Grid>}

                        <Grid item>
                            <ListItem button>
                                <Link to="/companies" className={classes.link}>
                                    <ListItemIcon>
                                        <ViewListIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('studi')} />
                                </Link>
                            </ListItem>
                        </Grid>

                        <Grid item>
                            <ListItem button>
                                <Link to="/education" className={classes.link}>
                                    <ListItemIcon>
                                        <MenuBookIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('education_area')} />
                                </Link>
                            </ListItem>
                        </Grid>

                        {(isSuperAdmin || isUsersManager) && (
                            <Grid item>
                                <ListItem button>
                                    <Link to="/users" className={classes.link}>
                                        <ListItemIcon>
                                            <GroupAddIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary={t('users_management')} />
                                    </Link>
                                </ListItem>
                            </Grid>
                        )}

                        <Grid item>
                            <ListItem button>
                                <Link
                                    to={(companies || []).some(isBmsEnabled) ? '/calendar' : '#'}
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <EventAvailableIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('calendario_ispezioni')} />
                                </Link>
                                {!companies.some(isBmsEnabled) && (
                                    <AppTooltip title={t('inactive_module')} placement={'top'}>
                                        <LockIcon className={classes.lock} fontSize="small" />
                                    </AppTooltip>
                                )}
                            </ListItem>
                        </Grid>

                        <Grid item>
                            <ListItem button>
                                <Link to="/help" className={classes.link}>
                                    <ListItemIcon>
                                        <ContactSupportIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('help_page')} />
                                </Link>
                            </ListItem>
                        </Grid>
                        <Divider />
                        <Grid item>
                            <ListItem button>
                                <Link
                                    target="_blank"
                                    to={{ pathname: 'https://resisto-prod.herokuapp.com/login' }}
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <img
                                            src={ResistoIcon}
                                            alt="RE.SIS.TO"
                                            width={30}
                                            className={classes.resistoIcon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography>
                                                RE.SIS.TO.
                                                <FontAwesomeIcon
                                                    className={classes.secondaryIcon}
                                                    icon={faExternalLink}
                                                    fontSize="small"
                                                />
                                            </Typography>
                                        }
                                    />
                                </Link>
                            </ListItem>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.bottomDrawer}
                            spacing={3}
                        >
                            <Grid item>
                                <ThemeSwitch />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<ExitToAppIcon />}
                                    onClick={handleLogout}
                                >
                                    {t('logout')}
                                </Button>
                            </Grid>
                            {changelog && (
                                <Link to="/changelog">
                                    <Typography
                                        variant="caption"
                                        classes={{
                                            root: classes.versionLink,
                                        }}
                                    >
                                        v{changelog.fiba.current_version}
                                    </Typography>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </List>
            )}
        </Drawer>
    )
}
